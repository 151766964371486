import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutotextService } from '../autotext.service';
import { ExamsService } from '../exams.service';
import { PregnancyService } from '../pregnancy.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-medicalhistoryitem',
  templateUrl: './medicalhistoryitem.component.html',
  styleUrls: ['./medicalhistoryitem.component.scss']
})
export class MedicalhistoryitemComponent implements OnInit, OnDestroy {
  @Input('title') title:string;
  @Input('control') control:UntypedFormControl = new UntypedFormControl();
  @Input('type') type:string;
  @Input('id') id:number;
  @Input('data') data:any;
  @Input('modality') modality:string
  sub = new Subscription();
  options: any[] = [];
  itemId:any
  // filteredOptions: Observable<string[]>;
  filteredOptions:any[] = [];
  inputControl: UntypedFormControl = new UntypedFormControl()
  showFormula:boolean = false;

  atSTitleMedicalHistory = this.translate.instant("pregnancy.MedicalHistory.title");

  constructor(
    public ps: PregnancyService,
    public es: ExamsService,
    public atS: AutotextService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.sub = this.es.examLoaded$.subscribe(() => {
      this.atS.showAuto(this.ps.pregForm.get(this.type+'Text'), this.type+'Text', this.id,false)
      this.getText()
      this.inputControl.patchValue(this.es.ps.pregForm.value[this.type+'Text'] ? this.es.ps.pregForm.value[this.type+'Text'] :'')
    })
  }
  addAndSave(option:any){
    this.options.push(option);
    if(this.ps.pregForm.get(this.type+'Text').value !== ""){
      this.atS.showAuto(this.ps.pregForm.get(this.type+'Text'), this.type+'Text', this.id,false)
      var res = this.atS.saveText(option, null)
      
      this.sub = res.subscribe((data)=>{
        if(this.itemId == null)
          this.itemId = data['insertId'];
      },(error)=>{
        console.log(error);
      });
    }
    
  }

  getClass(item):string{
    if(item !== null  && this.ps.pregForm.get(item+'Comment')  && this.ps.pregForm.get(item+'Comment').value )
      return 'type-v'
    return 'type-plus';
  }
  getText(){
    var res = this.atS.getTexts();
      this.sub = res.subscribe((data:any) => {
        data.forEach((d) => {
          this.options.push(d.Text)
        })
        this.inputControl.patchValue('');
      })
  }

  checkIn(){
    this.filteredOptions = this.options.filter(option => option.includes(this.ps.pregForm.get(this.type+'Text').value))
    return this.options.filter(option => option.includes(this.ps.pregForm.get(this.type+'Text').value)).length > 0;
  }
  delete(item){
    var res = this.atS.getTexts(this.id.toString());
    this.sub = res.subscribe((data:any) => {
      if(data.length > 0){
        var del = data.filter((i) => i.Text === item)
        var d= this.atS.delete(del[0].ID)
        this.sub = d.subscribe((da:any) => {
          if(da.status === 'ok'){
            this.ps.pregForm.get(this.type+'Text').patchValue('');
            this.getText()
            this.filteredOptions = this.filteredOptions.filter(i => i !== item)
            this.options = this.filteredOptions
          }
        })
      }
    })
  }
  reset(){
    if(this.type !== 'OBSFormula'){
      this.ps.pregForm.get(this.type).setValue(-1)
      this.ps.pregForm.get(this.type+'Text').setValue('')
      this.ps.pregForm.get(this.type+'Comment').setValue('')
    }
    
  }

  ngOnDestroy(): void {
      this.sub.unsubscribe()
  }


}
