<div class="flex flex-wrap summary" [formGroup]="AdnexaForm">
    <div class="w-full mdw-1_2 p-5">
        <section class="dashboard-section" style="padding: 32px 24px;margin-bottom: 0;">
            <header class="dashboard-section-header">
                <h2>
                    <!-- Left Ovary -->
                    {{'GUI.Gynecology.Ovaries.Left Ovary' | translate | titlecase}}
                </h2>
            </header>
            <div class="tabs">
                <div role="tablist" aria-label="Left Ovary" (dblclick)="setN('L', null)">
                    <a role="tab" [attr.aria-selected]="AdnexaForm.get('LObserved').value == 'Normal' ? 'true': 'false'" (click)="setN('L','Normal')">
                        <!-- Normal -->
                        {{'GUI.Gynecology.Ovaries.Normal' | translate | titlecase}}
                    </a>
                    <a role="tab" [attr.aria-selected]="AdnexaForm.get('LObserved').value == 'Abnormal' ? 'true': 'false'" (click)="setN('L','Abnormal')">
                        <!-- Abnormal -->
                        {{'GUI.Gynecology.Ovaries.Abnormal' | translate | titlecase}}
                    </a>
                    <a role="tab" [attr.aria-selected]="AdnexaForm.get('LObserved').value == 'Comment' ? 'true': 'false'" (click)="setN('L','Comment')">
                        <!-- Comment -->
                        {{'GUI.Gynecology.Ovaries.Comment' | translate | titlecase}}
                    </a>
                </div>
            </div>

            <div role="tabpanel" id="tab1">
                <div class="flex">
                    <div class="flex items-center flex-grow">
                        <label for="input1">L</label>
                        <div class="sep-16"></div>
                        <div class="flex items-center t-field t-field--number">
                            <input type="number" id="input1" class="t-field-text" formControlName="LSizeX">
                            <span class="t-field-desc">mm</span>
                        </div>
                    </div>
                    <div class="sep-16"></div>
                    <div class="flex items-center flex-grow">
                        <label for="input2">W</label>
                        <div class="sep-16"></div>
                        <div class="flex items-center t-field t-field--number">
                            <input type="number" id="input2" class="t-field-text" formControlName="LSizeY">
                            <span class="t-field-desc">mm</span>
                        </div>
                    </div>
                    <div class="sep-16"></div>
                    <div class="flex items-center flex-grow">
                        <label for="input3">H</label>
                        <div class="sep-16"></div>
                        <div class="flex items-center t-field t-field--number">
                            <input type="number" id="input3" class="t-field-text" formControlName="LSizeZ">
                            <span class="t-field-desc">mm</span>
                        </div>
                    </div>
                    <div class="sep-16"></div>
                    <div class="flex items-center flex-grow">
                        <label for="input4">&nbsp;</label>
                        <div class="sep-16"></div>
                        <div class="flex items-center t-field t-field--number">
                            <input type="number" id="input4" class="t-field-text" formControlName="LSizeVol">
                            <span class="t-field-desc">cc</span>
                        </div>
                    </div>
                </div>
                <div class="sep"></div>
                <div>
                    <!-- Doppler -->
                    {{'GUI.Gynecology.Ovaries.Doppler' | translate}}
                </div>
                <div class="flex flex-wrap" style="padding: 0;">
                    <div class="w-full mdw-1_3 p-5">
                        <div class="circle">
                            <div class="circle-content">
                                <div class="circle-content-title">PI</div>
                                <div class="l-flex-c t-field t-twin-fieldset" style="background: #fff;" (dblclick)="reset('LPIStatus')">

                                    <label class="gestation-label">

                                        <input type="radio" name="LPIStatus" formControlName="LPIStatus" [value]="0" class="custom-check customInput" aria-labelledby="" checked="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- OK -->
                                            {{'GUI.Gynecology.Ovaries.OK' | translate}}
                                        </span>

                                    </label>

                                    <label class="gestation-label customInput-check-true">

                                        <input type="radio" name="LPIStatus" formControlName="LPIStatus" [value]="1" class="custom-check customInput" aria-labelledby="" >
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- Not OK -->
                                            {{'GUI.Gynecology.Ovaries.Not OK' | translate}}
                                        </span>

                                    </label>

                                </div>										
                            </div>
                            <div class="sm-circle"><input formControlName="LPI" type="number" min="0"></div>
                        </div>
                    </div>
                    <div class="w-full mdw-1_3 p-5">
                        <div class="circle">
                            <div class="circle-content">
                                <div class="circle-content-title">RI</div>
                                <div class="l-flex-c t-field t-twin-fieldset" style="background: #fff;" (dblclick)="reset('LRIStatus')">

                                    <label class="gestation-label">

                                        <input type="radio" name="LRIStatus" formControlName="LRIStatus" [value]="0" class="custom-check customInput" aria-labelledby="" checked="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- OK -->
                                            {{'GUI.Gynecology.Ovaries.OK' | translate}}
                                        </span>

                                    </label>

                                    <label class="gestation-label customInput-check-true">

                                        <input type="radio" name="LRIStatus" formControlName="LRIStatus" [value]="1" class="custom-check customInput" aria-labelledby="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- Not OKs -->
                                            {{'GUI.Gynecology.Ovaries.Not OK' | translate}}
                                        </span>

                                    </label>

                                </div>										
                            </div>
                            <div class="sm-circle"><input formControlName="LRI" type="number" min="0"></div>
                        </div>
                    </div>
                    <div class="w-full mdw-1_3 p-5">
                        <div class="circle">
                            <div class="circle-content">
                                <div class="circle-content-title">S/D</div>
                                <div class="l-flex-c t-field t-twin-fieldset" style="background: #fff;" (dblclick)="reset('LSDStatus')">

                                    <label class="gestation-label">

                                        <input type="radio" name="LSDStatus" formControlName="LSDStatus" [value]="0" class="custom-check customInput" aria-labelledby="" checked="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- OK -->
                                            {{'GUI.Gynecology.Ovaries.OK' | translate}}
                                        </span>

                                    </label>

                                    <label class="gestation-label customInput-check-true">

                                        <input type="radio" name="LSDStatus" formControlName="LSDStatus" [value]="1" class="custom-check customInput" aria-labelledby="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- Not OK -->
                                            {{'GUI.Gynecology.Ovaries.Not OK' | translate}}
                                        </span>

                                    </label>

                                </div>										
                            </div>
                            <div class="sm-circle"><input type="number" min="0" formControlName="LSD"></div>
                        </div>
                    </div>
                </div>
                <div class="sep"></div>
                <div class="w100 summary-spreadHeight summary-diagnosis">

                    <section class="m-box" style="height: 100px; padding: 1.31em 0; box-shadow: none; border: 0; background: none;">

                        <h2 style="margin-bottom: 16px; font-size: inherit; font-weight: 400;">
                            <!-- Comments -->
                            {{'GUI.Gynecology.Ovaries.Comments' | translate}}
                        </h2>
                        
                        <textarea class="scroller" formControlName="LComments"></textarea>
                           
                        
                        
                        <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(AdnexaForm.get('LComments'),atSTitleLeftOvaryComments ,82)">Auto Text</button>

                    </section>
                
                </div>
            </div>
            <div role="tabpanel" id="tab2" hidden>Abnormal Left</div>
            <div role="tabpanel" id="tab3" hidden>Comment Left</div>
        </section>
    </div>
    <div class="w-full mdw-1_2 p-5">
        <section class="dashboard-section" style="padding: 32px 24px;margin-bottom: 0;">
            <header class="dashboard-section-header">
                <h2>
                    <!-- Right Ovary -->
                    {{'GUI.Gynecology.Ovaries.Right Ovary' | translate | titlecase}}
                </h2>
            </header>
            <div class="tabs">
                <div role="tablist" aria-label="Left Ovary" (dblclick)="setN('R', null)">
                    <a role="tab" [attr.aria-selected]="AdnexaForm.get('RObserved').value == 'Normal' ? 'true': 'false'" (click)="setN('R','Normal')">
                        <!-- Normal -->
                        {{'GUI.Gynecology.Ovaries.Normal' | translate | titlecase}}
                    </a>
                    <a role="tab" [attr.aria-selected]="AdnexaForm.get('RObserved').value == 'Abnormal' ? 'true': 'false'" (click)="setN('R','Abnormal')">
                        <!-- Abnormal -->
                        {{'GUI.Gynecology.Ovaries.Abnormal' | translate | titlecase}}
                    </a>
                    <a role="tab" [attr.aria-selected]="AdnexaForm.get('RObserved').value == 'Comment' ? 'true': 'false'" (click)="setN('R','Comment')">
                        <!-- Comment -->
                        {{'GUI.Gynecology.Ovaries.Comment' | translate | titlecase}}
                    </a>
                </div>
            </div>

            <div role="tabpanel" id="tab4">
                <div class="flex">
                    <div class="flex items-center flex-grow">
                        <label for="input1">L</label>
                        <div class="sep-16"></div>
                        <div class="flex items-center t-field t-field--number">
                            <input type="number" id="input1" class="t-field-text" min="0" formControlName="RSizeX">
                            <span class="t-field-desc">mm</span>
                        </div>
                    </div>
                    <div class="sep-16"></div>
                    <div class="flex items-center flex-grow">
                        <label for="input2">W</label>
                        <div class="sep-16"></div>
                        <div class="flex items-center t-field t-field--number">
                            <input type="number" id="input2" class="t-field-text" min="0" formControlName="RSizeY">
                            <span class="t-field-desc">mm</span>
                        </div>
                    </div>
                    <div class="sep-16"></div>
                    <div class="flex items-center flex-grow">
                        <label for="input3">H</label>
                        <div class="sep-16"></div>
                        <div class="flex items-center t-field t-field--number">
                            <input type="number" id="input3" class="t-field-text" min="0" formControlName="RSizeZ">
                            <span class="t-field-desc">mm</span>
                        </div>
                    </div>
                    <div class="sep-16"></div>
                    <div class="flex items-center flex-grow">
                        <label for="input4">&nbsp;</label>
                        <div class="sep-16"></div>
                        <div class="flex items-center t-field t-field--number">
                            <input type="number" id="input4" class="t-field-text" formControlName="RSizeVol">
                            <span class="t-field-desc">cc</span>
                        </div>
                    </div>
                </div>
                <div class="sep"></div>
                <div>
                    <!-- Doppler -->
                    {{'GUI.Gynecology.Ovaries.Doppler' | translate}}
                </div>
                <div class="flex flex-wrap"  style="padding: 0;">
                    <div class="w-full mdw-1_3 p-5">
                        <div class="circle">
                            <div class="circle-content">
                                <div class="circle-content-title">PI</div>
                                <div class="l-flex-c t-field t-twin-fieldset" style="background: #fff;" (dblclick)="reset('RPIStatus')">

                                    <label class="gestation-label">

                                        <input type="radio" name="RPIStatus" formControlName="RPIStatus" [value]="0" class="custom-check customInput" aria-labelledby="" checked="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- OK -->
                                            {{'GUI.Gynecology.Ovaries.OK' | translate}}
                                        </span>

                                    </label>

                                    <label class="gestation-label customInput-check-true">

                                        <input type="radio" name="RPIStatus" formControlName="RPIStatus" [value]="1" class="custom-check customInput" aria-labelledby="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- Not OK -->
                                            {{'GUI.Gynecology.Ovaries.Not OK' | translate}}
                                        </span>

                                    </label>

                                </div>										
                            </div>
                            <div class="sm-circle"><input type="number" min="0" formControlName="RPI"></div>
                        </div>
                    </div>
                    <div class="w-full mdw-1_3 p-5">
                        <div class="circle">
                            <div class="circle-content">
                                <div class="circle-content-title">RI</div>
                                <div class="l-flex-c t-field t-twin-fieldset" style="background: #fff;" (dblclick)="reset('RRIStatus')">

                                    <label class="gestation-label">

                                        <input type="radio" name="RRIStatus" formControlName="RRIStatus" [value]="0" class="custom-check customInput" aria-labelledby="" checked="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- OK -->
                                            {{'GUI.Gynecology.Ovaries.OK' | translate}}
                                        </span>

                                    </label>

                                    <label class="gestation-label customInput-check-true">

                                        <input type="radio" name="RRIStatus" formControlName="RRIStatus" [value]="1" class="custom-check customInput" aria-labelledby="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- Not OK -->
                                            {{'GUI.Gynecology.Ovaries.Not OK' | translate}}
                                        </span>

                                    </label>

                                </div>										
                            </div>
                            <div class="sm-circle"><input type="number" min="0" formControlName="RRI"></div>
                        </div>
                    </div>
                    <div class="w-full mdw-1_3 p-5">
                        <div class="circle">
                            <div class="circle-content">
                                <div class="circle-content-title">S/D</div>
                                <div class="l-flex-c t-field t-twin-fieldset" style="background: #fff;" (dblclick)="reset('RSDStatus')">

                                    <label class="gestation-label">

                                        <input type="radio" name="RSDStatus" formControlName="RSDStatus" [value]="0"  class="custom-check customInput" aria-labelledby="" checked="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- OK -->
                                            {{'GUI.Gynecology.Ovaries.OK' | translate}}
                                        </span>

                                    </label>

                                    <label class="gestation-label customInput-check-true">

                                        <input type="radio" name="RSDStatus" formControlName="RSDStatus" [value]="1" class="custom-check customInput" aria-labelledby="">
                                        <i class="customInput-display"></i>
                                        <span class="gestation-title-text">
                                            <!-- Not OK -->
                                            {{'GUI.Gynecology.Ovaries.Not OK' | translate}}
                                        </span>

                                    </label>

                                </div>										
                            </div>
                            <div class="sm-circle"><input type="number" min="0" formControlName="RSD"></div>
                        </div>
                    </div>
                </div>
                <div class="sep"></div>
                <div class="w100 summary-spreadHeight summary-diagnosis">

                    <section class="m-box" style="height: 100px; padding: 1.31em 0; box-shadow: none; border: 0; background: none;">

                        <h2 style="margin-bottom: 16px; font-size: inherit; font-weight: 400;">
                            <!-- Comments -->
                            {{'GUI.Gynecology.Ovaries.Comments' | translate}}
                        </h2>
                        
                        <textarea class="scroller" formControlName="RComments"></textarea>
                        <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(AdnexaForm.get('RComments'),atSTitleRightOvaryComments,82)">Auto Text</button>

                    </section>
                
                </div>
            </div>
            <div role="tabpanel" id="tab5" hidden>Abnormal Left</div>
            <div role="tabpanel" id="tab6" hidden>Comment Left</div>
        </section>
    </div>
    
    
    <div class="w50 p-5 summary">
        <section class="dashboard-section" style="padding: 32px 24px;margin-bottom: 0;">
            <header class="dashboard-section-header">
                <h2>
                    <!-- P.O.D -->
                    {{'GUI.Gynecology.Ovaries.POD' | translate}}
                </h2>
            </header>
                <div class="l-flex-c cervical-row">

                    <label class="cervical-label" id="funneling">
                        <!-- Fluid -->
                        {{'GUI.Gynecology.Ovaries.Fluid' | translate}}
                    </label>

                    <div class="sep-16"></div>
                    <app-genradio formname="Fluid" [formgroup]="AdnexaForm" [value1]="0" [value2]="1" [lname]="'general.PositiveNegative.yes' | translate" [rname]="'general.PositiveNegative.No' | translate" [resetval]="-1" ></app-genradio>
                    
                </div>
            <div class="sep-32" style="margin: 16px 0;"></div>
            <div class="w100 summary-spreadHeight summary-diagnosis summary">

                <section class="m-box" style="height: 173px; padding: 1.31em 0; box-shadow: none; border: 0; background: none;">

                    <h2 style="margin-bottom: 16px; font-size: inherit; font-weight: 400;">
                        <!-- Comments -->
                        {{'GUI.Gynecology.Ovaries.Comments' | translate}}
                    </h2>
                    
                    <textarea class="scroller" formControlName="Comments"></textarea>
                    
                    <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(AdnexaForm.get('Comments'),atSTitlePODComments, 83)">Auto Text</button>

                </section>
            
            </div>
        </section>
    </div>

    <div class="w50 p-5 summary" style="padding: 1em;">
        <app-diagnoses group="adnexa" [form]="AdnexaForm.get('DiagnosisCodes')" [text]="AdnexaForm.get('Diagnosis')" autoid="85" class="w100 summary-boxHeight summary-extradocs"></app-diagnoses>
    </div>
</div>
