<div class="wrapper">
  <header class="l-header">
    <div class="on-mobile header-logo">
      <img src="assets/icons/logo.svg" alt="Artisan Sonics" />
    </div>
    <div class="l-header-panel" style="justify-content: space-between">
      <h3>AWEB Data Center</h3>
      <div class="buttons-container">
        <a
          class="header-menu-link icon-confirm"
          style="cursor: pointer; margin-left: 0em"
          aria-label="Confirm"
          (click)="exit()"
          matTooltip="Close Data Center and Open AWL"
        ></a>
      </div>
    </div>
  </header>

  <ul class="t-menu">
    <li>
      <button
        class="t-menu-item"
        [ngClass]="searchType == 'Pregnancies' ? 'is-active' : ''"
        data-open="cns"
        (click)="setSearchType('Pregnancies')"
        *ngIf="this.loginService.premissions && this.loginService.premissions.searchPregnancies"
      >
        Search for Pregnancies
      </button>
    </li>
    <li>
      <button
        class="t-menu-item"
        [ngClass]="searchType == 'findings' ? 'is-active' : ''"
        data-open="cns"
        (click)="setSearchType('findings')"
        *ngIf="this.loginService.premissions && this.loginService.premissions.FIndings"
      >
        Findings
      </button>
    </li>
  </ul>

  <div *ngIf="searchType == 'Pregnancies'" class="m-box" style="margin-top: 2em;">
    <h2>Search for Pregnancies</h2>

    <form [formGroup]="pregnancySearchForm">
      <div class="t-set-title" style="width: fit-content;">
        <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big" >
          <label>
            <input
              type="radio"
              name="pregStatus"
              class="custom-check"
              formControlName="pregStatus"
              [value]="'open'"
            />
            <i class="customInput-display"></i>
            <span>Open</span>
          </label>

          <label>
            <input
              type="radio"
              name="pregStatus"
              class="custom-check"
              formControlName="pregStatus"
              [value]="'close'"
            />
            <i class="customInput-display"></i>
            <span>Closed</span>
          </label>
        </div>
      </div>

      <div class="h100 l-flex l-column">
        <div class="l-fg1">
          <div>
            <div class="l-flex-c l-flex-w l-justify h50 new_exam-head">
              <label class="t-label-top">
                <input
                  type="radio"
                  name="gaOption"
                  class="custom-check"
                  formControlName="gaOption"
                  value="equal"
                />
                <i class="customInput-display"></i>
                <span>GA</span>
                <ng-container *ngIf="pregnancySearchForm.get('gaOption').value=='equal'" >
                <div>
                <strong> From:</strong>
                <input class="t-field" type="number" formControlName="gaFromW" style="width: 4em; border: none;margin-left: 0;text-align: center;text-align-last:center ;"> w
                <input class="t-field" type="number" formControlName="gaFromD" style="width: 4em; border: none;margin-left: 0;text-align: center;text-align-last:center ;"> d
               </div>
               <div style="padding: 1em;">
                <strong> To:</strong>
                <input class="t-field" type="number" formControlName="gaToW" style="width: 4em; border: none;margin-left: 0;text-align: center;text-align-last:center ;"> w
                <input class="t-field" type="number" formControlName="gaToD" style="width: 4em; border: none;margin-left: 0;text-align: center;text-align-last:center ;"> d
               </div>
              </ng-container>
              </label>

              <label class="t-label-top">
                <input
                  type="radio"
                  name="gaOption"
                  class="custom-check"
                  formControlName="gaOption"
                  value="less"
                />
                <i class="customInput-display"></i>
                <span>GA less than</span>
                <ng-container *ngIf="pregnancySearchForm.get('gaOption').value=='less'" >
                  <div>
                  <input class="t-field" type="number" formControlName="gaLessW" style="width: 4em; border: none;margin-left: 0;text-align: center;text-align-last:center ;"> w
                  <input class="t-field" type="number" formControlName="gaLessD" style="width: 4em; border: none;margin-left: 0;text-align: center;text-align-last:center ;"> d
                 </div>
                </ng-container>
              </label>
              <label class="t-label-top">
                <input
                  type="radio"
                  name="gaOption"
                  class="custom-check"
                  formControlName="gaOption"
                  value="more"
                />
                <i class="customInput-display"></i>
                <span>GA more than</span>
                <ng-container *ngIf="pregnancySearchForm.get('gaOption').value=='more'" >
                  <div>
                  <input class="t-field" type="number" formControlName="gaMoreW" style="width: 4em; border: none;margin-left: 0;text-align: center;text-align-last:center ;"> w
                  <input class="t-field" type="number" formControlName="gaMoreD" style="width: 4em; border: none;margin-left: 0;text-align: center;text-align-last:center ;"> d
                 </div>
                </ng-container>
              </label>
            </div>
          </div>
        </div>
        <div class="l-flex l-flex-w l-just-center patient-ob-btns-row">
          <a class="patient-ob-btn -exit" style="padding-top: 1em;" (click)="AdvancedSearch.clearLogout();clearSearch()">Cancel</a>
          <button class="patient-ob-btn -search"  (click)="searchPregnancies()" [disabled]="pregnancySearchForm.invalid || !canSearchPreg()">
            Search
          </button>
        </div>
      </div>
    </form>
<ng-container *ngIf="AdvancedSearch.PregnanciesResult && AdvancedSearch.PregnanciesResult.results">
  <div style="display: flex;flex-direction: row;justify-content: space-between;">
  <h2>
    Date: {{ currentDate |date: "dd.MM.yyyy" }}
  </h2>
  <h2 *ngIf="AdvancedSearch.PregnanciesResult && AdvancedSearch.PregnanciesResult['data']">
    Search Range: 
    <ng-container *ngIf="AdvancedSearch.PregnanciesResult['data']['gaOption'] === 'equal'">
    from: {{AdvancedSearch.PregnanciesResult['data']['gaFrom']['weeks']}}w{{AdvancedSearch.PregnanciesResult['data']['gaFrom']['days']}}d 
     to: {{AdvancedSearch.PregnanciesResult['data']['gaTo']['weeks']}}w{{AdvancedSearch.PregnanciesResult['data']['gaTo']['days']}}d 
    </ng-container>
    <ng-container *ngIf="AdvancedSearch.PregnanciesResult['data']['gaOption'] === 'less'">
      GA less than: {{AdvancedSearch.PregnanciesResult['data']['gaLess']['weeks']}}w{{AdvancedSearch.PregnanciesResult['data']['gaLess']['days']}}d 
      </ng-container>
      <ng-container *ngIf="AdvancedSearch.PregnanciesResult['data']['gaOption'] === 'more'">
        GA more than: {{AdvancedSearch.PregnanciesResult['data']['gaMore']['weeks']}}w{{AdvancedSearch.PregnanciesResult['data']['gaMore']['days']}}d 
        </ng-container>
  </h2>
  <h2>
    Status of Pregnancies : 
    <ng-container *ngIf="AdvancedSearch.PregnanciesResult['data']['pregStatus'] === 'open'">
      Open
    </ng-container>
    <ng-container *ngIf="AdvancedSearch.PregnanciesResult['data']['pregStatus'] === 'close'">
      Closed
    </ng-container>
    <ng-container *ngIf="AdvancedSearch.PregnanciesResult['data']['pregStatus'] !== 'open' && AdvancedSearch.PregnanciesResult['data']['pregStatus'] !== 'close'">
      Both
    </ng-container>
  </h2>
  <h2>
   Total: {{AdvancedSearch?.PregnanciesResult?.results?.length}} Pregnancies
  </h2>
  <img
  style="cursor: pointer"
  matTooltip="Download CVS"
  *ngIf="AdvancedSearch.PregnanciesResult.results.length"
 (click)="generatePregsExcelFile(AdvancedSearch.PregnanciesResult.results)"
 height="41em"
  width="41em"
  src="../../assets/icons/fileblue.svg"
    />
</div>
<div style="display: flex;flex-direction: row;justify-content: center;" *ngIf="!AdvancedSearch.PregnanciesResult.results.length">
   <h3> no pregnancies results</h3> 
</div>
<div *ngIf=" AdvancedSearch.PregnanciesResult && AdvancedSearch.PregnanciesResult.results && AdvancedSearch.PregnanciesResult.results.length>1" style="font-size: x-large;
    margin-left: 10%;" class="controls">
  <span>Sort by GA:</span>
  <button class="sort-btn" (click)="sortPregnancies('asc')">▲</button>
  <button class="sort-btn" (click)="sortPregnancies('desc')">▼</button>
</div>
  <div class="l-flex patient-item" *ngFor="let preg of AdvancedSearch.PregnanciesResult.results ">
    <button class="w100" style="cursor: default;">
      <div class="l-table h100">
        <span class="l-td-m patient-item-cell patient-name-first">
          GA <strong>{{ lmpGA(preg.LMP, preg.ClosedDate) }}</strong>
        </span>
        <span class="l-td-m patient-item-cell patient-id">
          ID: <strong>{{ preg.ID }}</strong>
        </span>
        <span class="l-td-m patient-item-cell patient-id">
          Name: {{ preg.First }} {{ preg.Last }}
        </span>
        <span class="l-td-m patient-item-cell patient-name-first">
          Status: <strong>{{ preg.ClosedDate ? 'Closed' : 'Open' }}</strong>
        </span>
      </div>
    </button>
    <a class="l-td-m patient-item-exit" routerLink="/exams" (click)="patientsService.setPat(preg);" [state]="{ data: preg }" [matTooltip]="'GUI.Tooltips.Open Patient History' | translate | titlecase"></a>

  </div>
</ng-container>

</div>
<div *ngIf="searchType == 'findings'" class="m-box" style="margin-top: 2em;">
  <h2>My Findings</h2>
  <mat-form-field class="border-red" style="width: 90%">
    <mat-label> Type a name of a finding </mat-label>
    <input
      class="search-input"
      type="text"
      matInput
      [matAutocomplete]="auto"
      [(ngModel)]="FindingText"
      (ngModelChange)="filterOptions()"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option
        *ngFor="let option of filteredFindings"
        [value]="option['Finding']"
      >
        {{ option['Finding']}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <button
    matTooltip="Search"
    *ngIf="selectedFindingOpenSearch"
    class="advanced-search-btn"
    type="button"
    (click)="searchExamsByFreeText()"
  >
    Search
  </button>
  <ng-container *ngIf="AdvancedSearch.examsFindings ">
    <div style="display: flex;flex-direction: row;justify-content: space-between;">
    <h2>
      Date: {{ currentDate |date: "dd.MM.yyyy" }}
    </h2>
   
    <h2>
     Total: {{AdvancedSearch?.examsFindings?.length}} exams
    </h2>
    <button   class="advanced-search-btn -exit"  (click)="AdvancedSearch.examsFindings=null">Clear</button>
    <img
    style="cursor: pointer"
    matTooltip="Download CVS"
    *ngIf="AdvancedSearch.examsFindings.length"
   (click)="generateExcelFileFindings()"
   height="41em"
    width="41em"
    src="../../assets/icons/fileblue.svg"
      />
  </div>
  <div style="display: flex;flex-direction: row;justify-content: center;" *ngIf="!AdvancedSearch.examsFindings.length">
     <h3> no exams with much finding </h3> 
  </div>
  <!-- <div *ngIf=" AdvancedSearch.examsFindings && AdvancedSearch.examsFindings.results && AdvancedSearch.examsFindings.results.length>1" style="font-size: x-large;
      margin-left: 10%;" class="controls">
    <span>Sort by GA:</span>
    <button class="sort-btn" (click)="sortPregnancies('asc')">▲</button>
    <button class="sort-btn" (click)="sortPregnancies('desc')">▼</button>
  </div> -->
    <div class="l-flex patient-item" *ngFor="let exam of AdvancedSearch.examsFindings ">
      <button class="w100" style="cursor: default;">
        <div class="l-table h100">
          <span class="l-td-m patient-item-cell patient-name-first">
            Exam Time: <strong>{{exam.CreationTime | date:"dd/MM/yyyy h:mm a"}} </strong>
          </span>
          <span class="l-td-m patient-item-cell patient-id">  
            ID: <strong>{{ exam.ID }}</strong>
          </span>
          <span class="l-td-m patient-item-cell patient-id">
            Name: <strong>{{ exam.First }} {{ exam.Last }}</strong>
          </span>
          <span class="l-td-m patient-item-cell patient-name-first">
            Exam type: <strong> {{ exam.ExamType }}</strong>
          </span>
        </div>
      </button>
      <a class="l-td-m patient-item-exit"  (click)="goExam(exam)" [matTooltip]="('GUI.Tooltips.View Exam' | translate)"></a>

      <!-- <a class="l-td-m patient-item-exit" routerLink="/exams" (click)="patientsService.setPat(preg);" [state]="{ data: preg }" [matTooltip]="'GUI.Tooltips.Open Patient History' | translate | titlecase"></a> -->
  
    </div>
  </ng-container>
  </div>

  <div *ngIf="searchType == 'findings'" class="m-box" style="margin-top: 2em;">
    <h2>Manage Findings</h2>

    <button
    matTooltip="Add New Finding"
    class="advanced-search-btn"
    type="button"
    (click)="addNewFinding=true"
  >
    Add New
  </button>

  <table *ngIf="userFindings && userFindings.length" >
    <thead>
      <tr>
        <th>Name</th>
        <th>Comment</th>
        <th>Operation</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let finding of userFindings">
        <td>{{ finding.Finding }}</td>
        <td>{{ finding.comment }}</td>
        <td>
          <button
          matTooltip="Add New Finding"
          class="advanced-search-btn"
          type="button"
          (click)="RenameFinding(finding)"
        >
          Rename
        </button>
        <button
          matTooltip="Add New Finding"
          class="advanced-search-btn -exit"
          type="button"
          (click)="showDeletePopup=true;findingToDelete=finding"
        >
        Delete
        </button>
        

          
        </td>
        
      </tr>  
          

    </tbody>
  </table>
    </div>
  
    <div  class="print-history-popup" *ngIf="showDeletePopup">
      <div class="print-history-popup-body">
        <h2 class="popup-title">
      This Delete will lose connection to all exams which are connected to this Finding
      </h2>
      <div >
                  <button
                  (click)="showDeletePopup=false;findingToDelete=null"
                    class="print-history-popup-close"
                    aria-label="close popup"
                  ></button>
          <div style="    width: 100%;
          float: right;">
              
            </div>
          <button class="advanced-search-btn -exit" (click)="deleteFinding()" >
             Delete
          </button>
          <button class="advanced-search-btn" (click)="showDeletePopup=false;findingToDelete=null" >
              Cancel
        </button>
      </div>
      </div>
    </div>
    <div  class="print-history-popup" *ngIf="showRenamePopup">
      <div class="print-history-popup-body">
        <h2 class="popup-title">
    Rename Finding
      </h2>
      <div >
                  <button
                  (click)="showRenamePopup=false;findingToUpdate=null"
                    class="print-history-popup-close"
                    aria-label="close popup"
                  ></button>
          <div style="    width: 100%;
          
          float: right;">
          <div style="display: flex;flex-direction:row ;align-items: center; justify-content: space-between;">
                
                 Finding
             
              <div class="l-flex l-flex-c t-field t-field-pad" style="margin: 5%;width: 90%;">
                <input type="text" class="t-field-text w100" [(ngModel)]="findingToUpdate.Finding"  placeholder="Finding"/>
               
              </div>
            </div>
            <div style="display: flex;flex-direction:row ;align-items: center; justify-content: space-between;">
             
               Comment
           
            <div class="l-flex l-flex-c t-field t-field-pad" style="margin: 5%;width: 90%;">
              <textarea class="t-field-text w100" style="height: 10vh;" [(ngModel)]="findingToUpdate.comment" placeholder="Comment"></textarea>
            </div>
        </div>
            </div>
          <button class="advanced-search-btn " (click)="updateFinding()" >
             Rename
          </button>
          <button class="advanced-search-btn -exit" (click)="showRenamePopup=false;findingToUpdate=null" >
              Cancel
        </button>
      </div>
      </div>
    </div>
<app-add-finding *ngIf="addNewFinding" (closed)="onCloseAddNewFinding()"></app-add-finding>
  <aside class="l-sidebar l-flex l-column">
    <img class="off-mobile sidebar-logo" src="../../assets/icons/logo.svg" alt="Artisan Sonics" />
    <div class="userPanel"></div>
    <nav class="mainNav l-fg1 scroller"></nav>
    <app-docpanel></app-docpanel>
  </aside>

  <app-bottombar></app-bottombar>
