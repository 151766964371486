import { Component, OnInit, OnDestroy } from '@angular/core';
import {MeasgrowthService} from '../measgrowth.service';
import { UntypedFormBuilder,FormGroup,FormControl } from '@angular/forms';
import {DopplerService} from '../doppler.service';
import {ExamsService} from '../exams.service';
import { Observable, Subscription } from 'rxjs';
import {AutotextService} from '../autotext.service';
import { PregnancyService } from '../pregnancy.service';
import { PatientsService } from '../patients.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-doppler',
  templateUrl: './doppler.component.html',
  styleUrls: ['./doppler.component.scss']
})
export class DopplerComponent implements OnInit,OnDestroy {
  fetusSub:Subscription;
  dopSub:Subscription;
  mediaSub: Subscription;
  dopplerLoaded=false;
  public isLMPMissing: boolean;
  atSTitleGeneralDopplerComments = this.translate.instant('GUI.Auto Text.Titles.General Doppler Comments');
  atSTitleDuctosVenosusComments = this.translate.instant('GUI.Auto Text.Titles.Ductos Venosus Comments');

  constructor(
    private ms: MeasgrowthService,
    private fb: UntypedFormBuilder,
    public dops: DopplerService,
    public es: ExamsService,
    public atS: AutotextService,
    public pregnancyService: PregnancyService,
    public patientsService: PatientsService,
    private translate: TranslateService,
  ) {
    ms.setGA();
    this.dopSub=this.dops.loaded$.subscribe(()=>{
      this.dopplerLoaded=true;
      this.dopSub.unsubscribe();
    });
   
  }

  ngOnInit() {
    this.dops.loadData();
    this.fetusSub = this.es.fetusChange$.subscribe(()=> {
      this.dops.loadData();
    });
    this.es.getSRs();
    if(!this.es.isClosed)
      this.mediaSub =  this.es.mediaArrived$.subscribe((mediatype)=>{
        if(mediatype == 'html')
          this.dops.loadData();
      });

    const LMP = this.pregnancyService.pregForm.get("LMP").value;
    const LMP_US = this.pregnancyService.pregForm.get("LMP_US").value;
    
    if (LMP || LMP_US) {
      this.isLMPMissing = false;
    } else {
      this.isLMPMissing = true;
    }
  }

  ngOnDestroy(): void {
    this.fetusSub.unsubscribe();
    this.dopSub.unsubscribe();
    if(this.mediaSub)
      this.mediaSub.unsubscribe();
  }

}
