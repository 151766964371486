<form [formGroup]="ps.pregForm">
    <section class="m-box" [ngStyle]="(es.ExamType === 'Fetal Echo' || es.ExamType === 'Pediatric Echo') && type === 'CVS' ? {'margin': '0.87em 0 0', 'padding': '1.3em 0 0', 'margin-top': '1em'} : {'margin': '0.87em 0 0', 'padding': '1em 0 0'}"
    *ngIf="type !=='Remarks'" >
        <h2  style="cursor: pointer;">
            {{
                (es.ExamType === "Fetal Echo" || es.ExamType === "Pediatric Echo") && type === "CVS"
                  ? ("pregnancy.MedicalHistory.CVS.fetal" | translate)
                  : title
              }}
        </h2>
        <div class="l-flex-c l-flex-w indicative-fieldset h100" style="justify-content: space-between;z-index: auto;" *ngIf="type !== 'OBSFormula'">
            <section style="display: flex;min-width: 40%;min-height: 4.2em;">
                <label class="t-label-top" (dblclick)="reset()">
    
                    <input type="radio" [formControl]="control" [name]="type" [value]="1" class="custom-check" />
                    <i class="customInput-display"></i>
                    <span>
                        <!-- Yes -->
                        {{'general.PositiveNegative.yes' | translate | titlecase}}
                    </span>
    
                </label>
                <ng-container *ngIf="control.value === 1">
                    <mat-form-field style="width: 100%;">
                        <input type="text"
                               style="direction: rtl;text-align-last: right;text-align: right;"
                               matInput
                               [formControlName]="type+'Text'"
                               [matAutocomplete]="auto">
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  >
                          <mat-option style="text-align: right;" *ngIf="! checkIn()" [value]="ps.pregForm.get(type+'Text').value" (onSelectionChange)="addAndSave(ps.pregForm.get(type+'Text').value)">{{ps.pregForm.get(type+'Text').value}}</mat-option>
                          <div *ngFor="let option of filteredOptions" style="display: flex;">                          
                            <mat-option style="text-align: right;width:100%"  [value]="option">
                                {{option}}
                            </mat-option> <button class="auto-text-delete-button" (click)="delete(option)" style="height: auto;">delete</button>
                          </div>
                        </mat-autocomplete>
                    </mat-form-field>
                </ng-container>
            </section>
    
            <label class="t-label-top" style="width: fit-content;" (dblclick)="reset()">
                <input type="radio" [formControl]="control" [name]="type" [value]="0" class="custom-check" />
                <i class="customInput-display"></i>
                <span>
                    <!-- No -->
                    {{'general.PositiveNegative.No' | translate | titlecase}}

                </span>
            </label>
    
            <section style="display: flex;width: 40%;justify-content: end;padding-inline-end: 1em;" [ngClass]="getClass(type)">
                <span>
                    <!-- Comments -->
                    {{'general.Comments' | translate | titlecase}}

                </span>
                <span class="anatomy-show-more" style="bottom:1em;right:6em">
                    <!--form elemnts not working when empty so using hack-->
            
                    <div class="on-hoverPanel">
            
                        <div class="on-hoverPanel-body">
            
                            <div class="anatomy-item-details">
            
                                <h3 class="anatomy-item-details-title">
                                    {{title}}
                                </h3>
            
                                <div class="anatomy-items-desc">
            
                                    <div class="anatomy-items-edited scroller mCustomScrollbar _mCS_2 mCS_no_scrollbar"
                                        contenteditable="true">
                                        <div id="mCSB_2" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" tabindex="0"
                                            style="max-height: none;">
                                            <div id="mCSB_2_container" class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                                                style="position:relative; top:0; left:0;" dir="ltr"> </div>
                                            <div id="mCSB_2_scrollbar_vertical"
                                                class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
                                                style="display: none;">
                                                <div class="mCSB_draggerContainer">
                                                    <div id="mCSB_2_dragger_vertical" class="mCSB_dragger"
                                                        style="position: absolute; min-height: 30px; top: 0px; display: block; height: 12px; max-height: 106.938px;">
                                                        <div class="mCSB_dragger_bar" style="line-height: 30px;"></div>
                                                    </div>
                                                    <div class="mCSB_draggerRail"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <textarea class="anatomy-items-edit" [name]="type+'Comment'"
                                        [formControlName]="type+'Comment'"></textarea>
            
            
                                </div>
            
                            </div>
            
                        </div>
            
                    </div>
            
                </span>
            </section>
        </div>
        <div *ngIf="type === 'OBSFormula'">
            <section class="secLine" style="min-height: 4.2em;height: 100%;padding: 0 0.5em;">
                <div *ngIf="modality!='Ultrasound'" style="display: flex;place-self: center;">
                    <section style="margin: 0.5em 0.5em 0;">
                        <label style="padding: 0 0.5em 0 0;">Gravida</label>
                        <select class="t-select t-field" formControlName="Gravida" name="Gravida" (click)="ps.pregForm?.get('Para')?.setValue(0);ps.pregForm?.get('Abortion')?.setValue(0)">
                            <option [value]="1">1</option>
                            <option [value]="2">2</option>
                            <option [value]="3">3</option>
                            <option [value]="4">4</option>
                            <option [value]="5">5</option>
                            <option [value]="6">6</option>
                            <option [value]="7">7</option>
                            <option [value]="8">8</option>
                        </select>
                    </section>
                    <section style="margin: 0.5em 0.5em 0 ;">
                        <label style="padding: 0 0.5em 0 0;">Para</label>
                        <select class="t-select t-field" formControlName="Para" name="Para">
                            <option [value]="0">0</option>
                            <option [value]="1">1</option>
                            <option [value]="2">2</option>
                            <option [value]="3">3</option>
                            <option [value]="4">4</option>
                            <option [value]="5">5</option>
                            <option [value]="6">6</option>
                            <option [value]="7">7</option>
                            <option [value]="8">8</option>
                        </select>
                    </section>
                    <section style="margin: 0.5em 0.5em 0;">
                        <label style="padding: 0 0.5em 0 0;">Abortion</label>
                        <select class="t-select t-field" formControlName="Abortion">
                            <option [value]="0">0</option>
                            <option [value]="1">1</option>
                            <option [value]="2">2</option>
                            <option [value]="3">3</option>
                            <option [value]="4">4</option>
                            <option [value]="5">5</option>
                            <option [value]="6">6</option>
                            <option [value]="7">7</option>
                            <option [value]="8">8</option>
                        </select>
                    </section>
                </div>
                <div style="display: flex;place-self: center;" *ngIf="modality=='Ultrasound'">
                    <section style="margin: 0.5em 0.5em 0;">
                        <label style="padding: 0 0.5em 0 0;">Gravida</label>
                        <select class="t-select t-field" formControlName="Gravida_US" name="Gravida" >
                            <option [value]="ps.pregForm.get('Gravida_US').value">{{ps.pregForm.get('Gravida_US').value}}</option>
                        </select>
                    </section>
                    <section style="margin: 0.5em 0.5em 0;">
                        <label style="padding: 0 0.5em 0 0;">Para</label>
                        <select class="t-select t-field" formControlName="Para_US" name="Para" >
                            <option [value]="ps.pregForm.get('Para_US').value">{{ps.pregForm.get('Para_US').value}}</option>
                        </select>
                    </section>
                    <section style="margin: 0.5em 0.5em 0;">
                        <label style="padding: 0 0.5em 0 0;">Abortion</label>
                        <select class="t-select t-field" formControlName="Abortion_US" name="Abortion" >
                            <option [value]="ps.pregForm.get('Abortion_US').value">{{ps.pregForm.get('Abortion_US').value}}</option>
                        </select>
                    </section>
                </div>
            </section>
        </div>
    </section>
    
    <div class="summary-recommendations summary" *ngIf="type === 'Remarks'" style="margin-top: 1em;min-height: 4.2em;">    
        <section class="m-box" style="padding: 0.87em 0.87em 0;">
            <h2>
                {{title}}
            </h2>
            <textarea class="scroller scroller-intro fulltext" formControlName="Remarks" name="Remarks" style="height: fit-content;min-height: 4.3em;"></textarea>
            <button class="t-btn-edit" *ngIf="! es.isClosed" 
            (click)="atS.showAuto(ps.pregForm.get('Remarks'),atSTitleMedicalHistory, 1)">Auto Text</button>
        </section>
    </div>
</form>
