import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Patient } from '../app/patients/patient';
import { PregnancyService } from './pregnancy.service';
import { PatientsService } from './patients.service';
import { ExamsService } from './exams.service';
import { MeasgrowthService } from './measgrowth.service';
import { NtService } from './nt.service';
import { Subject, Subscription } from 'rxjs';
import { Measure } from './measurements/meaure';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { DiagnosisService } from './diagnosis.service';
import { PathologyService } from './pathology.service';
import { MedicationService } from './medication.service';
import { GastroService } from './gastro.service';
import { FetalechoService } from './fetalecho.service';
import { FormControl } from '@angular/forms';
import { LoginService } from './login.service';
import { CardioService } from './cardio/cardio.service';
import { ProcedureService } from './procedure.service';
import { UltrasoundMeasurementsService } from './ultrasound-measurements.service';



@Injectable({
  providedIn: 'root'
})
export class PrintService {

  examID: number;
  PID: number;
  code: string;
  public Pat: Patient;
  public exam;
  public Medication;
  public gastro;
  public pathology;
  public pregnancy;
  public indications;
  public placenta;
  public cns;
  public chest;
  public digest;
  public face;
  public skeleton;
  public urology;
  public uterus;
  public adnexa;
  public GYNgeneral;
  public summary;
  public invasive;
  public follicles;
  public Forms;
  public chestFetal;
  public Anamnesis;
  public nt;
  public loaded = false;
  public doppler;
  public cardioGeneral;
  public cardioFindings;
  public cardio2D;
  public cardioDoppler;
  public cardioTissue;
  public flowmeas;
  public fetusList: number[];
  public measCache: Measure[][];
  public chartData: ChartDataSets[][][] = [];
  public hasMeas: boolean[] = [];
  public images: any[] = [];
  fetchImages: boolean;
  public chartsCache;
  isOBS: boolean;
  isForm: boolean;
  isFetalEcho: boolean;
  isWeightAssesment: boolean;
  public indicationList: any;
  public diagnosisList: any;
  public signers;
  public allImages = false;
  public env;
  public surrogate;
  public showPercentiles = true;
  NONOBS = ['Gynecology', 'Follicles','Consultation Letter', 'Inform Consent', 'TTE Adults','Duplex Carotid', 'Stress', 'Stress-Echo', 'Colonoscopy', 'Gastroscopy', 'Sigmoidoscopy'];
  isCardio = false;
  isGastro = false;
  public printAllMeas;
  diagnosticsTables=['summary','digest','chest','cns','face','urology','skeleton','uterus','adnexa'];
  public bubbleTitle:boolean=true /**for consultation letter only */



  loadct: number;
  loadMsg: Subject<null>;
  chartsWaited: Subject<null>;
  sub: Subscription;

  constructor(private medS: MedicationService, public pathologyS: PathologyService, private http: HttpClient, private ps: PregnancyService, public es: ExamsService, private meas: MeasgrowthService, private patserv: PatientsService, private ntS: NtService, private diagnosis: DiagnosisService, private gs: GastroService, public ls: LoginService, public cardioS: CardioService, public pr:ProcedureService,private us:UltrasoundMeasurementsService) {
    this.loadMsg = new Subject();
    this.chartsWaited = new Subject();
    this.printAllMeas = true;
  }

  loadExam(id: number, PID: number, doImages: boolean, code: string = null, gastro = false) {
    this.loaded = false;
    this.loadct = 0;
    this.examID = id;
    this.PID = PID;
    this.code = code;
    this.getPatient();
    this.fetchImages = doImages;
  }

  getPatient() {
    let args = { 'PatID': this.PID }
    if (this.code) {
      args['Outside'] = '1'
      args['code'] = this.code.toString()
    }
    this.http.post("api/patient", args).subscribe((data) => {//need to use a token to open up a new session for pupeteer
      if (!data.hasOwnProperty('empty'))
        this.Pat = new Patient('3', '', '', '');
      this.Pat.asignVals(data);
      this.patserv.setPat(this.Pat);
      this.http.get(`/api/getenv`).subscribe((data) => {
        this.env = data;
        if (data['haslogo']) {
          this.ls.getImageFromService();
        }
        
        if (data["printSkipPercentiles"])
          this.showPercentiles = false;
        this.getExam();
      }, (error) => {
        console.log(error);
      });

    },
      (error) => {
        console.log(error);
      });
  }

  getExam() {
    this.loaded = false;
    this.loadct = 0;
    this.isForm = false;
    this.isFetalEcho = false;
    this.http.get("/api/exam", { params: { 'ExamID': this.examID.toString(), 'PID': this.PID.toString() } }).subscribe((data) => {
      this.exam = data["exam"];
      this.es.examID = this.examID;
      this.es.CreationTime = this.exam["CreationTime"];
      this.es.ExamType = data["exam"]["ExamType"];
      this.es.changedTitle = data["exam"]["Changed"];
      this.es.newTitle = data["exam"]["ChangedExamTitle"];
      this.es.switchNT = data["exam"]["Changed"] && (data["exam"]["ChangedExamTitle"] == '1st & NT')
      this.es.addNT = data["exam"]["Changed"] && (data["exam"]["ChangedExamTitle"] == 'Early & NT')
      this.es.switchOvaries = data["exam"]["addOvaries"];
      this.es.modality=data["exam"]["Modality"];
      if(this.env['hasSurrogatePreg']){
        this.patserv.getSurrogate(this.Pat.PID,this.examID).subscribe((data)=>{
          this.surrogate = data;
        },(error)=>{
          console.log(error);
        })
      }
      if (!this.NONOBS.includes(data["exam"]["ExamType"])) {//obs
        this.isOBS = true;

        if (data["exam"]["ExamType"] === 'Fetal Echo' || data["exam"]["ExamType"] === 'Pediatric Echo') {
          this.isFetalEcho = true;
          this.printAllMeas = data["exam"]['printAllMeas'];
        }
        this.pregnancy = data["exam"]["PregCopied"] ? data["exam"] : data["pregnancy"];
        if(data["pregnancy"]){
          data["exam"]['Abortion'] = data["pregnancy"]["Abortion"]
          data["exam"]['Para'] = data["pregnancy"]["Para"]
          data["exam"]['Gravida'] = data["pregnancy"]["Gravida"]
          data["exam"]['Gravida_US'] = data["pregnancy"]["Gravida_US"];
          data["exam"]['Abortion_US'] = data["pregnancy"]["Abortion_US"]
          data["exam"]['Para_US'] = data["pregnancy"]["Para_US"]
          this.es.pregId = data["pregnancy"]["AutoIndex"];

        }
        if (data["exam"]["PregCopied"])
          this.pregnancy["AutoIndex"] = data["exam"]["PregIndex"];
        if (!this.pregnancy.LMP) {//first time exam is opened
          this.pregnancy.LMP = this.ps.pregForm.get("LMP").value;
          this.pregnancy.LMP_ULTRASOUND = this.ps.pregForm.get("LMP_ULTRASOUND").value;
        }
        this.fetusList = [];
        for (let i = 0; i < this.pregnancy.NumFetuses; i++) {
          this.fetusList.push(i);
        }
        this.ps.setPreg(this.pregnancy, true);
        this.ps.setExamID(this.examID, this.exam["CreationTime"]);
        this.meas.setGA();
        this.meas.fetchValues(null,true);
        this.measCache = [];
        this.chartsCache = [];

        if(this.es.modality == "Ultrasound"){
          this.us.loadMeasurements();
        }

        this.meas.loaded.subscribe(() => {
          this.measCache[this.es.getFetus() - 1] = [];
          this.chartsCache[this.es.getFetus() - 1] = [];
          for (let m in this.meas.measref) {
            this.measCache[this.es.getFetus() - 1][m] = this.meas.measref[m];
            if (this.meas.measref[m].value && this.meas.measref[m].value != -1)
              this.hasMeas[this.es.getFetus() - 1] = true;
            this.chartsCache[this.es.getFetus() - 1][m] = this.meas.getAllPoints(this.meas.measref[m].name);
          }
          if (this.meas.Comment) {
            this.measCache[this.es.getFetus() - 1]["Comment"] = this.meas.Comment;
            this.hasMeas[this.es.getFetus() - 1] = true;
          }

          if (this.pregnancy.NumFetuses > this.es.getFetus()) {
            this.es.setFetus(this.es.getFetus() + 1);
            this.meas.fetchValues(null, true);
          }
          else {
            this.getChartData();
            this.loadData('nt');//wait for nt so the measures will be set
          }
         
          if(this.env['measDefaultNone'])
            this.meas.addNone();
          
        });
        this.pregnancy["lmpusedd"] = this.ps.lmpUSGA(this.pregnancy.LMP_US, this.pregnancy.LMPUSweeks, this.pregnancy.LMPUSdays);
        if (this.pregnancy.Ovulation)
          this.pregnancy.edd = this.ps.eddOvolation(this.pregnancy.Ovulation);
        else {
          if (this.pregnancy.LMP_US) {
            this.pregnancy.edd = this.ps.USedd(this.pregnancy.LMP_US, this.pregnancy.LMPUSweeks, this.pregnancy.LMPUSdays);
          }
          else
            this.pregnancy.edd = this.ps.edd(false, this.pregnancy.LMP);
        }
      }
      else {
        this.isOBS = false;
        if (data["exam"]["ExamType"] == 'Consultation Letter' || data["exam"]["ExamType"] == 'Inform Conset')
          this.isForm = true;
        if (data['exam']['ExamType'] == 'Duplex Carotid' || data['exam']['ExamType'] == 'TTE Adults' || data['exam']['ExamType'] == 'Stress' || data['exam']['ExamType'] == 'Stress-Echo') {
          this.isCardio = true;
        }
        if (data['exam']['ExamType'] == 'Colonoscopy' || data['exam']['ExamType'] == 'Gastroscopy' || data['exam']['ExamType'] == 'Sigmoidoscopy') {
          this.isGastro = true;
          this.pathologyS.getPathologies();
          this.medS.getAllMedication(data['exam']['AutoIndex']);
          this.gs.getATS();
        }
        if (data["exam"]["ExamType"] == 'Fetal Echo' || data["exam"]["ExamType"] == 'Pediatric Echo')
          this.isFetalEcho = true;
      }

      /*this.http.get('/api/whoami',{params: {'ExamID': this.examID.toString()}}).subscribe((nm)=>{
        this.DocName=nm['title']+ ' ' + nm['iam'];
        this.DocLicence = nm['expertNumber'];
        if(nm['signature'])
          this.DocSignature = nm['signature'];
      }, (error)=>{
        console.log(error);
      });*/

      if (this.fetchImages)
        this.getImages();
      if (this.isOBS) {
        //this.doppler.loadData();
        this.loadData("placenta");
        this.loadData("urology");
        this.loadData("skeleton");
        this.loadData("face");
        this.loadData("digest");
        this.loadData("cns");
        this.loadData("chest");
        this.loadData("doppler");
        this.loadData("chestFetal");
        this.loadData("invasive");
        this.loadData('Anamnesis')
        // this.loadData("follicles");
        this.loadData("Medication");
        if(data['exam']['addOvaries']){
          this.loadData("uterus");
          this.loadData("adnexa");
        }

        

      }
      else {
        if (!this.isForm && !this.isCardio && !this.isGastro) {
          this.loadData("GYNgeneral");
          this.loadData("uterus");
          this.loadData("adnexa");
          this.loadData("invasive");
          this.loadData("follicles");
          this.loadData('Anamnesis');

        }
      }
      if (this.isCardio) {
        this.loadData("cardioGeneral");
        this.loadData("cardio2D");
        this.loadData("cardioDoppler")
        this.loadData("cardioTissue")
        this.loadData("cardioFindings");
        this.loadData("flowmeas");
        

      }
      if (this.isGastro) {
        this.loadData("gastro")
        this.loadData("Medication")
        this.loadData("pathology")
        // this.loadData("GastroForms")
      }
      if (!this.isForm && !this.isGastro)
        this.loadData("summary");
      if (this.isForm)
        this.loadData("Forms");
      this.getSigners();
      
      if (!this.isForm ){
      const calculations={'LMP_GAcalc':this.ps.lmpGA(this.pregnancy.LMP),'LMPBYUS_GAcalc':this.ps.lmpUSGA(this.pregnancy.LMP_US,this.pregnancy.LMPUSweeks,this.pregnancy.LMPUSdays),'EDD_calc':this.pregnancy.edd,'Ovulation_GAcalc':this.ps.ovulationGA(this.pregnancy.Ovulation),
      'LMP_Ovulation_calc':this.ps.ovulationLMP(this.pregnancy.Ovulation),'LMPBYUS_calc':this.ps.lmpUS(this.pregnancy.LMP_US,this.pregnancy.LMPUSweeks,this.pregnancy.LMPUSdays)}
      this.sendPregCalculations(calculations,this.examID);
      }
    }, (error) => {
      console.log(error);
    });
  }

  getScore(fetus: number): string {//starting from fetus 0
    let score = 0;
    let possible = 0;
    let opts = ["AFI", 'Move', 'Tonus', 'Breath'];
    for (let i = 0; i < opts.length; i++) {
      if (this.placenta[fetus]["Score" + opts[i]] > -1) {
        score += this.placenta[fetus]["Score" + opts[i]];
        possible += 2;
      }
    }
    return score + "/" + possible;
  }

  
  params(table,i) {
    let params: {};
    if(this.es.ExamType === 'Pediatric Echo'){
      params = {
        ExamID: this.es.examID.toString(),
        table
      }
    } else {
      params = {
        fetus: i.toString(),
        ExamID: this.es.examID.toString(),
        table
      };
    }
    return params;
  }


  loadData(table: string) {
    let fetuses = 1;
    if (this.pregnancy && this.pregnancy.NumFetuses && table != "summary")
      fetuses = this.pregnancy.NumFetuses;
    if (!this[table])
      this[table] = [];
    for (let i = 1; i <= fetuses; i++) {
      this.http.get("/api/get", { params: this.params(table,i) }).subscribe((data: []) => {
        this.loadct++;
        if (data.hasOwnProperty('empty')) {
          if ((this.isOBS && this.loadct == 8 * this.pregnancy.NumFetuses - (this.pregnancy.NumFetuses == 1 ? 0 : this.pregnancy.NumFetuses - 1)) ||
            (!this.isOBS && this.loadct == 3) || this.isForm || this.isCardio || this.isGastro) {
            this.doWait();
            this.loaded = true;
            this.loadMsg.next();
          }
          for (let j = 0; j < i - 1; j++) {//check is any fetuses have values
            if (!this[table] || this[table][j])
              return;
          }
          //if no fetuses with value set as null
          this[table] = null;
          return;
        }
        if (table == 'nt')
          this.loadNT(data, i);


        if (!this[table])
          this[table] = [];
        switch (table) {
          case "summary":
            this[table][0] = data;
            this[table][0]['AnomaliesList'] = this.diagnosis.BuildList(data['DiagnosisCodes'], true);
            this[table][0]['IndicationList'] = this.diagnosis.BuildList(data['IndicationCodes']);
            this.indicationList = this.diagnosis.BuildList(data['IndicationCodes']);
            this.diagnosisList = this.diagnosis.BuildList(data['DiagnosisCodes'], true);
            this.sendDiagnostic(table,this.diagnosisList,undefined,this.es.examID);
            this.sendSummary(this.es.examID,this[table][0]['Diagnosis']);

            break;
          case 'pathology':
            this[table][0] = data;
            break;
          case 'Medication':
            this[table][0] = data;
            break;
          case 'Anamnesis':
            this[table]=data;
            break
          case 'follicles':
            this[table][data["InstanceNumber"] - 1] = data;
            break;
          case 'gastro':
            this[table][0] = data;
            // this.gs.getATS()
            this.gs.buildList(data['DignosisTree']);
            this[table][0]['DiagnosisList'] = this.gs.diagnosis;
            this[table][0]['DetailsFindings'] = this.gs.getFindings(data);
            break
          case "uterus": case "adnexa": case 'GYNgeneral': case 'cardioGeneral': case 'cardio2D': case 'cardioDoppler': case 'cardioTissue': case 'cardioFindings':case 'flowmeas':
            this[table] = data;
            this[table]["DiagnosisList"] = this.diagnosis.BuildList(data['DiagnosisCodes'])
            // if (table == "cardio2D" || table == "cardioDoppler" || table == "cardioTissue") {
            //   this.cardioS.addToFindings(data)
            // }
            this['summary']['Findings'] = this.cardioS.Findings;
            if (table == "uterus"){
              this.setMyomas();
              this.sendSizeVol(this.es.examID,this.UterusVol())
              this.sendDiagnostic(table,this[table]['DiagnosisList'],undefined,this.es.examID)

            }
            if (table == 'adnexa') {
              this.ovaryVolume();
              this.sendDiagnostic(table,this[table]['DiagnosisList'],undefined,this.es.examID);
              // this.sendOvaryVol(this.es.examID,this.adnexa["LVol"],"left")
              // this.sendOvaryVol(this.es.examID,this.adnexa["RVol"],"right")
            }
            if (table=='GYNgeneral'){
              this.GYNgeneral?.LMP?
              this.sendGynLmpDaysCalculations(this.getDays(this.GYNgeneral.LMP),this.es.examID):
              this.sendGynLmpDaysCalculations(null,this.es.examID)
            }
            break;
          default://obs
          if(this.es.ExamType==='Pediatric Echo' && table==='chestFetal'){
            this[table][0]=data
          }
          else{
            this[table][data["InstanceNumber"] - 1] = data;
          }
            try{
              if(table != 'invasive' && table != 'placenta')
                this[table][0]['DiagnosisList'] = this.diagnosis.BuildList(data['DiagnosisCodes'])
                if (this.diagnosticsTables.includes(table)){
                  this.sendDiagnostic(table,this[table][0]['DiagnosisList'],data["InstanceNumber"],this.es.examID)
                }
                if(table=='Forms' && this.es.ExamType=='Consultation Letter'){
                  this.FormTitleCheck()
                }
            }
            catch(e){
              console.log(e);
            }
            break;
        }
        if (table == 'placenta' && this[table][data["InstanceNumber"] - 1]['SacLoc']) {
          try { this.placenta[data["InstanceNumber"] - 1]['SacLocs'] = this[table][data["InstanceNumber"] - 1]['SacLoc'].split(',') }
          catch { }
        }
        if ((this.isOBS && this.loadct == 8 * this.pregnancy.NumFetuses - (this.pregnancy.NumFetuses == 1 ? 0 : this.pregnancy.NumFetuses - 1)) ||
          (!this.isOBS && this.loadct == 3) || this.isForm || this.isCardio || this.isGastro) {
          this.doWait();
          this.loaded = true;
          this.loadMsg.next();
        }
        if(table=='placenta'){
          // i is the fetus 
          let fetus=i
          // save calculated value total score for each fetus
          this.sendTotalScore(this.es.examID,this.getScore(fetus-1),fetus)
        }
      }, (error) => {
        console.log(error);
      })
    }

  }

  sendTotalScore(ExamID,value,fetus) {
    this.http.post('/api/saveGAGGtoDB/placenta',{ExamID,value,fetus}).subscribe((data)=>{
      console.log(data);
      })
  }

  sendDiagnostic(table,value,fetus,ExamID){
   if (!value){
       return
   }
    let diagnosis="";
    value.forEach(diag => {
      diagnosis+=diag[1]+", "
    });
    this.http.post('/api/saveDiagnostics',{table,value:diagnosis,fetus,ExamID}).subscribe((data)=>{
      console.log(data);
      })  
  }
 
  sendSizeVol(ExamID,value){
  if (!ExamID ) return;
  this.http.post('/api/saveGAGGtoDB/uterus',{ExamID,value}).subscribe((data)=>{
    console.log(data);
    })
  }

  
  sendSummary(ExamID,value){
    if (!ExamID ) return;
    this.http.post('/api/saveGAGGtoDB/summaryHtml',{ExamID,value}).subscribe((data)=>{
      console.log(data);
      })
    }
  
  //  sendOvaryVol(ExamID,value,side){
  //   if (!ExamID ) return;
  //   this.http.post('/api/saveGAGGtoDB/ovariesVol',{ExamID,value,side}).subscribe((data)=>{
  //     console.log(data);
  //     })
  //  } 
  loadNT(data, i) {
    if (data['NT']) {
      this.hasMeas[i - 1] = true;
      this.measCache[i - 1]['NT'].value = data['NT'];
      if (i == 1)
        for (let field in this.ntS.NTForm.controls) {
          if (data[field])
            this.ntS.NTForm.get(field).setValue(data[field], { emitEvent: false });
        }
    }
    if (data['CRL']) {
      this.hasMeas[i - 1] = true;
      this.measCache[i - 1]['CRL'].value = data['CRL'];
      if (data['CRL_GGfig'])
        this.measCache[i - 1]['CRL'].GGfig = data['CRL_GGfig'];
      else
        this.measCache[i - 1]['CRL'].GGfig = "Hadlock";
      if (data['CRL_GAfig'])
        this.measCache[i - 1]['CRL'].GAfig = data['CRL_GAfig'];
      else
        this.measCache[i - 1]['CRL'].GAfig = "Hadlock";
      this.measCache[i - 1]['CRL'].calcGATable(this.measCache[i - 1]['CRL'].GAfig);
      this.measCache[i - 1]['CRL'].calcGGTable(this.es.examGA());
    }
    if (data["GS"]) {
      this.hasMeas[i - 1] = true;
      this.measCache[i - 1]['GS'].value = data['GS'];
      this.measCache[i - 1]['GS'].calcGATable("Nyberg");
    }
    this.ntS.loadData(data);
    // send all nt calculated values 
    this.sendRisksNt(this.ntS.getAge(),this.ntS.calcRiskAge(this.ntS.getAge()),this.ntS.calcRiskCRL(this.ntS.calcRiskAge(this.ntS.getAge()),data['CRL'],data['NT']),i)
    this.sendNtGAGG(i,"CRL_GAcalc",this.measCache[i - 1]['CRL'].GA)
    this.sendNtGAGG(i,"CRL_GGcalc",this.measCache[i - 1]['CRL'].GG)
    this.sendNtGAGG(i,"GS_GAcalc",this.measCache[i - 1]['GS'].GA)
    
  }

  sendRisksNt(age,riskByAge,calculatedRisk,fetus){
    this.http.post('/api/saveGAGGtoDB/ntRisks',{age,riskByAge,calculatedRisk,fetus,examID:this.es.examID,table:"nt"}).subscribe((data)=>{
      console.log(data);
      })
  }

  sendNtGAGG(fetus,measureName,value){
    this.http.post('/api/saveGAGGtoDB/nt',{name:measureName ,value,fetus,examID:this.es.examID,table:"nt"}).subscribe((data)=>{
      console.log(data);
      })
  }

  sendPregCalculations(values,ExamID){
    this.http.post('/api/saveGAGGtoDB/preg',{values,ExamID}).subscribe((data)=>{
      console.log(data);
      })

  }

  sendGynLmpDaysCalculations(value,ExamID){
   this.http.post('/api/saveGAGGtoDB/gynCalculations',{value,ExamID}).subscribe((data)=>{
    console.log(data);
   })
 }
  doWait() {
    let c = this.chartsWaited;
    setTimeout(function () {
      c.next();
    }, 200);
  }

  getMeas(mname: string, fetus: number, gao: string[] = [], ggo: string[] = [], min: number = 0, max: number = 1000): Measure {
    if (mname == "Weight1") {
      return this.measCache[fetus]["EFW (AC FL)"];
    }
    if (mname == "Weight4")
      return this.measCache[fetus]["EFW (BPD HC AC FL)"];
    if (this.measCache[fetus].hasOwnProperty(mname))
      return this.measCache[fetus][mname];
    //else for doppler, etc
    //for later just take the options from the file - don't reuire beforehane
    return new Measure(mname, gao, ggo, min, max);
  }

  getChartData() {
    let mnames = Object.keys(this.meas.measref);
    for (let fetus = 0; fetus < this.pregnancy.NumFetuses; fetus++) {
      this.chartData[fetus] = [];
      for (let name of mnames) {
        let mymeas = this.measCache[fetus][name];
        if (!mymeas.value || !mymeas.GG)
          continue;
        let myValues: ChartDataSets = {
          data: this.chartsCache[fetus][name],
          pointStyle: 'crossRot',
          hitRadius: 4,
          pointRadius: 10,
          borderWidth: 2,
          pointBorderColor: '#015B6F',
          pointHoverBorderColor: '#015B6F',
          backgroundColor: '#015B6F',
          borderColor: '#015B6F',
          label: 'Measured'
        };

        this.chartData[fetus][name] = mymeas.getBoundaries(mymeas.GGfig, myValues);
      }
    }

  }

  UterusVol() {
    if (this.uterus["SizeX"] && this.uterus["SizeY"] && this.uterus["SizeZ"])
      return Math.round((0.52 * this.uterus["SizeX"] * this.uterus["SizeY"] * this.uterus["SizeZ"]) / 100) / 10;
    return "";
  }

  ovaryVolume() {
    if(this.adnexa?.LSizeVol){
      this.adnexa["LVol"]=this.adnexa.LSizeVol
    }
    else if (this.adnexa.LSizeX && this.adnexa.LSizeY && this.adnexa.LSizeZ)
      this.adnexa["LVol"] = Math.round((0.5 * this.adnexa.LSizeX * this.adnexa.LSizeY * this.adnexa.LSizeZ) / 100) / 10;

      if(this.adnexa?.RSizeVol){
        this.adnexa["RVol"]=this.adnexa.RSizeVol
      }

     else if (this.adnexa.RSizeX && this.adnexa.RSizeY && this.adnexa.RSizeZ)
      this.adnexa["RVol"] = Math.round((0.5 * this.adnexa.RSizeX * this.adnexa.RSizeY * this.adnexa.RSizeZ) / 100) / 10;
    // if (!this.adnexa["LObserved"])
    //   this.adnexa["LObserved"] = 'Normal';
    // if (!this.adnexa["RObserved"])
    //   this.adnexa["RObserved"] = 'Normal';

    //also doppler ok, not ok
    let dopplers = ['LPI', 'LRI', 'LSD', 'RPI', 'RRI', 'RSD'];
    for (let dop of dopplers) {
      if (this.adnexa[dop + 'Status'] == 0)
        this.adnexa[dop + 'OK'] = 'OK';
      if (this.adnexa[dop + 'Status'] == 1)
        this.adnexa[dop + 'OK'] = 'NotOK';
    }
    if (this.adnexa.Fluid == 0)
      this.adnexa["Fluidv"] = 'yes';
    if (this.adnexa.Fluid == 1)
      this.adnexa['Fluidv'] = 'No';
  }

  getImages() {
    this.http.get('/api/gallerydetails', { params: { 'ExamID': this.examID.toString() } }).subscribe(
      (data) => {
        for (let i = 0; i < data["images"].length; i++) {
          if ((data["images"][i][1] != null && data["images"][i][1] == 1) || this.allImages) {
            var src = data["images"][i][0];
            var text = data["images"][i][2];
            var image = {
              src: src,
              text: text
            }
            if (this.es.ExamType == 'Colonoscopy' || this.es.ExamType == 'Gastroscopy' || this.es.ExamType =='Sigmoidoscopy') {
              this.images.push(image)
            } else {
              this.images.push(data["images"][i][0])
            }
          }

        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  setMyomas() {
    this.uterus.Myomas = [];
    if (this.uterus["MyomaStr"]) {
      let mlist = this.uterus["MyomaStr"].split('|');
      if (mlist) {
        for (let myoma of mlist) {
          if (myoma)
            this.uterus.Myomas.push(myoma.split(','));
        }
      }
    }
  }
  getDays(fdate): number {
    let fd = new Date(fdate);
    let now = new Date();
    return Math.floor((now.getTime() - fd.getTime()) / (24 * 60 * 60 * 1000))
  }

  getSigners() {
    this.http.get('/api/signers/' + this.examID).subscribe((data) => {
      this.signers = data;
    }, (error) => {
      console.log(error);
    });
  }

  hasExamMethod(inInvasive = false) {
    if(inInvasive){
      if (this['invasive'] && (this.invasive["Abdominal"] || this.invasive["Vaginal"] || this.invasive["Transperineal"] || this.invasive["Rectal"]))
        return true;
      return false;
    }else{
      if (this['summary'] && this['summary'][0] && (this.summary[0]["Abdominal"] || this.summary[0]["Vaginal"] || this.summary[0]["Transperineal"] || this.summary[0]["Rectal"]))
        return true;
      return false;
    }
  }

  lastExamMethod(m) {
    switch (m) {
      case 'Rectal':
        return true;
      case 'Transperineal':
        return !this.summary[0]["Rectal"];
      case 'Vaginal':
        return (!this.summary[0]["Transperineal"] && !this.summary[0]["Rectal"]);
      case 'Abdominal':
        return (!this.summary[0]["Vaginal"] && !this.summary[0]["Transperineal"] && !this.summary[0]["Rectal"]);
    }

  }

  FormTitleCheck(){
    const bubbleTitles=['Consultation Letter','Gynecology Consult Letter','Obstetrics Consult Letter','Visit Confirmation','Visit Summary','Summary And Visit Recommendation','Medical Prescription']
   if(bubbleTitles.includes(this.Forms[0].Title)){
    this.bubbleTitle=true
   }
   else{
    this.bubbleTitle=false
   }
  }

}
