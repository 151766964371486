<div class="body-wrapper" *ngIf="print.loaded" [ngClass]="Direction" style='padding-top: 0;'>
		<header class="header">
			<div class="justify-between items-center" [innerHtml]="altHeader" *ngIf="altHeader && showHeader" style="width: 100%;"></div>
			<div class="justify-between items-center" *ngIf="print.env && print.env['haslogo'] && false">
				<img [src]="! print.env['haslogo'] ? '' : '/api/logo'" alt="Logo">
			</div>
			<div class="justify-between items-center" style="margin-bottom: 5px;">
				<!-- <h1 class="header-title">{{'exam.type.GYNE.'+print.exam.ExamType | translate}}</h1> -->
				<h1 class="header-title" *ngIf="print.bubbleTitle">{{(print.isOBS? (print.exam.Changed ? ( titleArray.includes(this.print.exam.ChangedExamTitle) ?'exam.type.OBS.'+print.exam.ChangedExamTitle : print.exam.ChangedExamTitle ): 'exam.type.OBS.'+print.exam.ExamType) : (print.isForm? 'forms.'+print.Forms[0].Title :'exam.type.GYNE.'+print.exam.ExamType)) | translate}}</h1>
				<h1 class="header-title" *ngIf="!print.bubbleTitle">{{print.Forms[0].Title}}</h1>
				 <img [src]="! print.env['haslogo'] ? '../../assets/images/logo.png' : '/api/logo'" alt="Logo" class="header-logo" *ngIf="print.env && ! altHeader && print.env['haslogo']"><!-- uncommented this for the old style user logos-->
			</div>
			<div class="header-details">
				<div style="display: inline-block; padding-left: 1em;padding-right: 1em">{{(print.isForm ? 'general.date' :'general.ExaminationDate')| translate}}:&nbsp;<strong style='direction: ltr;'>{{print.exam.CreationTime | date:"dd.MM.yyyy HH:mm"}}</strong></div>
				<div style="display: inline-block">{{'general.Printing_Date' | translate}}:&nbsp;<strong style='direction: ltr;'>{{now| date:"dd.MM.yyyy HH:mm"}}</strong></div>
			</div>
		</header>
		<main class="main" [ngClass]="typeOfPrint">
			<section class="section" *ngIf="print.Pat">
				<h4 class="text-20 text-gold" *ngIf="!print.isForm">{{(print.isOBS || print.exam.ExamType == 'Gynecology' ? 'general.Patient.Patient_Details' : 'general.ExamDetails') | translate}}</h4>
				<h4 class="text-20 text-gold" *ngIf="print.isForm">{{'general.Patient.Patient_Details' | translate}}</h4>

				<div style="width:100%;">
					<div style="width: 100%;">
						<div style="display: inline-flex;">
							<table><tr>
								<td>
									<dt>{{'general.Patient.first_name' | translate}}</dt>
									<dd style="font-size: 20px;"><strong>{{print.Pat.First}}</strong></dd>
								</td>
								<td>
									<dt>{{'general.Patient.last_name' | translate}}</dt>
									<dd style="font-size: 20px;"><strong>{{print.Pat.Last}}</strong></dd>
								</td>
								<td>
									<dt>{{'general.Patient.ID' | translate}}</dt>
									<dd>{{print.Pat.ID}}</dd>
								</td>
								<td *ngIf="print.Pat.Birth">
									<dt>{{'general.Patient.birth_date' | translate}}</dt>
									<dd>{{print.Pat.Birth| date:"dd.MM.yyyy"}}</dd>
								</td>
								<td *ngIf="print.Pat.Birth">
									<dt>{{'general.Patient.Age' | translate}}</dt>
									<dd>{{print.Pat.getAgeMo()}}</dd>
								</td>
								<td *ngIf="print.Pat.Mobile">
									<dt>{{'general.Patient.mobile' | translate}}</dt>
									<dd>{{print.Pat.Mobile}}</dd>
								</td>
								<td *ngIf="print.Pat.Phone">
									<dt>{{'general.Patient.home_phone' | translate}}</dt>
									<dd>{{print.Pat.Mobile}}</dd>
								</td>
								<td *ngIf="print.Pat.Address">
									<dt>{{'general.Patient.address' | translate}}</dt>
									<dd>{{print.Pat.Address}}</dd>
								</td>
								<td *ngIf="print.Pat.City">
									<dt>{{'general.Patient.town' | translate}}</dt>
									<dd>{{print.Pat.City}}</dd>
								</td>
								<td *ngIf="print.Pat.Zip">
									<dt>{{'general.Patient.zip' | translate}}</dt>
									<dd>{{print.Pat.Zip}}</dd>
								</td>
								<td *ngIf="print.Pat.Email">
									<dt>{{'general.Patient.email' | translate}}</dt>
									<dd>{{print.Pat.Email}}</dd>
								</td>
								<td *ngIf="print.Pat.Weight">
									<dt>{{'general.Patient.weight' | translate}}</dt>
									<dd>{{print.Pat.Weight}}</dd>
								</td>
								<td *ngIf="print.Pat.Height">
									<dt>{{'general.Patient.height' | translate}}</dt>
									<dd>{{print.Pat.Height}}</dd>
								</td>
								<td *ngIf="print.Pat.Height && print.Pat.Weight">
									<dt>{{'general.Patient.bmi' | translate}}</dt>
									<dd>{{getBMI(print.Pat.Height, print.Pat.Weight)}}</dd>
								</td>
								<td *ngIf="print.Pat.BloodType">
									<dt>{{'general.Patient.blood_type' | translate}}</dt>
									<dd>{{print.Pat.BloodType}}</dd>
								</td>
								<td *ngIf="print.Pat.HMO">
									<dt>{{'general.Patient.hmo' | translate}}</dt>
									<dd>{{print.Pat.HMO}}</dd>
								</td>
								<td *ngIf="print.Pat.RefferDoc">
									<dt>{{'general.Patient.referring_doctor' | translate}}</dt>
									<dd>{{print.Pat.RefferDoc}}</dd>
								</td>
								<td *ngIf="print.Pat.Sex && print.exam.ExamType=='Pediatric Echo'">
								<dt>{{'general.Patient.gender' | translate}}</dt>
                                <dd>{{(print.Pat.Sex == 'M' ? 'general.Patient.Male' : 'general.Patient.Female') | translate}}</dd>
								</td>
							</tr>
							<tr *ngIf="print.surrogate">
								<td colspan="2">
									<dt>{{'surrogates.name' | translate}}</dt>
									<dd><strong>{{print.surrogate.First}} {{print.surrogate.Last}}</strong></dd>
								</td>
								<td>
									<dt>{{'surrogates.ID' | translate}}</dt>
									<dd><strong>{{print.surrogate.ID}}</strong></dd>
								</td>
								<td *ngIf="print.surrogate.Birth">
									<dt>{{'surrogates.birth' | translate}}</dt>
									<dd>{{print.surrogate.Birth| date:"dd.MM.yyyy"}}</dd>
								</td>
								<td *ngIf="print.surrogate.OrgID">
									<dt>{{'surrogates.orgid' | translate}}</dt>
									<dd>{{print.surrogate.OrgID}}</dd>
								</td>
							</tr>
						</table>
						<div *ngIf="typeOfPrint === 'fancy'" class="flex justify-center items-center ps-24" style="width: 20%;">
							<img src="../../assets/images/girl.svg" width="111" height="154" alt="Patient Icon">
						</div>
						</div>
						<hr *ngIf="print.pregnancy && print.exam.ExamType !== 'GYN' && print.exam.ExamType !== 'Follicles' ">
						<section class="section" *ngIf="print.pregnancy">
							<!-- pediatric Anamnesis start  -->
							  <div class="section-text" *ngIf="print.exam.ExamType==='Pediatric Echo'">
								<section *ngIf="print.Anamnesis?.IndicationsOfExam" class="section-text">
								  <h4 class="text-20 text-gold"><strong>{{'pregnancy.indication' | translate}}: </strong ></h4>
								  <p>{{print.Anamnesis?.IndicationsOfExam}}</p>
								 
								</section>
							  <h4 *ngIf="print.Anamnesis?.PreMedical !== -1 || print.Anamnesis?.CVS !== -1 || print.Anamnesis?.SurgBack !== -1 || print.Anamnesis?.Drugs !== -1 || print.Anamnesis?.MedicalHistory" class="text-20 text-gold">{{'pregnancy.MedicalHistory.title' | translate}}</h4>
                              <table>
								<tr>
									<td class="td-med" *ngIf="print.Anamnesis?.PreMedical !== -1">
										<dt class="dt-med" [ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{'pregnancy.MedicalHistory.PreMedical.title' | translate}}</dt>
										<dd class="dd-med" [ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' ">{{print.Anamnesis?.PreMedical ? (print.Anamnesis?.PreMedicalText ? print.Anamnesis?.PreMedicalText : 'pregnancy.MedicalHistory.PreMedical.yes' | translate) :  'pregnancy.MedicalHistory.PreMedical.no' | translate}}</dd>
									</td>
									<td *ngIf="print.Anamnesis?.PreMedical !== -1 && print.Anamnesis?.PreMedicalComment && print.Anamnesis?.PreMedicalComment.length > 0">
										<dt>{{'pregnancy.MedicalHistory.Comment' | translate}}</dt>
										<dd>{{print.Anamnesis?.PreMedicalComment}}</dd>
									</td>
								</tr>
                                
									<tr>
										<td class="td-med" *ngIf="print.Anamnesis?.CVS !== -1">
											<dt class="dt-med" [ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{ 'pregnancy.MedicalHistory.CVS.fetal' | translate}}</dt>
											<dd class="dd-med" [ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' ">{{print.Anamnesis?.CVS ? (print.Anamnesis?.CVSText ? print.Anamnesis?.CVSText : 'pregnancy.MedicalHistory.CVS.yes' | translate) :  'pregnancy.MedicalHistory.CVS.no' | translate}}</dd>
										</td>
										<td *ngIf="print.Anamnesis?.CVS !== -1 && print.Anamnesis?.CVSComment && print.Anamnesis?.CVSComment.length > 0">
											<dt>{{'pregnancy.MedicalHistory.Comment' | translate}}</dt>
											<dd>{{print.Anamnesis?.CVSComment}}</dd>
										</td>
									</tr>
									<tr>
										<td class="td-med" *ngIf="print.Anamnesis?.SurgBack !== -1">
											<dt class="dt-med" [ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{'pregnancy.MedicalHistory.SurgBack.title' | translate}}</dt>
											<dd class="dd-med" [ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' ">{{print.Anamnesis?.SurgBack ? (print.Anamnesis?.SurgBackText ? print.Anamnesis?.SurgBackText : 'pregnancy.MedicalHistory.SurgBack.yes' | translate) :  'pregnancy.MedicalHistory.SurgBack.no' | translate}}</dd>
										</td>
										<td *ngIf="print.Anamnesis?.SurgBack !== -1 && print.Anamnesis?.SurgBackComment && print.Anamnesis?.SurgBackComment.length > 0">
											<dt>{{'pregnancy.MedicalHistory.Comment' | translate}}</dt>
											<dd>{{print.Anamnesis?.SurgBackComment}}</dd>
										</td>
									</tr>
									<tr>
										<td class="td-med" *ngIf="print.Anamnesis?.Drugs !== -1">
											<dt class="dt-med" [ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{'pregnancy.MedicalHistory.Drugs.title' | translate}}</dt>
											<dd class="dd-med" [ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' ">{{print.Anamnesis?.Drugs ? (print.Anamnesis?.DrugsText ? print.Anamnesis?.DrugsText : 'pregnancy.MedicalHistory.Drugs.yes' | translate) :  'pregnancy.MedicalHistory.Drugs.no' | translate}}</dd>
										</td>
										<td *ngIf="print.Anamnesis?.Drugs !== -1 && print.Anamnesis?.DrugsComment && print.Anamnesis?.DrugsComment.length > 0 ">
											<dt>{{'pregnancy.MedicalHistory.Comment' | translate}}</dt>
											<dd>{{print.Anamnesis?.DrugsComment}}</dd>
										</td>
									</tr>
									<tr *ngIf="print.Anamnesis?.MedicalHistory">
										<td class="td-med" style="white-space: pre-wrap;">
											<strong>{{'pregnancy.MedicalHistory.note' | translate}}: </strong> {{print.Anamnesis?.MedicalHistory}}
										</td>
									</tr>


							  </table>
							  <div *ngIf="print.Anamnesis?.PhysicalExamination">
								
								<h4 class="text-20 text-gold"><strong>{{'pregnancy.Physical' | translate}}: </strong ></h4>
                              <p>{{print.Anamnesis?.PhysicalExamination}}</p>
							  </div>



                              
							  </div>
							 <!-- pediatric Anamnesis end-->
							<div class="section-text" style="width: 100%" *ngIf="print.pregnancy.Gravida || print.pregnancy.Para || print.pregnancy.Abortion || print.pregnancy.PreMedical >-1 || print.pregnancy.CVS >-1 || print.pregnancy.SurgBack >-1 || print.pregnancy.Drugs >-1|| print.pregnancy.Remarks">
								<h4 class="text-20 text-gold">{{'pregnancy.MedicalHistory.title' | translate}}</h4>
								<table>
									<tr>

										<td class="td-med"  *ngIf="(print.pregnancy.Gravida || print.pregnancy.Para || print.pregnancy.Abortion) && es.modality!='Ultrasound'">
											<dt class="dt-med"[ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{'pregnancy.MedicalHistory.ObstetricFormula' | translate}}</dt>
											<dd class="dd-med"[ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' " style="direction: ltr;">G({{print.pregnancy.Gravida}}) P({{print.pregnancy.Para}}) AB({{print.pregnancy.Abortion}})</dd>
										</td>
										<td class="td-med" *ngIf="(print.pregnancy.Gravida_US || print.pregnancy.Para_US || print.pregnancy.Abortion_US) && es.modality=='Ultrasound'">
											<dt class="dt-med"[ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{'pregnancy.MedicalHistory.ObstetricFormula' | translate}}</dt>
											<dd class="dd-med"[ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' " style="direction: ltr;">G({{print.pregnancy.Gravida_US}}) P({{print.pregnancy.Para_US}}) AB({{print.pregnancy.Abortion_US}})</dd>
										</td>
									</tr>
									<tr>
										<td class="td-med" *ngIf="print.pregnancy.PreMedical !== -1">
											<dt class="dt-med" [ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{'pregnancy.MedicalHistory.PreMedical.title' | translate}}</dt>
											<dd class="dd-med" [ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' ">{{print.pregnancy.PreMedical ? (print.pregnancy.PreMedicalText ? print.pregnancy.PreMedicalText : 'pregnancy.MedicalHistory.PreMedical.yes' | translate) :  'pregnancy.MedicalHistory.PreMedical.no' | translate}}</dd>
										</td>
										<td *ngIf="print.pregnancy.PreMedical !== -1 && print.pregnancy.PreMedicalComment && print.pregnancy.PreMedicalComment.length > 0">
											<dt>{{'pregnancy.MedicalHistory.Comment' | translate}}</dt>
											<dd>{{print.pregnancy.PreMedicalComment}}</dd>
										</td>
									</tr>
									<tr>
										<td class="td-med" *ngIf="print.pregnancy.CVS !== -1">
											<dt class="dt-med" [ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{(print.exam.ExamType ==='Fetal Echo' ? 'pregnancy.MedicalHistory.CVS.fetal' : 'pregnancy.MedicalHistory.CVS.title') | translate}}</dt>
											<dd class="dd-med" [ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' ">{{print.pregnancy.CVS ? (print.pregnancy.CVSText ? print.pregnancy.CVSText : 'pregnancy.MedicalHistory.CVS.yes' | translate) :  'pregnancy.MedicalHistory.CVS.no' | translate}}</dd>
										</td>
										<td *ngIf="print.pregnancy.CVS !== -1 && print.pregnancy.CVSComment && print.pregnancy.CVSComment.length > 0">
											<dt>{{'pregnancy.MedicalHistory.Comment' | translate}}</dt>
											<dd>{{print.pregnancy.CVSComment}}</dd>
										</td>
									</tr>
									<tr>
										<td class="td-med" *ngIf="print.pregnancy.SurgBack !== -1">
											<dt class="dt-med" [ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{'pregnancy.MedicalHistory.SurgBack.title' | translate}}</dt>
											<dd class="dd-med" [ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' ">{{print.pregnancy.SurgBack ? (print.pregnancy.SurgBackText ? print.pregnancy.SurgBackText : 'pregnancy.MedicalHistory.SurgBack.yes' | translate) :  'pregnancy.MedicalHistory.SurgBack.no' | translate}}</dd>
										</td>
										<td *ngIf="print.pregnancy.SurgBack !== -1 && print.pregnancy.SurgBackComment && print.pregnancy.SurgBackComment.length > 0">
											<dt>{{'pregnancy.MedicalHistory.Comment' | translate}}</dt>
											<dd>{{print.pregnancy.SurgBackComment}}</dd>
										</td>
									</tr>
									<tr>
										<td class="td-med" *ngIf="print.pregnancy.Drugs !== -1">
											<dt class="dt-med" [ngClass]="Direction === 'ltr' ? 'dt-ltr': 'dt-rtl' ">{{'pregnancy.MedicalHistory.Drugs.title' | translate}}</dt>
											<dd class="dd-med" [ngClass]="Direction === 'ltr' ? 'dd-ltr': 'dd-rtl' ">{{print.pregnancy.Drugs ? (print.pregnancy.DrugsText ? print.pregnancy.DrugsText : 'pregnancy.MedicalHistory.Drugs.yes' | translate) :  'pregnancy.MedicalHistory.Drugs.no' | translate}}</dd>
										</td>
										<td *ngIf="print.pregnancy.Drugs !== -1 && print.pregnancy.DrugsComment && print.pregnancy.DrugsComment.length > 0 ">
											<dt>{{'pregnancy.MedicalHistory.Comment' | translate}}</dt>
											<dd>{{print.pregnancy.DrugsComment}}</dd>
										</td>
									</tr>
									<tr *ngIf="print.pregnancy.Remarks">
										<td class="td-med" style="white-space: pre-wrap;">
											<strong>{{'pregnancy.MedicalHistory.note' | translate}}: </strong> {{print.pregnancy.Remarks}}
										</td>
									</tr>
									<!-- <tr *ngIf="print.pregnancy.Remarks">
										<td class="comment">{{print.pregnancy.Remarks}}</td>
									</tr> -->
								</table>
							</div>
							<div style="width: 100%;" *ngIf="print.pregnancy && print.exam.ExamType !== 'Weight Assessment' && print.exam.ExamType === 'Fetal Echo' && (print.indicationList ||print.summary || print.summary[0] || print.summary[0].AnomaliesIndication)" >
								<section class="section" *ngIf="print.pregnancy && print.exam.ExamType !== 'Weight Assessment' && print.exam.ExamType === 'Fetal Echo' && ((print.indicationList && print.indicationList.length >0) || print.summary[0].AnomaliesIndication)" style=" width: auto;">
									<p class="comment" *ngIf="print.indicationList && print.indicationList.length > 0 || print.summary[0].AnomaliesIndication"><strong>{{'pregnancy.indication' | translate}}: </strong ></p>
									<p *ngFor="let i of print.indicationList" style="text-align: left;">{{i[1].split(">")[i[1].split(">").length -1]}}</p>
									<hr *ngIf="print.summary[0] && print.indicationList?.length > 0 && print.exam.ExamType === 'Fetal Echo'">
									<div class="comment" *ngIf="print.summary[0].AnomaliesIndication && print.exam.ExamType === 'Fetal Echo'"><strong>{{'general.Comments' | translate}}: </strong>{{print.summary[0].AnomaliesIndication}}</div>
								</section>
							</div>
						</section>
					</div>
						<span *ngIf="print.exam.Emergency > -1 && print.exam.ExamType =='CVS'">
							<p class="comment"><strong>{{'pregnancy.Emergency.title' | translate}}:</strong> {{(print.exam.Emergency ? 'pregnancy.Emergency.Emergency' : 'pregnancy.Emergency.Elective') | translate}}</p>
						</span>
						<hr *ngIf="print.hasExamMethod() || (print['summary'] && print.summary[0]  && print.summary[0].ReferingDoc)">
						<table style="margin-top:1em" *ngIf="print.hasExamMethod() || (print['summary'] && print.summary[0]  && print.summary[0].ReferingDoc)"><tr>
							<td class="w-1/2" *ngIf="print.hasExamMethod()"><strong>{{'summary.indicative_way_of_examination.title' | translate}}: </strong>
								<ng-container *ngFor="let exammethod of ['Abdominal','Vaginal','Transperineal','Rectal']">
									<ng-container *ngIf="print.summary[0][exammethod]">{{'summary.indicative_way_of_examination.' + exammethod.toLowerCase() | translate}}<ng-container *ngIf="! print.lastExamMethod(exammethod)">, </ng-container>
									 </ng-container>
								</ng-container>
							</td>
							<td class="w-1/2" *ngIf="print.summary && print.summary[0] && print.summary[0].ReferingDoc"><strong>{{'general.Patient.referring_doctor' | translate}}:</strong> {{print.summary[0].ReferingDoc}}</td>
						</tr></table>
				</div>
			</section>
			<section class="section" *ngIf="print.exam && (print.exam.PurposeOfExam || print['GYNgeneral'])">
				<div class="section-text" *ngIf="print.isOBS || print.exam.ExamType == 'Follicles' ">
						<p class="comment"><strong>{{'pregnancy.Purpose_of_Exam' | translate}}:</strong> {{print.exam.PurposeOfExam}}</p>
				</div>
				<div *ngIf="! print.isOBS && print['GYNgeneral'] ">
					<div class="section-text" *ngIf="print.GYNgeneral.MedicalHistory && print.exam.ExamType !== 'Fetal Echo'">
						<p class="comment"><strong>{{'pregnancy.anamnesis' | translate}}: </strong><br>{{print.GYNgeneral.MedicalHistory}}</p>
					</div>
					<div class="section-text" style="display: inline-flex;" *ngIf="print.GYNgeneral.Gravida || print.GYNgeneral.Para || print.GYNgeneral.Abortion ">
						<strong style="margin-left: 0.5em;">{{'pregnancy.MedicalHistory.ObstetricFormula' | translate}}: </strong>
						<div style="padding-inline: 0.5em;direction: ltr;">
							<span>{{print.GYNgeneral.Gravida ? ' G('+print.GYNgeneral.Gravida+') ': ' G(0) ' }}</span>
							<span>{{print.GYNgeneral.Para ? ' P('+print.GYNgeneral.Para+') ': ' P(0) ' }}</span>
							<span>{{print.GYNgeneral.Abortion ? ' A('+print.GYNgeneral.Abortion+') ': ' A(0) ' }}</span>
						</div>
					</div>
					<div class="section-text" *ngIf="print.GYNgeneral.Purpose">
						<p class="comment"><strong>{{'pregnancy.Purpose_of_Exam' | translate}}:</strong><br> {{print.GYNgeneral.Purpose}}</p>
					</div>
					
					<table *ngIf="print.GYNgeneral.LMP"><tr>
						<td>{{'pregnancy.Last_Menstrual_Period.LMP' | translate}}: <strong>{{print.GYNgeneral.LMP | date:"dd.MM.yyyy"}}</strong></td>
						<td>{{'GyneIntro.Days_from_LMP' | translate}}: <strong>{{print.getDays(print.GYNgeneral.LMP)}}</strong></td>
					</tr></table>
				</div>
			</section>
			
			
			<section class="section" *ngIf="print.isOBS && print.exam.ExamType !='Follicles' && print.exam.ExamType!='Pediatric Echo'">
				<h4 class="text-20 text-gold">{{'pregnancy.title' | translate}}</h4>
				<table *ngIf="print.pregnancy">
					<tr>
					<td *ngIf="print.pregnancy.LMP && es.modality!=='Ultrasound'">
						<dt>{{'pregnancy.Last_Menstrual_Period.LMP' | translate}}</dt>
						<dd>{{print.pregnancy.LMP|date:"dd.MM.yyyy"}}</dd>
					</td>
					<td *ngIf="print.pregnancy.LMP_ULTRASOUND && es.modality==='Ultrasound'">
						<dt>{{'pregnancy.Last_Menstrual_Period.LMP' | translate}}</dt>
						<dd>{{print.pregnancy.LMP_ULTRASOUND|date:"dd.MM.yyyy"}}</dd>
					</td>
					<td *ngIf="print.pregnancy.LMP && es.modality!=='Ultrasound'">
						<dt>{{'measurements.titles.GA' | translate}}</dt>
						<dd>{{preg.lmpGA(print.pregnancy.LMP)}}</dd>
					</td>
					<td *ngIf="print.pregnancy.LMP_ULTRASOUND &&print.pregnancy.GA_LMP && es.modality=='Ultrasound'">
						<dt>{{'measurements.titles.GA' | translate}}</dt>
						<dd>{{print.pregnancy.GA_LMP | weeksDays}}</dd>
					</td>
					

					
					<td *ngIf="print.pregnancy.LMP_US && es.modality!=='Ultrasound'">
						<dt>{{'pregnancy.Last_Menstrual_Period.LMP_by_US' | translate}}</dt>
						<dd>{{preg.lmpUS(print.pregnancy.LMP_US,print.pregnancy.LMPUSweeks,print.pregnancy.LMPUSdays)}}</dd>
					</td>
					<td *ngIf="print.pregnancy.LMP_US && es.modality!=='Ultrasound'">
						<dt>{{'measurements.titles.GA' | translate}}</dt>
						<dd>{{print.pregnancy.lmpusedd}}</dd>
					</td>
					<td *ngIf="print.pregnancy.Ovulation && es.modality!=='Ultrasound'">
						<dt>{{'pregnancy.Last_Menstrual_Period.LMP_by_Ovulation' | translate}}</dt>
						<dd>{{preg.ovulationLMP(print.pregnancy.Ovulation)}}</dd>
					</td>
					<td *ngIf="print.pregnancy.Ovulation && es.modality!=='Ultrasound'">
						<dt>{{'measurements.titles.GA' | translate}}</dt>
						<dd>{{preg.ovulationGA(print.pregnancy.Ovulation)}}</dd>
					</td>
					<td>
						<dt>{{'pregnancy.Last_Menstrual_Period.EDD' | translate}}</dt>
						<dd *ngIf="es.modality!=='Ultrasound'">{{print.pregnancy.edd}}</dd>
						<dd *ngIf="print.pregnancy.EDD_US && es.modality=='Ultrasound'">{{print.pregnancy.EDD_US|date:"dd.MM.yyyy"}}</dd>
					</td>
				</tr>
				</table>
				<table *ngIf="print.pregnancy && print.exam.ExamType !== 'Pediatric Echo'"><tr>
					<td *ngIf="print.pregnancy.NumFetuses">
						<dt>{{'pregnancy.no_of_fetuses.title' | translate}}</dt>
						<dd>{{print.pregnancy.NumFetuses}} 
							<ng-container *ngIf="print.pregnancy.ChorioAmino">{{'pregnancy.Number_of_Fetuses.'+print.pregnancy.ChorioAmino.replace(' - ','_').replace(' ','') | translate }}</ng-container>
						</dd>
					</td>
					<td *ngIf="print.pregnancy.NumberOfSacs && print.exam.ExamType=='1st'">
						<dt>{{'pregnancy.Number_of_Fetuses.No_of_Gest_Sacs.title' | translate}}</dt>
						<dd>{{print.pregnancy.NumberOfSacs}}</dd>
					</td>
					<td *ngIf="print.pregnancy.NaturalPregnancy !== -1 ">
						<dt>{{'pregnancy.PregnancyType' | translate}}</dt>
						<dd>{{(print.pregnancy.NaturalPregnancy == 1? 'pregnancy.Pregnancy_Type.Natural': 'pregnancy.Pregnancy_Type.AFA.AFA') | translate}} 
							<ng-container *ngIf="print.pregnancy.NaturalPregnancy != 1 && print.pregnancy.IVFType">
								-
								<ng-container *ngFor="let it of print.pregnancy.IVFType.split('|');index as i">
									{{i>0?', ':''}}{{('pregnancy.Pregnancy_Type.AFA.' + it.replace(' ','_') | translate)}}
								</ng-container>
							</ng-container> </dd>
					</td>
	<!-- ========================== Gestation Sac Location (Start) ========================== -->
					<td *ngIf="print.pregnancy.Insitu !== -1" >
						<dt>
							{{'pregnancy.Gestational_Sac_Location.title'  | translate}}
						</dt>
						<dd *ngIf="print.pregnancy.Insitu === 1" >
							{{'pregnancy.Gestational_Sac_Location.In_Situ' | translate }}
						</dd>
						&nbsp;
						<dd *ngIf="print.pregnancy.Insitu === 0 && print.pregnancy.CervicalEctop " >
							{{'pregnancy.Gestational_Sac_Location.Ectopic.Cervical' | translate }}
						</dd>
						&nbsp;
						<dd *ngIf="print.pregnancy.Insitu === 0 && print.pregnancy.TubalEctop " >
							{{'pregnancy.Gestational_Sac_Location.Ectopic.Tubal' | translate }}
						</dd>
						&nbsp;
						<dd *ngIf="print.pregnancy.Insitu === 0 && print.pregnancy.OvarianEctop " >
							{{'pregnancy.Gestational_Sac_Location.Ectopic.Ovarian' | translate }}
						</dd>
						&nbsp;
						<dd *ngIf="print.pregnancy.Insitu === 0 && print.pregnancy.PeritonealEctop " >
							{{'pregnancy.Gestational_Sac_Location.Ectopic.Peritoneal' | translate }}
						</dd>
						&nbsp;
						<dd *ngIf="print.pregnancy.Insitu === 0 && print.pregnancy.AbdominalEctop " >
							{{'pregnancy.Gestational_Sac_Location.Ectopic.Abdominal' | translate }}
						</dd>
						&nbsp;
						<dd *ngIf="print.pregnancy.Insitu === 0 && 
						!print.pregnancy.CervicalEctop && 
						!print.pregnancy.TubalEctop && 
						!print.pregnancy.OvarianEctop &&
						!print.pregnancy.PeritonealEctop && 
						!print.pregnancy.AbdominalEctop">
							{{'pregnancy.Gestational_Sac_Location.Ectopic.Ectopic' | translate }}
						</dd>
					</td>
	<!-- ========================== Gestation Sac Location (End) ========================== -->
				</tr>
			</table>

			<!-- ========================== MFPR Management (Start) ========================== -->
			<section class="section"> 
				<table *ngIf="print.pregnancy.MFPR || print.pregnancy.MFPRNotes">
	 <td >
		<h6 class="text-20 text-gold">{{'pregnancy.MFPR.title' | translate}}</h6>
		<dt *ngIf="print.pregnancy.MFPR === 'Selective reduction'">
			{{ 'pregnancy.MFPR.selective_reduction' | translate}}
	 </dt>
	 <dd *ngIf="print.pregnancy.MFPR === 'Selective reduction'">
		 {{ print.pregnancy.MFPRfetus }}
	 </dd>
	 
	 <div class="section-text px-16" *ngIf="print.pregnancy.MFPRNotes">
		 <p class="comment">
			 <strong>{{'pregnancy.MFPR.method_of_reduction' | translate}}:</strong>
			 {{ print.pregnancy.MFPRNotes }}
		 </p>
	 </div>
 </td>
</table>
</section>
<!-- ========================== MFPR Management (End) ========================== -->

				<table *ngIf="print.pregnancy"><tr>
					<td *ngIf="print.pregnancy.BloodType">
						<dt>{{'pregnancy.BloodType' | translate}}</dt>
						<dd>{{print.pregnancy.BloodType}} </dd>
					</td>
					
				</tr></table>
				<div *ngIf="(preg.CervicalMeasures && preg.CervicalMeasures.length > 0) || print.pregnancy.cervicalComment">
						<dt>{{'pregnancy.Cervical_Measurments.cervix.title' | translate}}</dt>
					</div>
					<table class="symetric"  *ngIf="(preg.CervicalMeasures && preg.CervicalMeasures.length > 0) ">
						<tr *ngFor="let cerv of preg.CervicalMeasures">
							<td class="w-1/5"></td>
							<td class="w-1/5">{{'pregnancy.Cervical_Measurments.Lenght' | translate}}: {{cerv.Measure}}mm</td> 
							<td class="w-1/5" *ngIf="cerv.Funneling==-1"></td>
							<td class="w-1/5" *ngIf="cerv.Funneling>-1">{{'pregnancy.Cervical_Measurments.Funneling'| translate}}: {{'general.PositiveNegative.' + (cerv.Funneling==1? 'yes':'No') | translate}}</td>
							<td class="w-1/5">{{'measurements.titles.GA'| translate}}: {{preg.getGA(cerv.MeasureDate)}}</td>
							<td class="w-1/5">{{'pregnancy.Cervical_Measurments.Date' | translate}}: {{cerv.MeasureDate | date:"dd.MM.yyyy"}}</td>
						</tr>
					</table>
					<section class="section" *ngIf="print.pregnancy.cervicalComment">
						{{'general.Comments' | translate}}: {{print.pregnancy.cervicalComment}}
					</section>
			</section>
			
			<ng-container *ngIf="print.isOBS && print.pregnancy && print.fetusList">
				<section class="section"  *ngFor="let fetus of print.fetusList" [ngClass]="print.pregnancy.NumFetuses > 1 ? 'section-fetus':''" >
					<h4 class="section-title text-20" *ngIf="print.pregnancy.NumFetuses > 1" >{{('general.fetuses.fetus_1.title' | translate).replace("1","")}}{{fetus+1}}</h4>
					<div class="section-inner" *ngIf="print.placenta && print.placenta[fetus] && (print.placenta[fetus].SacLoc || print.placenta[fetus].LieAndPresent || print.placenta[fetus].AmniFluidVolume || print.placenta[fetus].Comments || print.placenta[fetus].Movements || print.placenta[fetus].Heartbeat || print.placenta[fetus].Heartrate || print.placenta[fetus].BloodVessels || print.placenta[fetus].Grading || print.placenta[fetus].AmniFluidVolume || print.placenta[fetus].SacApear || print.placenta[fetus].ScoreAFI)" style="display:block">
						<p class="section-subtitle" *ngIf="print.placenta && print.placenta[fetus]">{{'placentaetc.title' | translate}}</p>
						<table class="formatted" *ngIf="print.placenta[fetus].SacLoc || print.placenta[fetus].BloodVessels || print.placenta[fetus].Grading">
							<tr>
							<td class="formatted-title" *ngIf="print.placenta[fetus].SacLoc" style='display: inline-block;'>{{'placentaetc.Placenta_Position.title'| translate}}  </td>
							<td *ngIf="print.placenta[fetus].SacLoc">
								<dd>
								<ng-container *ngFor="let loc of print.placenta[fetus].SacLocs">
									{{'placentaetc.Placenta_Position.'+ loc | translate}} 
								</ng-container>
								</dd>
							</td>
							<td *ngIf="print.placenta[fetus].BloodVessels"><dt>{{'placentaetc.Placenta_Position.#ofbloodvessels' | translate}}</dt> <dd>{{print.placenta[fetus].BloodVessels}}</dd></td>
							<td *ngIf="print.placenta[fetus].Grading"><dt>{{'placentaetc.Placenta_Position.PlacentalGrading' | translate}}</dt> <dd>{{print.placenta[fetus].Grading}}</dd></td>
							<td></td>
						</tr></table>
						
						<table class="formatted" *ngIf="print.placenta[fetus].LieAndPresent || print.placenta[fetus].SacApear || print.placenta[fetus].Position || print.placenta[fetus].PositionVertical">
							<tr>
								<td class="formatted-title" style='display: inline-block;'>{{'placentaetc.Lie_and_Presentation.title' | translate}}</td>
								<td *ngIf="print.placenta[fetus].LieAndPresent"><dd>{{'placentaetc.Lie_and_Presentation.' + print.placenta[fetus].LieAndPresent | translate}}</dd></td>
								<td *ngIf="print.placenta[fetus].Position || print.placenta[fetus].PositionVertical "><dt>{{'placentaetc.Lie_and_Presentation.fetal_position.title' | translate}}</dt> <dd><span style="margin-inline-end: 10px;" *ngIf="print.placenta[fetus].Position">{{'placentaetc.Placenta_Position.' + print.placenta[fetus].Position | translate}}</span> <span *ngIf="print.placenta[fetus].PositionVertical">{{'placentaetc.Placenta_Position.' + print.placenta[fetus].PositionVertical | translate}}</span></dd></td>
								<td *ngIf="print.placenta[fetus].SacApear"><dt>{{'placentaetc.AFV.GestationalSacAppearance.title' | translate}}</dt> <dd>{{'placentaetc.AFV.GestationalSacAppearance.' + print.placenta[fetus].SacApear + 'Value' | translate}}</dd></td>
								<td></td>
							</tr>
						</table>
						
						<table class="formatted" *ngIf="print.placenta[fetus].AmniFluidVolume || print.placenta[fetus].AmniFluidIndex || print.placenta[fetus].MaxPocket">
							<tr>
								<td class="formatted-title" *ngIf="print.placenta[fetus].AmniFluidVolume" style='display: inline-block;'>{{'placentaetc.BioPhysicalScore.AmnioticFluid' | translate}}</td>
								<td><dd>{{'placentaetc.AFV.'+print.placenta[fetus].AmniFluidVolume | translate}}</dd></td>
							<td *ngIf="print.placenta[fetus].AmniFluidIndex"><dt>{{'placentaetc.AFV.AFI' | translate}}</dt> <dd>{{print.placenta[fetus].AmniFluidIndex}}mm</dd></td>
							<td *ngIf="print.placenta[fetus].MaxPocket"><dt>{{'placentaetc.AFV.MaxPocketSize' | translate}}</dt> <dd>{{print.placenta[fetus].MaxPocket}}mm</dd></td>
							</tr>
						</table>
						<div *ngIf="print.placenta && print.placenta[fetus] && hasScore(print.placenta[fetus])" class="formatted">
							<div class="formatted-title">{{'placentaetc.BioPhysicalScore.title' | translate}}:</div>
							<table  *ngIf="print.placenta && print.placenta[fetus] && hasScore(print.placenta[fetus])">
								<tr *ngFor="let score of scores">
									<td></td>
									<ng-container *ngIf="print.placenta[fetus]['Score'+score[0]] > -1">
										<td style="width:8em">{{'placentaetc.BioPhysicalScore.'+score[1].replace(' ','') | translate }}</td>
										<td style="width:2em">{{print.placenta[fetus]['Score'+score[0]] == 2 ? '2' :''}}</td>
										<td style="width:2em">{{print.placenta[fetus]['Score'+score[0]] == 0 ? '0' :''}}</td>
										<td style="width:2em"><strong>{{print.placenta[fetus]['Score'+score[0]] < 0 ? '0':print.placenta[fetus]['Score'+score[0]] }}/{{print.placenta[fetus]['Score'+score[0]] < 0 ? '0':'2'}}</strong></td>
										
									</ng-container>
								</tr>
								<tr style="margin-bottom: 8px;">
									<td></td>
									<td style="width:8em">{{'placentaetc.BioPhysicalScore.Score' | translate}}</td>
									<td style="width:2em"><strong></strong></td>
									<td style="width:2em"><strong></strong></td>
									<td style="width:2em"><strong>{{print.getScore(fetus)}}</strong></td>
								</tr>
							</table>
						</div>
						<table class="items-center" class="formatted" *ngIf="print.placenta[fetus].Movements || print.placenta[fetus].Heartbeat || print.placenta[fetus].Heartrate"><tr>
							<td class="ps-16" *ngIf="print.placenta[fetus].Movements"><dt>{{'placentaetc.BioPhysicalScore.FetalMovements' | translate}}</dt> <dd>{{'general.PositiveNegative.yes' | translate}}</dd></td>
							<td class="pe-16" *ngIf="print.placenta[fetus].Heartbeat && !print.placenta[fetus].Heartrate"><dt>{{'placentaetc.BioPhysicalScore.HeartBeat' | translate }}</dt> <strong>{{'general.PositiveNegative.yes' | translate}}</strong></td>
							<td class="pe-16" *ngIf="print.placenta[fetus].Heartrate && print.placenta[fetus].Heartbeat == 1"><dt>{{'systems.chest.fetal_heart_rate.title' | translate }}</dt> <dd>{{print.placenta[fetus].Heartrate}} {{'systems.chest.fetal_heart_rate.units.title' | translate}}</dd></td>
						</tr></table>
					
						<div class="section-text px-16" *ngIf="print.placenta[fetus].Comments">
							<p class="comment"><strong>{{'general.Comment' | translate}}:</strong> {{print.placenta[fetus].Comments}}</p>
						</div>
						
					</div>
					<!--nt first-->
					
					
					
					<table class="section-inner" *ngIf="print.nt &&  print.nt[fetus] &&(print.nt[fetus].YolkSac > -1 || print.nt[fetus].NasalBone > -1 || print.nt[fetus].Heart > -1 || print.nt[fetus].FHR || print.nt[fetus].Comments)">
						<tr><td class="section-subtitle">{{'nt.biophysical_indices.title' | translate}}</td></tr>
						<tr>
							<td *ngIf="print.nt[fetus].YolkSac > -1"><dt>{{'nt.biophysical_indices.yolk_sac' | translate}}</dt> <dd>{{'general.PositiveNegative.' + (print.nt[fetus].YolkSac == 0 ? 'yes': 'No') | translate}}</dd></td>
							<td *ngIf="print.nt[fetus].NasalBone > -1"><dt>{{'nt.biophysical_indices.nasal_bone' | translate}}</dt> <dd>{{'general.PositiveNegative.' + (print.nt[fetus].NasalBone == 0 ? 'yes': 'No') | translate}}</dd></td>
							<td *ngIf="print.nt[fetus].Heart > -1"><dt>{{'nt.biophysical_indices.heart_beat' | translate}}</dt> <dd>{{'general.PositiveNegative.' + (print.nt[fetus].Heart == 0 ? 'yes': 'No') | translate}}</dd></td>
							<td *ngIf="print.nt[fetus].FHR"><dt>{{'systems.chest.fetal_heart_rate.title' | translate}}</dt> <dd>{{print.nt[fetus].FHR}}{{'systems.chest.fetal_heart_rate.units.title' | translate}}</dd> </td>
						</tr>
						<tr *ngIf="print.nt && print.nt[fetus] && print.nt[fetus].Comments">
							<td class="comment"><strong>{{'general.Comment' | translate}}:</strong> {{print.nt[fetus].Comments}}</td>
						</tr>
					</table>
					<!--measurements-->
					<div class="section-subtitle" *ngIf="print.hasMeas[fetus] && print.printAllMeas" style="height: 60pt; margin-bottom: -38pt;page-break-inside: avoid;">{{'measurements.titles.Measurements' | translate}}
						<p *ngIf="es.modality==='Ultrasound'" style="display: inline-block"><strong>{{'general.us_note' | translate}} </strong></p>
						<p *ngIf="es.modality==='Computing'" style="display: inline-block"><strong>{{'general.computing_note' | translate}} </strong></p>
					</div>
					<table class="formatted" style="direction: ltr;" *ngIf="print.hasMeas[fetus] && print.printAllMeas" >
						<thead class="measurements">
						<tr *ngIf="!print.isFetalEcho">
							<td class="text-14 text-lightblue" style="width: 20%;">{{'measurements.titles.Measurements' | translate}}</td>
							<td class="text-14 text-lightblue" style="width: 10%;">mm</td>
							<td class="text-14 text-lightblue" style="width: 20%;" >{{'measurements.titles.GA' | translate}}</td>							
							<!-- <td class="text-14 text-lightblue" style="width: 20%;" *ngIf='print.showPercentiles'>{{'measurements.titles.GG' | translate}}</td> -->
							<td class="text-14 text-lightblue" style="width: 20%;" *ngIf=" print.pregnancy.LMP || print.pregnancy.LMP_ULTRASOUND">{{'measurements.titles.GG' | translate}}</td>
							<td class="text-14 text-lightblue" style="width: 18%;padding-left: 0;" *ngIf=' print.showPercentiles'>{{'measurements.titles.Range' | translate}}</td>
						</tr>
						</thead>
						<tbody class="measurements">
					<ng-container *ngIf="print.exam.ExamType !== 'Pediatric Echo'">	
						<tr><app-printmeasure MyMeasure="GS"  [fetus]="fetus" *ngIf="print.es.addNT || print.es.switchNT || print.exam.ExamType == 'NT' || print.exam.ExamType == '1st'"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="NT" [fetus]="fetus" *ngIf="print.es.addNT || print.es.switchNT || print.exam.ExamType == 'NT' || print.exam.ExamType == '1st'"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="BPD" [modality]="es.modality"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="HC" [modality]="es.modality"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="AC" [modality]="es.modality"  [fetus]="fetus" ></app-printmeasure></tr>  
						<tr><app-printmeasure MyMeasure="FL" [modality]="es.modality"  [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="EFW 1" [modality]="es.modality"  [fetus]="fetus" ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="EFW 2"  [fetus]="fetus" *ngIf="false"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="HCAC" [modality]="es.modality"   [fetus]="fetus" *ngIf="print.exam.ExamType !== 'Weight Assessment'"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="VoPl"  [modality]="es.modality" [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="VoPr" [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="CEREB"  [modality]="es.modality"   [fetus]="fetus" ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="CM"  [modality]="es.modality" [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="OFD" [modality]="es.modality"  [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="OOD" [modality]="es.modality"  [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="IOD" [modality]="es.modality"  [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="OD" [modality]="es.modality"  [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="EarLen" [modality]="es.modality"  [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="Nuchal"  [modality]="es.modality" [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="TC" [modality]="es.modality"   [fetus]="fetus" ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="CL" [modality]="es.modality"   [fetus]="fetus" ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="HL" [modality]="es.modality"   [fetus]="fetus" ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="RADIUS" [modality]="es.modality"   [fetus]="fetus" ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="UL" [modality]="es.modality"  [fetus]="fetus"  ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="TL"  [modality]="es.modality"  [fetus]="fetus" ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="FIBULA"  [modality]="es.modality"  [modality]="es.modality"  [fetus]="fetus" ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="Foot"  [modality]="es.modality"  [fetus]="fetus" ></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="CRL" [fetus]="fetus" *ngIf="(print.es.addNT || print.es.switchNT || print.exam.ExamType == 'NT' || print.exam.ExamType == '1st') || (print.exam.ExamType == 'CVS' || print.exam.ExamType== 'Amniocentesis')"></app-printmeasure></tr>
						<tr><app-printmeasure [modality]="es.modality" MyMeasure="AUA" [fetus]="fetus" ></app-printmeasure></tr>
					</ng-container>	
						<tr><app-printmeasure MyMeasure="TRC"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="MIT"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="PulmonaryV"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="AOR"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="PULFlow"  [fetus]="fetus" inCm="true"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="MPASYS"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="RPA"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="LPA"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="AOFlow"  [fetus]="fetus" inCm="true"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="ASCAO"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="TrasArch"  [fetus]="fetus"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="ISTHMUS"  [fetus]="fetus" inMm="true"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="IFI"  [fetus]="fetus" inCm="true"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="DASYSFlow"  [fetus]="fetus" inMs="true"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="DADIASTFLOW"  [fetus]="fetus" inMs="true"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="PEEFF"  [fetus]="fetus" minmax="true" *ngIf="es.ExamType =='Fetal Echo' || es.ExamType=='Pediatric Echo'"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="Coronary"  [fetus]="fetus" inMm="true"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="DuctusVenosus"  [fetus]="fetus" doppler="true"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="AWave"  [fetus]="fetus" doppler="true"></app-printmeasure></tr>
						<tr><app-printmeasure MyMeasure="PI"  [fetus]="fetus" doppler="true"></app-printmeasure></tr>



						<!-- <tr [ngClass]="Direction ==='rtl' ? 'rtlBox': ''"> -->
						<tr [ngClass]="Direction" *ngIf="print.measCache[fetus]['Comment']">
							<p class="comment" [ngClass]="Direction =='rtl' ? 'rtl': 'ltr'"><strong>{{'general.Comment' | translate}}: </strong>
								 {{print.measCache[fetus]['Comment']}}</p>
						<td colspan="5" class="section-text px-16">
						</td>
						</tr>
					</tbody>
					</table>
					<app-weight-assessment [print]="true" [direction]="Direction" [fetus]="fetus + 1" *ngIf="print.exam.ExamType == 'Weight Assessment' || print.exam.ExamType == 'Tech Exam'"></app-weight-assessment>

					
					<div class="section-subtitle" *ngIf="showCharts && print.chartData && print.chartData[fetus]">{{'measurements.titles.Charts' | translate}}</div>
					<div class="px-8" *ngIf="showCharts && print.chartData && print.chartData[fetus]" style='display:block'>
						<ng-container *ngFor="let m of keys">
							<div class="p-8 chart-container" *ngIf="print.chartData[fetus][m]">	
								<div class="chart">
									<canvas baseChart [datasets]="print.chartData[fetus][m]"
													[options]="m.indexOf('EFW') > -1 ? weightChartOptions:lineChartOptions"
													[legend]="false"
													[chartType]="'scatter'"
													width="170" height="170"
												></canvas>
									<div class="chart-title" style='direction: ltr;'>{{getName(m)}} <ng-container *ngIf="m.indexOf('EFW')==-1">({{print.measCache[fetus][m].GGfig}})</ng-container></div>
								</div>
							</div>
						</ng-container>			
					</div>
					<!--Invasive exam-->

					<table class="formatted" *ngIf="(print.invasive && print.invasive[fetus]) || (print.Medication && print.Medication[0])">
						<tr>
							<td class="section-subtitle" style='display: inline-block;'>{{'invasive.title' | translate}}</td>
						</tr>
						<tr *ngIf="print.invasive[fetus].Needle">
							<td class="w-1/2" *ngIf="print.hasExamMethod()"><strong>{{'summary.indicative_way_of_examination.title' | translate}}: </strong>
								<ng-container *ngFor="let exammethod of ['Abdominal','Vaginal','Transperineal','Rectal']">
									<ng-container *ngIf="print.summary[0][exammethod]">{{'summary.indicative_way_of_examination.' + exammethod.toLowerCase() | translate}}<ng-container *ngIf="! print.lastExamMethod(exammethod)">, </ng-container>
									 </ng-container>
								</ng-container>
							</td>
						</tr>
						<tr *ngIf="print.invasive[fetus].USGiudance == 2 ">
							<td >
								<strong>{{'invasive.USGiudance' | translate}}</strong>
							</td>
						</tr>
						<tr *ngIf="print.invasive[fetus].Needle || print.invasive[fetus].Tries">
							<td *ngIf="print.invasive[fetus].Needle">
								<dt>{{'invasive.Needle' | translate}}</dt>
								<dd style="direction: ltr;">{{print.invasive[fetus].Needle}} fr</dd>
							</td>
							<td *ngIf="print.invasive[fetus].Tries">
								<dt>{{'invasive.Tries' | translate}}</dt>
								<dd>{{print.invasive[fetus].Tries}}</dd>
							</td>
						</tr>
						<tr *ngIf="print.invasive[fetus].FluidSample > -1 ">
							<td *ngIf="print.invasive[fetus].FluidSample && print.exam.ExamType == 'CVS'">
								<dt>{{'invasive.ViliSampled' | translate}}</dt>
								<dd>{{print.invasive[fetus].FluidSample| translate}}cc</dd>
							</td>
						</tr>
						<tr *ngIf="print.exam.ExamType == 'Amniocentesis' && (print.invasive[fetus].FluidColor || print.invasive[fetus].Type || print.invasive[fetus].FluidSample)">
							<td *ngIf="print.invasive[fetus].FluidSample">
								<dt>{{'invasive.FluidSample' | translate}}</dt>
								<dd>{{print.invasive[fetus].FluidSample| translate}}ml</dd>
							</td>
							<td *ngIf="print.invasive[fetus].Type">
								<dt>{{'invasive.Type' | translate}}</dt>
								<dd>{{print.invasive[fetus].Type| translate}}</dd>
							</td>
							<td *ngIf="print.invasive[fetus].FluidColor">
								<dt>{{'invasive.FluidColor' | translate}}</dt>
								<dd>{{print.invasive[fetus].FluidColor| translate}}</dd>
							</td>
						</tr>
						 <tr *ngIf="print.Medication && print.Medication[0]">
							<td style="width: 100%;">
								<table>
									<tr><td class="formatted-title">{{'invasive.Medication' | translate}}:</td></tr>
									<tr *ngFor="let med of print.Medication[0]; index as i">
										<td class="l-td-m" style="padding-left: 5px;font-weight: 700;">{{i+1}})</td>
										<td class="l-td-m" style="padding-left: 5px">{{med.Medication}}</td>
										<td class="l-td-m" style="padding-left: 0">{{med.Dose}}{{med.Unit}}</td>
									</tr>
								</table>
							</td>
						 </tr>
						 <tr *ngIf="print.invasive[fetus].preformedby">
							<td>
								<dt>{{ 'invasive.preformedby' | translate}}</dt>
								<dd>{{print.invasive[fetus].preformedby}}</dd>
							</td>
						 </tr>
					</table>



					<!--Invasive exam-->

					

					<!--nt risk-->
					
					
					<table *ngIf="(print.es.addNT || print.es.switchNT || print.exam.ExamType == 'NT') && print.nt && print.nt[fetus] " class="formatted">
						<tr><td class="section-subtitle">{{'nt.fetus_measurements.risks.title' | translate}}</td></tr>
						<tr>
							<table class="formatted" *ngIf="(print.es.addNT || print.es.switchNT || print.exam.ExamType == 'NT') && print.nt && print.nt[0]">
								<tr><td *ngIf="print.nt[0].PrintConception"><dt>{{'pregnancy.Pregnancy_Type.title' | translate}}</dt>
									<dd>{{'nt.way_of_conception.'+ (print.nt[0].Conception ? print.nt[0].Conception.toLowerCase().replace(" ","_").replace("normal","natural") : 'natural') | translate }}</dd></td>
								<td *ngIf="print.nt[0].Conception == 'Egg Donation' && print.nt[0].OtherDonorBD">
									<dt>{{'nt.way_of_conception.donors_birthdate.title' | translate}}</dt> <dd>{{print.nt[0].OtherDonorBD | date:"dd.MM.yyyy"}}</dd>
								</td>
								<td  *ngIf="(print.nt[0].Conception == 'Egg Donation' || print.nt[0].Conception =='Egg Freezing')  && print.nt[0].EggDonationDate">
									<dt>{{'nt.way_of_conception.date_of_' + print.nt[0].Conception.toLowerCase().replace(" ","_")+'.title' | translate }}</dt> <dd>{{print.nt[0].EggDonationDate | date:"dd.MM.yyyy"}}</dd>
								</td>
								<td>
									<dt>{{'nt.way_of_conception.age_at_delivery_by_donation' | translate}}</dt> <dd>{{nt.getAge() ? nt.getAge() : 'לא ניתן לחישוב' }}</dd>
								</td>
								</tr>
							</table>
						</tr>
						<tr>
							<td *ngIf="print.nt[fetus]"> <!-- && nt.calcRiskAge(nt.getAge())-->
								<dt>{{'nt.fetus_measurements.risk_by_age.title' | translate}}</dt>
								<dd *ngIf="nt.calcRiskAge(nt.getAge())">1:{{nt.calcRiskAge(nt.getAge())}}</dd>
								<dd *ngIf="!nt.calcRiskAge(nt.getAge())">לא ניתן לחישוב</dd>

							</td>
							<td  *ngIf="print.nt[fetus] "> <!--&& print.nt[fetus].CRL && print.nt[fetus].NT && nt.calcRiskCRL(nt.calcRiskAge(nt.getAge()),print.nt[fetus].CRL,print.nt[fetus].NT)-->
								<dt>{{'nt.fetus_measurements.risk_by_age_and_measurements.title' | translate}}</dt>
								<dd *ngIf="nt.calcRiskCRL(nt.calcRiskAge(nt.getAge()),print.nt[fetus].CRL,print.nt[fetus].NT)">1:{{nt.calcRiskCRL(nt.calcRiskAge(nt.getAge()),print.nt[fetus].CRL,print.nt[fetus].NT)}}</dd>
								<dd *ngIf="!nt.calcRiskCRL(nt.calcRiskAge(nt.getAge()),print.nt[fetus].CRL,print.nt[fetus].NT)">לא ניתן לחישוב</dd>

								<!-- <strong>{{'nt.fetus_measurements.risk_by_age_and_measurements.title' | translate}}: </strong>1:{{nt.calcRiskCRL(nt.calcRiskAge(nt.getAge()),print.nt[fetus].CRL,print.nt[fetus].NT)}} -->
							</td>
						</tr>
						<tr *ngIf="print.nt[fetus] && (! nt.calcRiskAge(nt.getAge())  || (! print.nt[fetus].CRL || ! print.nt[fetus].NT) || ! nt.calcRiskCRL(nt.calcRiskAge(nt.getAge()),print.nt[fetus].CRL,print.nt[fetus].NT))">
							<section class="section" style="display: grid;" *ngIf="! es.patServ.ageRange(nt.getAge()) || (! print.nt[fetus].CRL || ! print.nt[fetus].NT) || ! nt.inRange() || (nt.inRange() && ! nt.calcRiskCRL(nt.calcRiskAge(nt.getAge()),print.nt[fetus].CRL,print.nt[fetus].NT))">
								<span><strong>{{'general.Comments' | translate}}</strong></span>
								<span *ngIf="! nt.calcRiskAge(nt.getAge()) && print.nt[fetus].Conception =='Natural'">סיכון לפי גיל האישה בלידה: לא חושב כי לא הוזן תאריך הלידה  או  LMP .</span>
								<span *ngIf="! nt.calcRiskAge(nt.getAge()) && print.nt[fetus].Conception =='Egg Freezing'">סיכון לפי גיל האישה בלידה: לא חושב כי לא הוזן תאריך הלידה  או תאריך ההקפאה.</span>
								<span *ngIf="! nt.calcRiskAge(nt.getAge()) && print.nt[fetus].Conception =='Egg Donation'">סיכון לפי גיל האישה בלידה: לא חושב כי לא הוזן תאריך הלידה  או תאריך תרומה.</span>

								<span *ngIf="! nt.calcRiskCRL(nt.calcRiskAge(nt.getAge()),print.nt[fetus].CRL,print.nt[fetus].NT) && nt.getAge()"> סיכון משוקלל לפי גיל האישה בלידה ומדידות CRL + NT: לא חושב כי לפחות אחת המדידות מחוץ לתחום</span>
								<span *ngIf="! nt.inRange() && nt.getAge()">לא חושב כי כי גיל ההריון לסיכון מחוץ לתחום: {{!nt.getAgeOver() ? 'גיל הריון לחישוב הסיכון קטן מ20!' : 'גיל הריון לחישוב הסיכון גדול מ45 ו11 חודשים!'}}</span>

							</section>
						</tr>
					</table>
					

					<div class="section-inner" style="display: block;" *ngIf="(print.cns && print.cns[fetus]) || (print.face && print.face[fetus]) || (print.chest && print.chest[fetus]) || (print.digest && print.digest[fetus]) || (print.urology && print.urology[fetus]) || (print.skeleton && print.skeleton[fetus]) || (print.chestFetal && print.chestFetal[fetus])">
						<div class="mb-8 section-subtitle" style="height: 55pt; margin-bottom: -33pt;" *ngIf="!print.isFetalEcho">{{'systems.Category_Names.title' | translate}}</div>
						<div class="mb-8 section-subtitle" style="height: 55pt; margin-bottom: -33pt;" *ngIf="(print.exam.ExamType === 'Fetal Echo'|| print.exam.ExamType=='Pediatric Echo') && print.chestFetal[fetus]">{{'systems.Category_Names.chestFetal' | translate}}</div>
						<app-systemprint System="cns" name="CNS" [fetus]="fetus" [dir]="Direction" *ngIf="print.cns && print.cns[fetus]"></app-systemprint>
						<app-systemprint System="face" name="Face and Neck" [fetus]="fetus" [dir]="Direction" *ngIf="print.face && print.face[fetus]"></app-systemprint>
						<app-systemprint System="chest" name="Cardio and Chest" [fetus]="fetus" [dir]="Direction" *ngIf="print.chest && print.chest[fetus]"></app-systemprint>
						<app-systemprint System="digest" name="GIT" [fetus]="fetus" [dir]="Direction" *ngIf="print.digest && print.digest[fetus]"></app-systemprint>
						<app-systemprint System="urology" name="GUT" [fetus]="fetus" [dir]="Direction" *ngIf="print.urology && print.urology[fetus]" ></app-systemprint>
						<app-systemprint System="skeleton" name="Skeleton" [fetus]="fetus" [dir]="Direction" *ngIf="print.skeleton && print.skeleton[fetus]"></app-systemprint>
						<app-systemprint System="chestFetal" name="chestFetal" [fetus]="fetus" [dir]="Direction" *ngIf="print.chestFetal && print.chestFetal[fetus]"></app-systemprint>

						<table class="justify-end" *ngIf="(print.cns && print.cns[fetus]) || (print.face && print.face[fetus]) || (print.chest && print.chest[fetus]) || (print.digest && print.digest[fetus]) || (print.urology && print.urology[fetus]) || (print.skeleton && print.skeleton[fetus])">
							<tr>
								<td class="me-16 unseen">{{'general.seen_unseen.unseen' | translate}}</td>
								<td class="me-16 seen">{{'general.seen_unseen.seen' | translate}}</td>
								<td class="comments">{{'general.Comment' | translate}}</td>
							</tr>
						</table>
					</div>

					<div *ngIf="print['doppler'] && print.doppler[fetus] && (print.doppler[fetus]['MPI'] || print.doppler[fetus]['MRI'] || print.doppler[fetus]['MSD'] || print.doppler[fetus]['PSV'] || print.doppler[fetus]['UPI'] || print.doppler[fetus]['URI'] || print.doppler[fetus]['USD'] || print.doppler[fetus]['LAPI'] || print.doppler[fetus]['LARI'] || print.doppler[fetus]['LASD'] || print.doppler[fetus]['RAPI'] || print.doppler[fetus]['RARI'] || print.doppler[fetus]['RASD'] || print.doppler[fetus]['Ductus'] || print.doppler[fetus]['Ductus'] === 0 || print.doppler[fetus]['Awave'] || print.doppler[fetus]['Awave'] === 0 || print.doppler[fetus]['DuctosComments'] || print.doppler[fetus]['Comments'])" class="section-inner" style="display: block">
						<div class="mb-8 px-8 section-subtitle" >{{'doppler.obs_doppler.title' | translate}}</div>
						<table *ngIf="print['doppler'] && print.doppler[fetus]" class="doppler"><tr>
							<td class="w-1/2 px-8 py-6" style="width: 50%;vertical-align: top;" *ngIf="print.doppler[fetus]['MPI'] || print.doppler[fetus]['MRI'] || print.doppler[fetus]['MSD'] || print.doppler[fetus]['PSV']">
								
								<table class="formatted">
									<tr><td class="formatted-title">{{'doppler.obs_doppler.MCA.title' | translate}}</td></tr>
									<tr><app-dopplerprint Name="PI" key="MPI" [fetus]="fetus" *ngIf="print.doppler[fetus]['MPI']"></app-dopplerprint></tr>
									<tr><app-dopplerprint Name="RI" key="MRI" [fetus]="fetus" *ngIf="print.doppler[fetus]['MRI']"></app-dopplerprint></tr>
									<tr><app-dopplerprint Name="PSV" key="PSV" [fetus]="fetus" *ngIf="print.doppler[fetus]['PSV']"></app-dopplerprint></tr>
									<tr><app-dopplerprint Name="SD" key="MSD" [fetus]="fetus" *ngIf="print.doppler[fetus]['MSD']"></app-dopplerprint></tr>
								</table>
								<div *ngIf="print.doppler[fetus]['AnemicIndic']"><strong>{{'doppler.obs_doppler.MCA.anemic_indication.title' | translate}}: </strong>{{'doppler.obs_doppler.MCA.anemic_indication.' + print.doppler[fetus]['AnemicIndic'].toLowerCase() | translate}}</div>
								
							</td>
							<td class="w-1/2 px-8 py-6" style="width: 50%; vertical-align: top;" *ngIf="print.doppler[fetus]['UPI'] || print.doppler[fetus]['URI'] || print.doppler[fetus]['USD']" >
								<div class="inner-wrapper p-16">
									
									<table class="formatted">
										<tr><td class="formatted-title">{{'doppler.obs_doppler.UmA.title' | translate}}</td></tr>
										<tr><app-dopplerprint Name="PI" key="UPI" [fetus]="fetus" *ngIf="print.doppler[fetus]['UPI']"></app-dopplerprint></tr>
										<tr><app-dopplerprint Name="RI" key="URI" [fetus]="fetus" *ngIf="print.doppler[fetus]['URI']"></app-dopplerprint></tr>
										<tr><td>&nbsp;</td></tr>
										<tr><app-dopplerprint Name="SD" key="USD" [fetus]="fetus" *ngIf="print.doppler[fetus]['USD']"></app-dopplerprint></tr>
									</table>
								</div>
							</td>
						</tr></table>
						
							
						<table *ngIf="print['doppler'] && print.doppler[fetus] && print.doppler[fetus]['CPR']" class="formatted"><tr><app-dopplerprint Name="CPR" key="CPR" [fetus]="fetus"></app-dopplerprint></tr></table>
							
						
						<table *ngIf="print['doppler'] && print.doppler[fetus]" class="doppler"><tr>
							<td class="w-1/2 px-8 py-6" style='vertical-align: top;' *ngIf="print.doppler[fetus]['LAPI'] || print.doppler[fetus]['LARI'] || print.doppler[fetus]['LASD']">
								<div class="inner-wrapper p-16">
									
									<table class="formatted">
										<tr><td class="formatted-title">{{'doppler.obs_doppler.UtA_Left.title' | translate}}</td></tr>
										<tr><app-dopplerprint Name="PI" key="LAPI" [fetus]="fetus"></app-dopplerprint></tr>
										<tr><app-dopplerprint Name="RI" key="LARI" [fetus]="fetus"></app-dopplerprint></tr>
										<tr><app-dopplerprint Name="SD" key="LASD" [fetus]="fetus"></app-dopplerprint></tr>
									</table>
								</div>
							</td>
							<td class="w-1/2 px-8 py-6" style='vertical-align: top;' *ngIf="print.doppler[fetus]['RAPI'] || print.doppler[fetus]['RARI'] || print.doppler[fetus]['RASD']">
								<div class="inner-wrapper p-16">
									
									<table class="formatted">
										<tr><td class="formatted-title">{{'doppler.obs_doppler.UtA_Right.title' | translate}}</td></tr>
										<tr><app-dopplerprint Name="PI" key="RAPI" [fetus]="fetus"></app-dopplerprint></tr>
										<tr><app-dopplerprint Name="RI" key="RARI" [fetus]="fetus"></app-dopplerprint></tr>
										<tr><app-dopplerprint Name="SD" key="RASD" [fetus]="fetus"></app-dopplerprint></tr>
									</table>
								</div>
							</td>
						</tr></table>
						<table *ngIf="print['doppler'] && print.doppler[fetus]"><tr>
							<td class="w-1/2 px-8 py-6" style="width:50%" *ngIf="print.doppler[fetus]['Ductus'] || print.doppler[fetus]['Ductus'] === 0">
								<div class="inner-wrapper p-16">
									<div>{{'doppler.obs_doppler.ductus_venosus.title' | translate}}: <strong>{{'general.observed_notobserved.' + (print.doppler[fetus]['Ductus'] == 1 ? 'not_observed' : 'observed') | translate}}</strong></div>
								</div>
							</td>
							<td class="w-1/2 px-8 py-6" style="width:50%" *ngIf="print.doppler[fetus]['Awave'] || print.doppler[fetus]['Awave'] === 0">
								<div class="inner-wrapper p-16">
									<div>{{'doppler.obs_doppler.ductus_venosus.a_wave.title' | translate}}: <strong>{{'general.positive_negative.' + (print.doppler[fetus]['Awave'] == 1 ? 'negative' : 'positive') | translate}}</strong></div>
								</div>
							</td>
						</tr>
						</table>
						<div class="px-8 py-6" *ngIf="print['doppler'] && print.doppler[fetus] && print.doppler[fetus]['DuctosComments']">
							<div class="inner-wrapper p-16">
								<div class="comment"><strong>{{'doppler.obs_doppler.ductus_venosus.title'| translate}} {{'general.Comment' | translate}}: </strong>{{print.doppler[fetus]['DuctosComments']}}</div>
							</div>
						</div>
						<div class="px-8 py-6" *ngIf="print['doppler'] && print.doppler[fetus] && print.doppler[fetus]['Comments']">
							<div class="inner-wrapper p-16">
								<div class="comment"><strong>{{'doppler.obs_doppler.title' | translate}} {{'general.Comment' | translate}}: </strong>{{print.doppler[fetus]['Comments']}}</div>
							</div>
						</div>
					</div>
				</section>
			</ng-container>
			<!--Follicles Ovaries-->

			<section>
				<table class="w100 section" *ngIf="print.follicles && print.follicles[0]">
					<tr>
						<td class="section-subtitle" style='display: inline-block;'>{{'invasive.title' | translate}}</td>
					</tr>
					<tr style="display: flex;">
						<td style="width: 45%;">
							<section class="section">
								<table *ngIf="print.follicles[0].RightOvary && print.follicles[0].RightOvary.length > 0">
									<tr>
										<td class="section-subtitle" style='display: inline-block;'>{{'GynaecologOvaries.Right_Ovary.Right_Ovary_Title' | translate}}</td>
									</tr>
									<tr>
										<td class="w20"><strong>#</strong></td>
										<td class="w20"><strong>X</strong></td>
										<td class="w20"><strong>Y</strong></td>
										<td class="w20"><strong>Z</strong></td>
										<td class="w20"><strong>{{'Cyst.AVG' |translate}}</strong></td>
									</tr>
									<hr>
									<tr *ngFor="let folc of print.follicles[0].RightOvary;index as i">
										<td class="w20">{{i+1}}.</td>
										<td class="w20">{{folc.Xindex}}</td>
										<td class="w20">{{folc.Yindex}}</td>
										<td class="w20">{{folc.Zindex}}</td>
										<td class="w20">{{folc.AVG}}</td>
									</tr>
								</table>
							</section>
						</td>
						<td style="width: 45%;">
							<section class="section">
								<table *ngIf="print.follicles[0].LeftOvary && print.follicles[0].LeftOvary.length > 0">
									<tr>
										<td class="section-subtitle" style='display: inline-block;'>{{'GynaecologOvaries.Left_Ovary.Left_Ovary_Title' | translate}}</td>
									</tr>
									<tr>
										<td class="w20"><strong>#</strong></td>
										<td class="w20"><strong>X</strong></td>
										<td class="w20"><strong>Y</strong></td>
										<td class="w20"><strong>Z</strong></td>
										<td class="w20"><strong>{{'Cyst.AVG' |translate}}</strong></td>
									</tr>
									<hr>
									<tr *ngFor="let folc of print.follicles[0].LeftOvary;index as i">
										<td class="w20">{{i+1}}.</td>
										<td class="w20">{{folc.Xindex}}</td>
										<td class="w20">{{folc.Yindex}}</td>
										<td class="w20">{{folc.Zindex}}</td>
										<td class="w20">{{folc.AVG}}</td>
									</tr>
								</table>
							</section>
						</td>
					</tr>

				</table>
			</section>

			<!--Follicles Ovaries-->
			
			<section class="section" *ngIf="(! print.isOBS || es.switchOvaries) && print.uterus">
				<h4 class="section-subtitle" *ngIf="print.uterus">{{'GynaecologyUterus.Gynaecology_Uterus_Title' | translate}}</h4>
				<table *ngIf="print.uterus.Position || print.uterus.Texture"><tr>
					<td><dt>{{'GynaecologyUterus.Size_and_Position.position' | translate}}</dt> <dd>{{'GynaecologyUterus.Size_and_Position.'+(print.uterus.Position) | translate}}</dd></td>
					<td *ngIf="print.uterus.Texture"><dt>{{'GynaecologyUterus.Size_and_Position.Texture' | translate}}</dt> <dd>{{'GynaecologyUterus.Size_and_Position.'+print.uterus.Texture.replace(' ','_') | translate}}</dd></td>
				</tr></table>
				<table>
					<tr *ngIf="print.uterus.Size || print.uterus.SizeX || print.uterus.SizeY || print.uterus.SizeZ" style='margin-top: 1em'>
						<td *ngIf="print.uterus.Size">{{'measurements.titles.Measurements' | translate}} ({{'GynaecologyUterus.Size_and_Position.'+print.uterus.Size.replace(' + ', '_plus_') | translate}})</td>
						<td *ngIf="print.uterus.SizeX">{{'GynaecologyUterus.Size_and_Position.L' | translate}}: <strong>{{print.uterus.SizeX}}mm</strong></td> 
						<td *ngIf="print.uterus.SizeY">{{'GynaecologyUterus.Size_and_Position.W' | translate}}: <strong>{{print.uterus.SizeY}}mm</strong></td>
						<td *ngIf="print.uterus.SizeZ">{{'GynaecologyUterus.Size_and_Position.H' | translate}}: <strong>{{print.uterus.SizeZ}}mm</strong></td>
						<td *ngIf="print.uterus.SizeX && print.uterus.SizeY && print.uterus.SizeZ">{{'GynaecologyUterus.Size_and_Position.Vol' | translate}}: <strong>{{print.UterusVol()}}cc</strong></td>
					</tr>
					<tr *ngIf="print.uterus.Cervical || print.uterus.Cysts" style='margin-top: 1em'>
						<td *ngIf="print.uterus.Cervical">{{'GynaecologyUterus.Size_and_Position.Cervical_Length' | translate}}: <strong>{{print.uterus.Cervical}}mm</strong></td>
						<td *ngIf="print.uterus.Funneling > -1">{{'GynaecologyUterus.Size_and_Position.Funneling' | translate}}: <strong>{{'general.PositiveNegative.'+(print.uterus.Funneling == 0 ? 'yes': 'No') | translate}}</strong></td>
						<td *ngIf="print.uterus.Cysts">{{'GynaecologyUterus.Number_of_Cervical_Cysts' | translate}}: <strong>{{print.uterus.Cysts}}</strong></td>
						<td *ngIf="print.uterus.Adenomyosis > -1">{{'GynaecologyUterus.Adenomyosis' | translate}}: <strong>{{'general.PositiveNegative.'+(print.uterus.Adenomyosis == 0 ? 'yes':'No') | translate}}</strong></td>
						<td></td>
					</tr>
				</table>
				<table *ngIf="print.uterus.Endometrial || print.uterus.Echogenicity || print.uterus.EndoPhase || print.uterus.EndoStripe === 0 || print.uterus.EndoStripe === 1" style='margin-top: 1em'><tr>
					<td>{{'GynaecologyUterus.Endometrium.Endometrium_Title' | translate}}</td>
					<td *ngIf="print.uterus.Endometrial">{{'GynaecologyUterus.Endometrium.Width' | translate}}: <strong>{{print.uterus.Endometrial}}mm</strong></td>
					<td *ngIf="print.uterus.EndoStripe === 0 || print.uterus.EndoStripe === 1">
						{{ "GynaecologyUterus.Endometrium.Thin_Stripe" | translate }}:
						<ng-container *ngIf="print.uterus.EndoStripe === 0; else notZeroOrOne">
						  <strong>{{ "general.seen" | translate }}</strong>
						</ng-container>
						<ng-template #notZeroOrOne>
						  <ng-container *ngIf="print.uterus.EndoStripe === 1">
							<strong>{{ "general.unseen" | translate }}</strong>
						  </ng-container>
						</ng-template>
					</td>
					<td *ngIf="print.uterus.Echogenicity">{{'GynaecologyUterus.Endometrium.Echogenicity' | translate}}: <strong>{{'GynaecologyUterus.Endometrium.Echogenicity_'+print.uterus.Echogenicity.replace('echogenicity','') | translate}}</strong></td>
					<td *ngIf="print.uterus.EndoPhase">{{'GynaecologyUterus.Endometrium.Phase' | translate}}: <strong>{{'GynaecologyUterus.Endometrium.Phae_' + print.uterus.EndoPhase | translate}}</strong></td>
				</tr></table>
				
				<table *ngIf="print.uterus && print.uterus.Myomas && print.uterus.Myomas.length" class="symetric">
					<tr>
						<td></td>
						<th>D1 - mm</th>
						<th></th>
						<th>D2 - mm</th>
						<th></th>
						<th>D3 - mm</th>
						<!-- <th>
							{{ 'GynaecologyUterus.Myoma.Vol' | translate }} cm^3
						</th> -->
						<th></th>
					  </tr>
					<tr *ngFor="let myoma of print.uterus.Myomas">
						<td>{{myoma == print.uterus.Myomas[0] ? ('GynaecologyUterus.Myoma.Myoma_Title' | translate) : ''}}</td>
						<td>{{ myoma[0] !== 'undefined' ? myoma[0] : 'null' }}</td>
						<td>x</td>
						<td>{{ myoma[1] !== 'undefined' ? myoma[1] : 'null' }}</td>
						<td>x</td>
						<td>{{ myoma[2] !== 'undefined' ? myoma[2] : 'null' }}</td>
						<td >{{myoma[4] && myoma[4]!=='null'&& myoma[4]!=='undefined'?myoma[4]:""}}</td>
						<td *ngIf="myoma[3] && myoma[3]!=='null'&& myoma[3]!=='undefined'">{{'GynaecologyUterus.Myoma.'+ myoma[3] | translate}}</td>
					</tr>
				</table>
				<section class="section" *ngIf="print.uterus.Diagnosis || (print.uterus.DiagnosisList && print.uterus.DiagnosisList.length > 0)" style="width: 98%;">
					<strong>{{'summary.diagnosis.title' | translate}}:</strong> <br/>
					<div *ngIf="print.uterus.DiagnosisList && print.uterus.DiagnosisList.length > 0" style="text-align: left;direction: ltr;width: 100%;">
						<ng-container *ngFor="let a of print.uterus.DiagnosisList">{{a[1]}}<br/></ng-container>
					</div>
					<ng-container *ngIf="print.uterus.Diagnosis">
						<hr *ngIf="print.uterus.Diagnosis && (print.uterus.DiagnosisList && print.uterus.DiagnosisList.length > 0)">
						<h4 *ngIf="! print.uterus.DiagnosisList">{{'summary.diagnosis.title'| translate}}</h4>
						<div class="comment">{{print.uterus.Diagnosis}}</div>
					</ng-container>
					<br>
				</section>
				<div class="px-8 py-6" *ngIf="print.uterus.Comments">
					<div class="inner-wrapper p-16">
						<div class="comment"><strong>{{'general.Comment' | translate}}: </strong>{{print.uterus.Comments}}</div>
					</div>
				</div>
			</section>

			<section class="section" *ngIf="(! print.isOBS || es.switchOvaries) && print.adnexa">
				<h4 class="section-subtitle">{{'GynaecologOvaries.Gynaecology_Ovaries_Title' | translate}}</h4>
				<table class="symetric"><tr>
					<td style="width:50%" *ngIf="print.adnexa['RObserved'] || print.adnexa.RSizeX || print.adnexa.RSizeY || print.adnexa.RSizeZ || print.adnexa.RComments || print.adnexa.RPI || print.adnexa.RRI || print.adnexa.RSD || print.adnexa['RPIOK'] || print.adnexa['RRIOK'] || print.adnexa['RSDOK']">
						<div class="inner-wrapper" >
							<table class="formatted symetric">
								<tr>
									<td class="formatted-title">
										{{ 'GynaecologOvaries.Right_Ovary.Right_Ovary_Title' | translate }} -
									</td>
									<td *ngIf="print.adnexa['RObserved']">
										{{'GynaecologOvaries.Right_Ovary.Overview_' + print.adnexa['RObserved'] | translate}}
									</td>
								</tr>
								
								<tr *ngIf="print.adnexa.RSizeX || print.adnexa.RSizeY || print.adnexa.RSizeZ">
									<td *ngIf="print.adnexa.RSizeX">{{'GynaecologOvaries.Right_Ovary.L' | translate}}: <strong>{{print.adnexa.RSizeX}}mm</strong></td>
									<td *ngIf="print.adnexa.RSizeY">{{'GynaecologOvaries.Right_Ovary.W' | translate}}: <strong>{{print.adnexa.RSizeY}}mm</strong></td>
									<td *ngIf="print.adnexa.RSizeZ">{{'GynaecologOvaries.Right_Ovary.H' | translate}}: <strong>{{print.adnexa.RSizeZ}}mm</strong></td>
									<td *ngIf="print.adnexa['RVol']">{{'GynaecologOvaries.Right_Ovary.Vol' | translate}}: <strong>{{print.adnexa.RVol}}cc</strong></td>
								</tr>
								<tr *ngIf="print.adnexa.RPI || print.adnexa.RRI || print.adnexa.RSD || print.adnexa['RPIOK'] || print.adnexa['RRIOK'] || print.adnexa['RSDOK']" >
									<td><strong>{{'GynaecologOvaries.Right_Ovary.Doppler_Title' | translate}}</strong></td>
									<td *ngIf="print.adnexa.RPI || print.adnexa['RPIOK']">PI: <strong>{{print.adnexa.RPI}} {{print.adnexa['RPIOK'] ? ('general.' + print.adnexa['RPIOK']| translate) :''}}</strong></td>
									<td *ngIf="print.adnexa.RRI || print.adnexa['RRIOK']">RI: <strong>{{print.adnexa.RRI}} {{print.adnexa['RRIOK'] ? ('general.' + print.adnexa['RRIOK']| translate) :''}}</strong></td>
									<td *ngIf="print.adnexa.RSD || print.adnexa['RSDOK']">SD: <strong>{{print.adnexa.RSD}} {{print.adnexa['RSDOK'] ? ('general.' + print.adnexa['RSDOK']| translate) :''}}</strong></td>
								</tr>
								<tr *ngIf="print.adnexa.RComments">
									<td colspan="4" class="comment"><strong>{{'general.Comment' | translate}}: </strong>{{print.adnexa.RComments}}</td>
								</tr>
							</table>
						</div>
					</td>
					<td style="width:50%" *ngIf="print.adnexa['LObserved'] || print.adnexa.LSizeX || print.adnexa.LSizeY || print.adnexa.LSizeZ || print.adnexa.LComments || print.adnexa.LPI || print.adnexa.LRI || print.adnexa.LSD || print.adnexa['LPIOK'] || print.adnexa['LRIOK'] || print.adnexa['LSDOK']">
						<div class="inner-wrapper">
							
							<table class="formatted symetric">
								<tr >
									<td class="formatted-title">
										{{ 'GynaecologOvaries.Left_Ovary.Left_Ovary_Title' | translate }} -
									
									</td>
									<td *ngIf="print.adnexa['LObserved']">	{{ 'GynaecologOvaries.Left_Ovary.Overview_' + print.adnexa['LObserved'] | translate }}</td>
									
								</tr>
								
								<tr *ngIf="print.adnexa.LSizeX || print.adnexa.LSizeY || print.adnexa.LSizeZ ">
									<td *ngIf="print.adnexa.LSizeX">{{'GynaecologOvaries.Left_Ovary.L' | translate}}: <strong>{{print.adnexa.LSizeX}}mm</strong></td>
									<td *ngIf="print.adnexa.LSizeY">{{'GynaecologOvaries.Left_Ovary.W' | translate}}: <strong>{{print.adnexa.LSizeY}}mm</strong></td>
									<td *ngIf="print.adnexa.LSizeZ">{{'GynaecologOvaries.Left_Ovary.H' | translate}}: <strong>{{print.adnexa.LSizeZ}}mm</strong></td>
									<td *ngIf="print.adnexa['LVol']">{{'GynaecologOvaries.Left_Ovary.Vol' | translate}}: <strong>{{print.adnexa.LVol}}cc</strong></td>
								</tr>
								<tr *ngIf="print.adnexa.LPI || print.adnexa.LRI || print.adnexa.LSD || print.adnexa['LPIOK'] || print.adnexa['LRIOK'] || print.adnexa['LSDOK']">
									<td><strong>{{'GynaecologOvaries.Left_Ovary.Doppler_Title' | translate}}</strong></td>
									<td *ngIf="print.adnexa.LPI">PI: <strong>{{print.adnexa.LPI}} {{print.adnexa['LPIOK'] ? ('general.' + print.adnexa['LPIOK']| translate) :''}}</strong></td>
									<td *ngIf="print.adnexa.LRI">RI: <strong>{{print.adnexa.LRI}} {{print.adnexa['LRIOK'] ? ('general.' + print.adnexa['LRIOK']| translate) :''}}</strong></td>
									<td *ngIf="print.adnexa.LSD">SD: <strong>{{print.adnexa.LSD}} {{print.adnexa['LSDOK'] ? ('general.' + print.adnexa['LSDOK']| translate) :''}}</strong></td>
								</tr>
								<tr *ngIf="print.adnexa.LComments">
									<td class="comment" colspan="4"><strong>{{'general.Comment' | translate}}: </strong>{{print.adnexa.LComments}}</td>
								</tr>
							</table>
							
						</div>
					</td>
				</tr></table>
				<div class="section" *ngIf="print.adnexa && ((print.adnexa['DiagnosisList'] && print.adnexa['DiagnosisList'].length > 0) || print.adnexa.Diagnosis)" style="width: 98%;">
					<strong *ngIf="true">{{'summary.diagnosis.title' | translate}}:</strong> 
					<div *ngIf="print.adnexa.DiagnosisList && print.adnexa.DiagnosisList.length > 0" style="text-align: left;direction: ltr;">
						<ng-container *ngFor="let a of print.adnexa.DiagnosisList">{{a[1]}}<br/></ng-container>
					</div>
					<ng-container *ngIf="print.adnexa.Diagnosis">
						<hr *ngIf="print.adnexa.Diagnosis && (print.adnexa.DiagnosisList && print.adnexa.DiagnosisList.length > 0)">
						<h4 *ngIf="! print.adnexa.DiagnosisList">{{'summary.diagnosis.title'| translate}}</h4>
						<div class="comment">{{print.adnexa.Diagnosis}}</div>
					</ng-container>
					<br>
				</div>
				<div class="px-8 py-6" *ngIf="print.adnexa.Comments || print.adnexa['Fluidv']">
					<div class="inner-wrapper p-16">
						<div *ngIf="print.adnexa['Fluidv']">{{'GynaecologOvaries.POD.POD_Title' | translate}} {{'GynaecologOvaries.POD.Title' | translate}}: <strong>{{'general.PositiveNegative.'+ print.adnexa.Fluidv | translate}}</strong></div>
						<div class="comment" *ngIf="print.adnexa.Comments"><strong>{{'general.Comment' | translate}}: </strong>{{print.adnexa.Comments}}</div>
					</div>
				</div>
			</section>
			
            <section class="section" *ngIf="print.Forms && print.Forms[0]">
				<!-- <h4 class="section-title">{{'general.opinion' | translate}}</h4> -->
					<div style="display: block !important;" ><span *ngIf="print.Forms[0].FormContent" class="formatted-comment letter" [innerHtml]="print.Forms[0].FormContent">		
					</span>
					</div>
			</section>
			<section class="section"  *ngIf="print.Forms && print.Forms[0]">
				<table>
					<tr *ngFor="let signer of print.signers;let i = index">
					<td class="w-1/3"><strong>{{(signer['Title'] ?  signer['Title']+ ' ': '')+ signer.Name}}</strong></td>
					<td class="w-1/3">
						<ng-container *ngIf="signer.DrNum">מ.ר. <strong>{{signer.DrNum}} </strong></ng-container>
						<ng-container *ngIf="signer.ExpertNum">מ.ר.מ. <strong>{{signer.ExpertNum}}</strong></ng-container>
					</td>
					<td class="w-1/3" *ngIf="i == 0">{{(print.isForm ? 'general.date' :'general.ExaminationDate') | translate}}: <strong>{{print.exam.CreationTime | date:"dd.MM.yyyy"}}</strong></td>
					</tr>
				</table>
			</section>
			
			<section class="section summary" *ngIf="print.summary && print.summary[0]">
				<p class="section-subtitle">{{'summary.SummaryTitle' | translate}}</p>
				<div *ngIf="print.isFetalEcho && print.summary[0] && print.summary[0].ImagingQuality">
					<strong>{{'summary.ImagingQuality.title' | translate}}:</strong>
					{{'summary.ImagingQuality.'+print.summary[0].ImagingQuality | translate}}
				</div>

				<div *ngIf="print.summary[0].Diagnosis && (print.exam.ExamType == 'Gynecology'|| print.exam.ExamType=='Fetal Echo' || print.exam.ExamType=='Pediatric Echo')" class="comment section-inner"><h4>{{'summary.InnerSummary' | translate}}: </h4><br><span [innerHtml]="print.summary[0].Diagnosis"></span></div>

				<div *ngIf="print.summary[0].Diagnosis && print.exam.ExamType != 'Gynecology' && print.exam.ExamType !='Fetal Echo' && print.exam.ExamType !='Pediatric Echo'" class="comment section-inner"><h4>{{'summary.InnerSummary' | translate}}: </h4>{{print.summary[0].Diagnosis}}</div>
				<div *ngIf="(print.summary[0].Repeat1 || print.summary[0].FollowUpExam) && print.exam.ExamType === 'Fetal Echo'" style="margin-top:1em"><strong >{{'summary.followup' | translate}} 
					{{print.summary[0].FollowUpExam ? ((print.summary[0].FollowUpExam == 'Gynecology'? 'exam.type.GYNE.Genecology' : 'exam.type.OBS.' + print.summary[0].FollowUpExam) | translate)  : ''}} 
					<ng-container *ngIf="print.summary[0].Repeat1">
						{{'summary.in' | translate}} {{print.summary[0].Repeat1}} {{ print.summary[0].Repeat2 ? ('general.weeks.'+ print.summary[0].Repeat2 | translate) : ''}}
					</ng-container>
					</strong>
				</div>

				<div *ngIf="print.summary[0].Diagnosis  || print.summary[0].Anomalies || (print.summary[0].AnomaliesList  && print.diagnosisList?.length > 0)">
					<div class="section" *ngIf=" (print.summary[0].AnomaliesList.length > 0 || print.summary[0].Anomalies) && print.exam.ExamType !=='Fetal Echo'">
						<strong *ngIf="(print.summary[0].AnomaliesList && print.summary[0].AnomaliesList.length > 0)">
							{{'summary.diagnosis.DiagnosisTree' | translate}}:
						</strong> 
						<div *ngIf="(print.summary[0].AnomaliesList && print.summary[0].AnomaliesList.length > 0)" style="text-align: left;direction: ltr;">
							<ng-container *ngFor="let a of print.summary[0].AnomaliesList">{{a[1]}}<br/></ng-container>
						</div>
						<ng-container *ngIf="print.summary[0].Anomalies"><hr *ngIf="(print.summary[0].AnomaliesList && print.summary[0].AnomaliesList.length > 0)">
							<h4 *ngIf="print.summary[0].Anomalies">
								{{'summary.diagnosis.DiagnosisComments'| translate}}:
							</h4>
							<h4 *ngIf="!(print.summary[0].AnomaliesList && print.summary[0].AnomaliesList.length > 0)">{{'summary.diagnosis.title'| translate}}</h4>
							<div class="comment">{{print.summary[0].Anomalies}}</div>
						</ng-container>
						<br>
					</div>
					<div class="section" *ngIf="print.exam.ExamType === 'Fetal Echo' &&  ((print.diagnosisList && print.diagnosisList?.length > 0) || print.summary[0].Anomalies)">
						<strong *ngIf="(print.diagnosisList && print.diagnosisList.length > 0)">{{'summary.diagnosis.title' | translate}}:</strong> 
						<div *ngIf="(print.diagnosisList && print.diagnosisList.length > 0)" style="text-align: left;direction: ltr;">
							<ng-container *ngFor="let a of print.diagnosisList">{{a[1]}}<br/></ng-container>
						</div>
						<ng-container *ngIf="print.summary[0].Anomalies"><hr *ngIf="(print.diagnosisList && print.diagnosisList.length > 0)">
							<h4 *ngIf="!(print.diagnosisList && print.diagnosisList.length > 0)">{{'summary.diagnosis.title'| translate}}</h4>
							<div class="comment">{{print.summary[0].Anomalies}}</div>
						</ng-container>
						<br/>
					</div>
				</div>

				<div *ngIf="print.summary[0].DiagnosisGeneral || print.summary[0].DiagnosisGeneralComments">
					<div class="section">
						<strong>
							{{'summary.diagnosis.title' | translate}}:
						</strong>
						<div *ngIf="print.summary[0].DiagnosisGeneral" style="text-align: left;direction: ltr;">
							{{print.summary[0].DiagnosisGeneral}}
						</div>
						<ng-container *ngIf="print.summary[0].DiagnosisGeneralComments">
							<hr *ngIf="print.summary[0].DiagnosisGeneralComments">
							<h4>
								{{'summary.diagnosis.DiagnosisComments'| translate}}:
							</h4>
							<div class="comment">
								{{print.summary[0].DiagnosisGeneralComments}}
							</div>
						</ng-container>
						<br>
					</div>
				</div>

				<section class="comment section-inner" *ngIf="print.summary[0].Recomm">
					<div *ngIf="print.summary[0].Recomm" ><strong> {{'summary.recommendations.title' | translate}}</strong><br>{{print.summary[0].Recomm}}</div>
				</section>
				<div *ngIf="print.exam.ExamType !== 'Fetal Echo' && (print.summary[0].Repeat1 || print.summary[0].FollowUpExam)" style="margin-top:1em"><strong >{{'summary.followup' | translate}} 
					{{print.summary[0].FollowUpExam ? ((print.summary[0].FollowUpExam == 'Gynecology'? 'exam.type.GYNE.Genecology' : 'exam.type.OBS.' + print.summary[0].FollowUpExam) | translate)  : ''}} 
					<ng-container *ngIf="print.summary[0].Repeat1">
						{{'summary.in' | translate}} {{print.summary[0].Repeat1}} {{ print.summary[0].Repeat2 ? ('general.weeks.'+ print.summary[0].Repeat2 | translate) : ''}}
					</ng-container>
					</strong>
				</div>
			
			
			<section class="section" *ngIf="!print.Forms && print.summary && print.summary[0] && print.summary[0].ResultsRating">
				<table *ngIf="print.summary[0].ResultsRating">
					<tr>
						<td >
							<dt>{{'summary.ResultsRating.title' | translate}}</dt>
							<dd>{{'summary.ResultsRating.'+print.summary[0].ResultsRating | translate}}</dd>
						</td>
					</tr>
				</table>
			</section>
			<section class="section" *ngIf="!print.Forms && print.summary && print.summary[0] && print.summary[0].Terms">
				<table *ngIf="print.summary[0].Terms">
					<tr>
						<td >
							<dt>{{'summary.Terms' | translate}}</dt>
							<dd>{{print.summary[0].Terms }}</dd>
						</td>
					</tr>
				</table>
			</section>
		</section>
			<section class="section" *ngIf="! print.Forms">
				<table>
					<tr *ngFor="let signer of print.signers;let i = index">
					<td class="w-1/3"><strong>{{(signer['Title'] ?  signer['Title']+ ' ': '')+ signer.Name}}</strong></td>
					<td class="w-1/3">
						<ng-container *ngIf="signer.DrNum">מ.ר. <strong>{{signer.DrNum}} </strong></ng-container>
						<ng-container *ngIf="signer.ExpertNum">מ.ר.מ. <strong>{{signer.ExpertNum}}</strong></ng-container>
					</td>
					<td class="w-1/3" *ngIf="i == 0">{{(print.isForm ? 'general.date' :'general.ExaminationDate') | translate}}: <strong>{{print.exam.CreationTime | date:"dd.MM.yyyy"}}</strong></td>
					</tr>
				</table>
			</section>


			<ng-container *ngFor="let signer of print.signers">
				<img *ngIf="signer['signature']" [src]="'assets/signatures/'+signer['signature']" style='max-height: 100px; break-inside: avoid;'>
			</ng-container>
			<div *ngIf="hasImages && print.images.length > 0" class="signatures" [ngClass]="'perpage'+imPerPage">
				<ng-container *ngFor="let image of print.images">
					<img [src]="'/api/getmedia/'+image+'/n'">
				</ng-container>
			</div>
			<span class="finished" *ngIf="loaded"></span>
		</main>
	</div>
