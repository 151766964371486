import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import {ExamsService}from '../exams.service';
import { UntypedFormGroup,UntypedFormBuilder } from '@angular/forms';
import {AutotextService} from '../autotext.service';
import {LoginService} from '../login.service';
import { PatientsService } from '../patients.service';
import { Subscription,Subject } from 'rxjs';
import { DiagnosisService } from '../diagnosis.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';


@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy/*, AfterViewInit*/ {
  editorConfig: AngularEditorConfig = {
    editable: this.es.isClosed ? false : true,
    height: "fit-contnet",
    toolbarHiddenButtons: [
      ["subscript", "superscript", "strikeThrough"],
      [
        "backgroundColor",
        "customClasses",
        "link",
        "unlink",
        "insertImage",
        "insertVideo",
        "removeFormat",
        "toggleEditorMode",
      ],
    ],
  };
  summaryForm: UntypedFormGroup;
  lastDiagnosis: string;
  lastRecommendations: string;
  prnerr:string;
  trerr;
  subscritpion: Subscription;
  fEdit$: Subscription;
  checkleumit: boolean;
  isFetalEcho: boolean = false;
  signed:Date;
  signer:String;
  showlock = false;
  showComment = false;
  closeSubject: Subject<void> = new Subject<void>();

  constructor(public es: ExamsService, private fb: UntypedFormBuilder, public atS:AutotextService, public ls: LoginService, private ps: PatientsService, private ds: DiagnosisService) { 
    this.summaryForm = this.fb.group({
      Repeat1: [''],
      Repeat2: [''],
      Diagnosis: [''],
      Recomm: [''],
      ReferingDoc: [''],
      ExamMethod: [''],
      DiagnosisCodes: [''],
      Anomalies: [''],
      Abdominal: [''],
      Vaginal: [''],
      Transperineal: [''],
      Rectal: [''],
      DiagnosisCopy: [''],
      HMO: [''],
      PRN: [''],
      TreatmentCode: [''],
      FollowUpExam: [''],
      Quality:[''],
      IndicationCodes: [''],
      AnomaliesIndication: [''],
      ImagingQuality: [''],
      Terms: [''],
      ResultsRating: [''],
      DiagnosisGeneral: [''],
      DiagnosisGeneralComments: [''],
      findingsComments: [''],
    });
    if (!this.es.isClosed) {
      this.es.getLastSummary().subscribe((data) => {
        this.lastDiagnosis = data["diagnosis"];
        this.lastRecommendations = data['recommendations'];
        this.isFetalEcho = this.es.ExamType === 'Fetal Echo';
      }, (err) => {
        console.log(err);
      });
    }
  }

  addLastDiagnosis() {
    if (this.summaryForm.get("Diagnosis").value)
      this.summaryForm.get("Diagnosis").setValue(this.summaryForm.get("Diagnosis").value + this.lastDiagnosis);
    else
      this.summaryForm.get("Diagnosis").setValue(this.lastDiagnosis);
    this.summaryForm.get('DiagnosisCopy').setValue(1);
  }

  addLastRecomm() {
    if (this.summaryForm.get("Recomm").value)
      this.summaryForm.get("Recomm").setValue(this.summaryForm.get("Recomm").value + this.lastRecommendations);
    else
      this.summaryForm.get("Recomm").setValue(this.lastRecommendations);
  }

  ngOnInit() {
    this.subscritpion = this.es.formLoaded$.subscribe(()=> {
      if(this.ls.PRNcheck && this.summaryForm.get('PRN').value){
        this.summaryForm.get('PRN').disable({emitEvent:false});
        this.summaryForm.get('TreatmentCode').disable({emitEvent:false});
        this.checkleumit=false;
      }
      else{
        let ct = new Date(this.es.CreationTime);
        //temporarily opening for all old exams back to Jan.1 2024 -- remove this later
        let janFirst2024 = new Date('2024-01-01');
        if(this.ls.hasLeumit && this.summaryForm.disabled && ct >= janFirst2024/*Date.now() - ct.getTime() < 1000 * 24 * 60 * 60 * 10*/){
          this.summaryForm.get('PRN').enable();
          this.summaryForm.get('TreatmentCode').enable();
          this.summaryForm.get('HMO').enable();
        }
      }
    });
   // if(this.ls.PRNcheck)
      //this.es.fillForm(this.summaryForm,"summary",false,true,['HMO','PRN','TreatmentCode']);
    //else
      this.es.fillForm(this.summaryForm,"summary",false,true,[],['HMO','PRN','TreatmentCode','FindingsComments']);
    if(this.ls.isCardio){
      this.es.getClosing().subscribe((data)=>{
        if(data['ClosedDate'])
          this.signed = new Date(data['ClosedDate']);
        if(data['Signer'])
          this.signer = data['Signer'];
      },(error)=>{
        console.log(error);
      })
    }
    this.fEdit$ = this.summaryForm.get('Diagnosis').valueChanges.subscribe((data) => {
      if(data != null && !this.es.isClosed && this.es.ExamType !='Gynecology'&& this.es.ExamType!='Fetal Echo' && this.es.ExamType!='Pediatric Echo')
        this.showComment = true;
    })
  }
  /*ngAfterViewInit(): void {
      
  }*/

  ngOnDestroy(): void {
      this.subscritpion.unsubscribe();
      this.fEdit$.unsubscribe();
  }

  checkPRN(){
    let prn = this.summaryForm.get('PRN').value;
    let tcode = this.summaryForm.get('TreatmentCode').value;
    this.ps.checkPRN(this.ps.currentPatient.ID,prn,tcode).subscribe((data)=>{
      if(data['response'] == ''){//verified
        this.prnerr = null;
        this.summaryForm.get('PRN').disable({emitEvent:false});
        this.summaryForm.get('TreatmentCode').disable({emitEvent:false});
        this.es.saveVals('PRN',prn,false);
        this.es.saveVals('TreatmentCode',tcode,false);
        if(this.es.isClosed)
          this.es.saveVals('HMO','Leumit',false);
        this.summaryForm.get('HMO').disable({emitEvent:false});
        this.checkleumit=false;
      }
      else{
        this.prnerr = data['response'];
      }
    },(err)=>{
      this.prnerr = err['error'];
    }) 
  }
  resetGrp(item: string) {
    if (!this.es.isClosed)
      this.summaryForm.get(item).setValue(null);
  }


  checklength(){
    if(! this.ls.PRNcheck)
      return;
    if((this.summaryForm.get("PRN").value + '').length < 9){
      if(this.summaryForm.get("PRN").value)
        this.prnerr = "PRN invalid";
    }
    else{
      this.prnerr = null;
      this.checkleumit = true;
    }
    this.trerr = this.summaryForm.get('TreatmentCode').value && (this.summaryForm.get('TreatmentCode').value + '').length < 5;

  }

  showSign(){
    return this.ls.userType == 'doctor' && ! this.es.isClosed && ! this.showlock
  }

  signClose(){
    this.es.signExam(this.ls.getUserName()).subscribe((d)=>{
      this.es.exitExam();
      this.signer = this.ls.getUserName();
      this.signed = new Date();
      this.summaryForm.disable({emitEvent: false});
      this.closeSubject.next();
      this.es.isClosed = true;
    },(error)=>{
      console.log(error);
    })
  }
  
}
