import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AutotextService } from "../autotext.service";
import { ExamsService } from "../exams.service";
import { PregnancyService } from "../pregnancy.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-medicalhistory",
  templateUrl: "./medicalhistory.component.html",
  styleUrls: ["./medicalhistory.component.scss"],
})
export class MedicalhistoryComponent implements OnInit {
  @Input("isWeightAss") isWeightAss;
  @Input("typeOfUser") typeOfUser;
  @Input("modality") modality;
  @Output() newItemEvent = new EventEmitter<boolean>();

  showHistory = false;
  lastMed: string;
  showAlert: boolean = false;

  atSTitleMedicalHistory = this.translate.instant("pregnancy.MedicalHistory.title");

  constructor(
    public ps: PregnancyService,
    public es: ExamsService,
    public atS: AutotextService,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    if (this.es.ExamType == "Fetal Echo") {
      this.showHistory = true;
      this.newItemEvent.emit(this.showHistory);
    }

    if (!this.es.isClosed) {
      this.es.getLastHistoryFromClosedPreg().subscribe(
        (data: any) => {
          if (data.history && Array.isArray(data.history)) {
            this.lastMed = data.history.map((record) => record.Remarks).join("\n");
          } else {
            this.lastMed = "";
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }
  setShowHistory() {
    this.showHistory = !this.showHistory;
    this.newItemEvent.emit(this.showHistory);
  }

  addLastMed() {
    const medicalHistoryValue = this.ps.pregForm.get("Remarks").value;
    if (medicalHistoryValue) {
      this.ps.pregForm.get("Remarks").setValue(medicalHistoryValue + "\n" + this.lastMed);
    } else {
      this.ps.pregForm.get("Remarks").setValue(this.lastMed);
    }
  }
}
