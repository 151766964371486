<div class="t-popup popup-patientDetails">

		<div class="t-popup-close"></div>
		
		<div class="t-popup-frame">
			<div class="t-popup-body">

				<button class="t-btn-popup-close" aria-label="close popup" (click)="close(true)"></button>

				<h2>
					<!-- Patient Details Form -->
					{{'GUI.General.Patient Details Form.Patient Details Form' | translate | titlecase}}
				</h2>
				<!-- box (end) -->

				<div class="l-flex l-flex-w t-fieldmargin patient-details">
					
					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<div class="t-field l-flex t-twin-fieldset">
								<label style='width: 3em'>
									<input checked type="radio" name="id" [(ngModel)]="myPat.IsID" [value]="1" class="custom-check customInput" aria-labelledby="pregnancy_popup-anomales" >
                                    <i class="customInput-display"></i>
                                    <span>
										{{'GUI.General.Patient Details Form.ID' | translate}}
									</span>
								</label>
								<label style='width: 4em'>
									<input type="radio" name="id" [(ngModel)]="myPat.IsID" [value]="0" class="custom-check customInput" aria-labelledby="pregnancy_popup-anomales">
                                    <i class="customInput-display"></i>
                                    <span>
										{{'GUI.General.Patient Details Form.Pass' | translate}}
									</span>
								</label>
							</div>

							<i class="t-sep"></i>

							<div class="t-validation-wrap">
								<input type="text" [(ngModel)]="myPat.ID" class="t-field-text w100" (keyup)="clearSpace()" [ngClass]="IDValidator() && ! iderr? '': 'is-invalid'" (change)="idchanged=true"/>
								<div class="t-validation-message">
									<span>{{iderr? iderr : 'Invalid ID Number'}}</span>
								</div>
							</div>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								<!-- First Name -->
								{{'GUI.General.Patient Details Form.First Name' | translate | titlecase}}
							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.First" class="t-field-text w100" (change)="idchanged=true"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								<!-- Last Name -->
								{{'GUI.General.Patient Details Form.Last Name' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.Last" class="t-field-text w100" (change)="idchanged=true"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<div class="w33">
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								<!-- First Name Eng. -->
								{{'GUI.General.Patient Details Form.First Name Eng' | translate | titlecase}}
							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.FirstEng" class="t-field-text w100" (change)="idchanged=true"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								<!-- Last Name Eng. -->
								{{'GUI.General.Patient Details Form.Last Name Eng' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.LastEng" class="t-field-text w100" (change)="idchanged=true"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->
					

					

					<!-- cell ( start ) -->
					<div class="w33" [ngClass]="myPat.Height && myPat.Weight? 'smallbox':''" (change)="myPat.BSAus=null">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Height' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<span class="t-field t-field--number">
									<input [(ngModel)]="myPat.Height" class="t-field-text" type="number" min="0">
									<span class="t-field-desc">
										{{'GUI.General.Patient Details Form.cm' | translate}}
									</span>
								</span>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->					

					<!-- cell ( start ) -->
					<div class="w33" [ngClass]="myPat.Height && myPat.Weight? 'smallbox':''">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Weight' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<span class="t-field t-field--number">
									<input [(ngModel)]="myPat.Weight" class="t-field-text" type="number" min="0" (change)="myPat.BSAus=null">
									<span class="t-field-desc">
										{{'GUI.General.Patient Details Form.kg' | translate}}
									</span>
								</span>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->
					
					<div class="w33 smallbox" *ngIf="myPat.Weight && myPat.Height">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								BMI
							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<span class="t-field t-field--number">
									{{getBMI()}}
								</span>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->


						<!-- cell ( start ) -->
					<div class="w33">

							<div class="l-flex l-flex-c t-field t-field-pad">
	
								<label>
									<!-- Birth Date -->
									{{'GUI.General.Patient Details Form.Birth Date' | translate | titlecase}}

								</label>
	
								<i class="t-sep"></i>
	
								<span class="t-validation-wrap l-fg1">
									<input [(ngModel)]="Birth"  (dateChange)="dateChanged('Birth')"  [matDatepicker]="birthpicker" [max]="maxBD"  class="t-field-text w100"/>
									<img src="../../assets/icons/date.svg" (click)="birthpicker.open()" style='margin-left: -1.8em;position:absolute'>
									<mat-datepicker #birthpicker [startAt]="startBD" startView="year"></mat-datepicker>
								</span>
								
							</div>
							
						</div>
						<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Email' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="email" [(ngModel)]="myPat.Email" class="t-field-text w100"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Mobile' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="tel" [(ngModel)]="myPat.Mobile" class="t-field-text w100"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Phone' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="tel" [(ngModel)]="myPat.Phone" class="t-field-text w100"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Address' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.Address" class="t-field-text w100"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Town' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.City" class="t-field-text w100"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Zip Code' | translate | titlecase}}
							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.Zip" class="t-field-text w100"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Blood Type' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<select class="t-select w100" [(ngModel)]="myPat.BloodType">
									<option value="">
										{{'GUI.Selects.Select' | translate}}
									</option>
                                    <option value="A+">A+</option>
                                    <option value="B+">B+</option>
                                    <option value="AB+">AB+</option>
									<option value="O+">O+</option>
									<option value="A-">A-</option>
                                    <option value="B-">B-</option>
                                    <option value="AB-">AB-</option>
                                    <option value="O-">O-</option>
								</select>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Health Provider' | translate | titlecase}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<select class="t-select w100" [(ngModel)]="myPat.HMO" (change)="idchanged=true">
									<option value="">
										{{'GUI.Selects.Select' | translate}}
									</option>
									<option value="Clalit">
										{{'GUI.Selects.Clalit' | translate}}
									</option>
									<option value="Macabi">
										{{'GUI.Selects.Maccabi' | translate}}
									</option>
									<option value="Meuhedet">
										{{'GUI.Selects.Meuhedet' | translate}}
									</option>
									<option value="Leumit">
										{{'GUI.Selects.Leumit' | translate}}
									</option>
									<option value="Private">
										{{'GUI.Selects.Private' | translate}}
									</option>
								</select>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
							{{'GUI.General.Patient Details Form.Referring Doc' | translate | titlecase}}
							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.RefferDoc" class="t-field-text w100" (change)="idchanged=true"/>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w33" *ngIf="myPat.PID && ls.separatedClinic && (ls.isAdmin || ls.userType == 'secretary')">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
							<img src="../../assets/icons/drb.svg">
							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								{{ls.getDocByUID(myPat.UID)}}
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->
					<div class="w33" *ngIf="! tryCopy && myPat.PID && ls.separatedClinic && (ls.isAdmin || ls.userType == 'secretary')">
						
						<div class="l-flex l-flex-c t-field t-field-pad">
							<button class="search-btn" style="max-width: 3.5em" [disabled]="copyTo? false: 'disabled'" (click)="copyPat()">
								Copy
							</button>
							to
							<select [(ngModel)]="copyTo" class="t-select w100" style="text-align: center;" (change)="idchanged=true">
								<ng-container *ngFor="let e of ls.clinicUsers">
									<option [value]="e.UID" *ngIf="e.UID != myPat.UID">{{e.Name}}</option>
								</ng-container>
							</select>
							
						</div>
						
					</div>
					<div class="w33" *ngIf="copyNote">
						{{copyNote}}
					</div>
					<div class="w33" *ngIf="ls.hasSurrogatePreg">
						<button class="search-btn" (click)="show_surrogate.emit()">Show/Add Surrogate</button>
					</div>
					<!-- cell ( end ) -->

					<!-- cell ( start ) -->
					<div class="w100">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								{{'GUI.General.Patient Details Form.Comments' | translate | titlecase}}

							</label>

							<span class="t-validation-wrap is-sep l-fg1">
								<textarea [(ngModel)]="myPat.Comments" class="t-field-text w100"></textarea>
							</span>
							
						</div>
						
					</div>
					<!-- cell ( end ) -->

				</div>

				<div class="buttons-container">
				<button *ngIf="! hideSave && ! ls.barcodeScan" class="save_patieont" (click)="savePatient();" [ngClass]="(! openAWL && ! awl) ? 'awlopen':''"  matTooltip="{{'GUI.Tooltips.Save patients details' | translate}}" >
					{{'GUI.General.Patient Details Form.Buttons.Save Patient Details' | translate | titlecase}}
					{{myPat.PID ? '': ('GUI.General.Patient Details Form.Buttons.and Close' | translate)}}
				</button>
				<button *ngIf="patservice.zimunFeedID && ! hideSave && ! ls.barcodeScan" class="save_patieont" (click)="savePatient(null, false, true);" [ngClass]="(! openAWL && ! awl) ? 'awlopen':''"  matTooltip="{{'GUI.Tooltips.Save patients details and schedule' | translate}}" >
					{{'GUI.Buttons.Save and Schedule' | translate}}
				</button>
				<button *ngIf="!openAWL && !awl && !hideSave" class="save_patieont" (click)="prepareAWL()"  >
					{{'GUI.General.Patient Details Form.Buttons.Add to TodayList' | translate | titlecase}}

				</button>
			</div>
				
				<h2 *ngIf="(awl || openAWL) && (myPat.Status == 'Waiting')" style='margin-top: 1em'>
					TodayList Details
				</h2>
				<!-- box (end) -->

				<div class="l-flex l-flex-w t-fieldmargin patient-details" *ngIf="(awl || openAWL) && (!ls.ishospital || ls.barcodeScan) &&  (!myPat['Status']  || myPat.Status == 'Waiting')">
					<div class="w30">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label class="w50" matTooltip="{{'GUI.Tooltips.Date of the test' | translate}}">
								{{'GUI.General.Patient Details Form.Time' | translate}}

							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1 w50">
								<input type="time" [(ngModel)]="awlTime" class="t-field-text w100"/>
							</span>
							
						</div>
						
					</div>
					<div class="w30">

						<div class="l-flex l-flex-c t-field t-field-pad" >

							<label matTooltip="{{'GUI.Tooltips.Exam type' | translate}}">
								{{'GUI.General.Patient Details Form.Exam Type' | translate}}
							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<select [(ngModel)]="awlExam" class="t-select w100">
									<option></option>
									<!-- <option *ngIf="awlExam && ! examTypes.includes(awlExam)" [value]="awlExam">{{awlExam}}</option> -->
									<ng-container *ngFor="let exam of getExamTypes()">
										<option [value]="exam">
											<!-- {{exam}} -->

											<ng-container *ngIf="languageService.isOB(exam)">
												{{'GUI.Patient History.ExamTypes.OB.'+exam | translate | titlecase}}
											</ng-container>
											<ng-container *ngIf="languageService.isGYN(exam)">
												{{'GUI.Patient History.ExamTypes.GYN.'+exam | translate | titlecase}}
											</ng-container>
										</option>
									</ng-container>
								</select>
							</span>
							
						</div>
						
					</div>
					<div class="w40" *ngIf="!ls.isGastro && !ls.isCardio">

						<div class="l-flex l-flex-c t-field t-field-pad">							
							<span class="t-validation-wrap l-fg1 w65" style="display: flex;">
								<label>
									{{'GUI.General.Patient Details Form.LMP' | translate}}
								</label>
								<input [(ngModel)]="awlLMP" (dateChange)="dateChanged('awlLMP');checklmp()" [matDatepicker]="lmppicker" [max]="maxLMP"  [min]="minLMP" class="t-field-text w100" [ngClass]="invalidlmp"/>
								<mat-datepicker #lmppicker ></mat-datepicker>
								<div class="t-validation-message">
									<span>
										{{'GUI.General.Patient Details Form.Invalid LMP' | translate}}
									</span>
								</div>
								<img src="../../assets/icons/date.svg" (click)="lmppicker.open()" style='margin-left: -1.8em;'>

							</span>
							<i class="t-sep"></i>
							
							<span class="t-validation-wrap l-fg1 w35" style="display: flex;">
								<label>
									{{'GUI.General.Patient Details Form.GA' | translate}}
								</label>
								<span style="font-weight: bolder;">{{lmpGA(this.awlLMP)}}</span>
							</span>
							
						</div>
						
					</div>
					<div class="w33" *ngIf="myPat.HMO == 'Leumit' && ls.hasLeumit">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								PRN
							</label>

							<i class="t-sep"></i>

							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.PRN" class="t-field-text w100" (keyup)="checkLength()" [ngClass]="prnerr? 'is-invalid':''"/>
								<div class="t-validation-message">
									<span>{{prnerr}}</span>
								</div>
							</span>
							
						</div>
						
					</div>
					<div class="w33" *ngIf="myPat.HMO == 'Leumit' && ls.hasLeumit">

						<div class="l-flex l-flex-c t-field t-field-pad">
							<label>
								Treatment Code
							</label>

							<i class="t-sep"></i>
							<span class="t-validation-wrap l-fg1">
								<input type="text" [(ngModel)]="myPat.Code" class="t-field-text w100" (keyup)="checkLength()" [ngClass]="treatmenterr"/>
								<div class="t-validation-message">
									<span>Invalid Treament Code</span>
								</div>
							</span>
							
						</div>
						
					</div>
					<div class="w33" *ngIf="ls.PRNcheck && myPat.HMO == 'Leumit' && ls.hasLeumit && ! PRNVerified && myPat.PRN && myPat.PRN.length > 8 && myPat.Code && myPat.Code.length > 4">
						<button class="save_patieont" (click)="checkPRN()">
							Check Validity
						</button>
					</div>

					<div class="w100">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label  matTooltip="{{'GUI.Tooltips.Comment' | translate}}">
								{{'GUI.General.Patient Details Form.Comments' | translate}}
							</label>

							<span class="t-validation-wrap is-sep l-fg1">
								<textarea [(ngModel)]="awlComment" class="t-field-text w100"></textarea>
							</span>
							
						</div>
						
					</div>

					<div class="w100" *ngIf="ls.ExamCodes">

						<div class="l-flex l-flex-c t-field t-field-pad">

							<label>
								Exam Codes
							</label>

							<span class="t-validation-wrap is-sep l-fg1" style="direction: rtl">
								<ng-container *ngFor="let e of ecodeTxt; let i = index">
									<div (dblclick)="removeCode(i)">{{e}}</div>	
								</ng-container>
								<select class="t-select w100" [(ngModel)]="ecode" (change)="addCode()" style="direction:rtl">
									<option value="0"></option>
									<option *ngFor="let opt of ls.ExamCodes" [value]="opt[0]">{{opt[1]}} ({{opt[0]}})</option>
								</select>
							</span>
							
						</div>
						
					</div>
				</div>

				

				
				<div style="display: flex; flex-direction: row;justify-content: space-between; margin-top: 1em;">
					<button class="save_patieont" (click)="saveAWL()" *ngIf="(awl || openAWL) && awlTime && (myPat.HMO != 'Leumit' || ! ls.hasLeumit || ! myPat.PRN || PRNVerified)" [ngClass]="awl ? 'lefty' : 'n'"  matTooltip="{{'GUI.Tooltips.Save to todays list' | translate}}">
						{{'GUI.Buttons.Save To TodayList' | translate}}
						{{idchanged ? ('GUI.General.Patient Details Form.Buttons.and Save Patient Details' | translate):''}}
					</button>
					<button class="save_patieont" (click)="deleteAWL()" *ngIf="awl && myPat.Status == 'Waiting'"  matTooltip="{{'GUI.Tooltips.Delete from todays list' | translate}}">
						{{'GUI.Buttons.Delete From' | translate}}
					</button>
					<div *ngIf="! myPat.PID || warning" [ngClass]="['patientDetails-warning',warning ? 'warnred':'v']">
						{{warning? warning: ('GUI.General.Patient Details Form.Warning' | translate)}}
					</div>
				</div>

			</div>
		</div>

	</div>


