import { Component, OnInit, OnDestroy,SecurityContext,ViewChild,ElementRef } from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import { PatientsService } from '../patients.service';
import { Patient } from '../patients/patient';
import { ExamsService} from '../exams.service';
import {PregnancyService} from '../pregnancy.service';
import {AutotextService} from '../autotext.service';
import { DomSanitizer,SafeHtml} from '@angular/platform-browser';
import { LoginService } from '../login.service';
import {VideoCaptureService} from '../video-capture.service';
import {VideoClientService} from '../video-client.service';
import { Router } from '@angular/router';
import {TimerService} from '../timer.service';
import { Subscription, Subject } from 'rxjs';
import {GalleryService} from '../gallery.service';
import { Location } from '@angular/common';
import { MeasgrowthService } from '../measgrowth.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-exam',
  templateUrl: './exam.component.html',
  styleUrls: ['./exam.component.scss']
})
export class ExamComponent implements OnInit, OnDestroy {
  @ViewChild('videobox') videoElement: ElementRef;
  @ViewChild('imgcanvas') imageCanvas: ElementRef;
  fetuses:number = 1;
  showSide: boolean= false;
  page:string = "Pregnancy";
  pat:Patient;
  showPat = false;
  showgallery=false;
  showcharts=false;
  showprint=false;
  showprintopts=true;
  showInbox = false;
  recording = false;
  recordLink: SafeUrl;
  CurrentSR: number;
  CurrentInbox: number;
  vidMinified: boolean;
  videoSource: string;
  audioSource: string;
  Stream: MediaStream;
  soundtest: boolean;
  audioInputs:{ value: any, text: any }[];
  videoInputs:{ value: any, text: any }[];
  videowarn = false;
  SR: SafeHtml;
  noStop = false;
  showdeleteimg = false;
  exitTimeout;
  fullscreen=false;
  ExamSubscription:Subscription;
  showlink=false;
  sub : Subscription;
  takePicSubsctiption: Subscription;
  phototaken: SafeUrl;
  showTimmer;
  messages: string;
  sendonce =false;
  showSelect = false;
  concentForm;
  inform = false;
  afterdefault: string;
  defaultWarn = false;
  public missingLMPMsg: boolean = false;
  showPage$:Subject<string> = new Subject<string>();
  show_surrogate = false;
  linkedConcent = {};
  modality: string = "";
  audioLevel: number = 0;
  public isAlertActive: boolean = false;
  public alertActiveSubscription: Subscription
  public selectedTab: string = "";
  openDocumentsAdd=false

  public shouldTranslateSub: Subscription;
  public shouldTranslate: boolean = true;

  constructor(
    private location: Location,
    private patservice: PatientsService,
    public vs: VideoCaptureService,
    private vidClient: VideoClientService,
    public ps: PregnancyService,
    public atS: AutotextService,
    private sanitizer: DomSanitizer,
    public ls: LoginService,
    private router: Router,
    public ts: TimerService,
    public gs: GalleryService,
    public es: ExamsService,
    public languageService: LanguageService,
  ) {
    this.es.loginMissing();
    this.vidMinified=false;
    this.audioInputs = [];
    this.videoInputs = [];
    this.gs.isOpen=false;
    this.ExamSubscription = this.es.examLoaded$.subscribe(async ()=>{
      
      if(! this.es.isClosed){
        this.exitTimeout = setTimeout(()=>{this.exit()}, 120 * 60 * 1000);
      }
      else{ 
        if(this.es.ExamType != "Emergency Room"){
          this.showprintopts = false;
          this.showprint = true;
        }
      }
      if(this.es.ExamType != 'Follicles' && this.es.ExamType != 'Gynecology' && this.patservice.getPat().ID != 'default' && ! this.es.isForm)
        this.ps.setExamID(this.es.examID,this.es.CreationTime);
      this.sendonce = this.es.LinkSent;
    });
   }

  async ngOnInit() {
    if((this.ls.usMode || this.ls.premissions?.usMode) && this.es.examsUsMode.includes(this.es.ExamType)){
    await this.es.fetchModality();
    if(this.es.isClosed ){
      this.modality=this.es.modality
    }
    if(!this.es.isClosed && !this.es.modality){
      this.es.saveVals('Modality','Ultrasound',true,"exams");
      this.es.modality='Ultrasound'
    }
    if(!this.es.isClosed){
      this.modality=this.es.modality
     
    }
  }

    this.getCountLinks()
    this.pat=this.patservice.getPat();
    //this.recordLink = this.sanitizer.bypassSecurityTrustUrl('ArtisanImage:Start' + this.es.examID);
    this.es.ShowSRs=false;
    
    // this.cookie=this.cookieServ.get('connect.sid');
    if(this.pat.ID == 'default')
    this.page = 'Placenta';
    if(this.es.ExamType != 'Follicles' && this.es.ExamType == 'Gynecology' || this.ls.isCardio)
    this.page = 'Introduction';
    if(this.es.ExamType == 'Emergency Room')
    this.page = null;
    if(this.es.isForm)
    this.page = 'forms';
    if(this.es.ExamType === 'Pediatric Echo'){
      this.page = 'Anamnesis';
    }
    if(this.ls.Recording == 'webrtc'  && ! this.ls.RemoteRTC && this.pat.ID != "default" && ! this.es.isForm && this.es.ExamType != 'Emergency Room' && ! this.es.isClosed){
      let inputs = await this.vs.getDevices();
      this.videoInputs = inputs.video;
      this.audioInputs = inputs.audio;
      try{
        let oldv = window.localStorage.getItem("video");
        if(oldv && this.videoInputs.find((item)=> {return item.value == oldv})){
          this.videoSource = oldv;
        }
        var olda = window.localStorage.getItem("audio");
        if(olda&& this.audioInputs.find((item)=> {return item.value == olda})){
          this.audioSource = olda;
        }
        await this.startVideo();
        if(this.ls.hasImaging && this.es.ExamType == 'Gynecology'){
          this.vs.ExamID =  this.es.examID;
          this.vs.openWebSocket();//for image capture messages
        }       
          this.vs._photoS.subscribe((photo)=>{
            this.imageCanvas.nativeElement.width = photo.width;
            this.imageCanvas.nativeElement.height = photo.height;
            this.imageCanvas.nativeElement.getContext('2d').drawImage(photo, 0, 0);
            this.imageCanvas.nativeElement.style.display = 'block';
            try{
              if(this.showTimmer)
                clearTimeout(this.showTimmer);
            }
            catch(e){
              console.log(e);
            }
            this.showTimmer  = setTimeout(()=>{
              this.imageCanvas.nativeElement.style.display = 'none';
            },3000);
          });
        }
        catch(e){
        console.log(e);
      }
    }
    if(this.ls.RemoteRTC && ! this.es.isClosed){
      this.vidClient.GotStream.subscribe(()=>{
        this.Stream = this.vidClient.Stream;
      });
      this.vidClient.ConnectServer();
    }

      this.shouldTranslateSub = this.es.shouldTranslate$.subscribe((data) => {  
        this.shouldTranslate = data;
      });
  
     this.sub = this.es.titleChange.subscribe(
       (res) => {
         this.es.changedTitle = res
         if(res){
         }
       }
     );
     
     if(this.ls.premissions?.Consent && this.es.ExamType !== 'Pediatric Echo' && this.es.ExamType !== 'Gynecology '&& this.pat.ID != 'default') {
      setTimeout(() => {
        this.es.getConcentForExam().subscribe((data)=>{
          this.linkedConcent=data
          })
      
      }, 600);
      }

  }

  ngAfterViewInit() {
    // Get access to the microphone audio stream
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        this.videoElement.nativeElement.srcObject = stream;

        // Create an audio context and analyzer
        const audioContext = new AudioContext();
        const analyzer = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);

        microphone.connect(analyzer);

        // Continuously update audio level
        const updateAudioLevel = () => {
          const dataArray = new Uint8Array(analyzer.frequencyBinCount);
          analyzer.getByteFrequencyData(dataArray);
          const average =
            dataArray.reduce((acc, val) => acc + val, 0) / dataArray.length;
          this.audioLevel = Math.floor((average / 255) * 100); // Convert to 0-100 scale
          requestAnimationFrame(updateAudioLevel);
        };

        audioContext.resume().then(() => {
          updateAudioLevel(); // Start updating audio level
        });
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.exitTimeout) {
      clearTimeout(this.exitTimeout);
    }
    if(this.ls.RemoteRTC)
      this.vidClient.StopRecording();
    else{
      this.vs.Clean();
      this.vs.closeWebSocket();
    }
   // this.es.exitExam();
   this.ExamSubscription.unsubscribe();
   if(this.sub){
     this.sub.unsubscribe();

   }
    if(this.takePicSubsctiption) {
      this.takePicSubsctiption.unsubscribe();

    }
    if (this.shouldTranslateSub) { 
      this.shouldTranslateSub.unsubscribe();
    }
  }

  setPage(pg: string){
    if(this.es.showDefaultButton()){
      this.afterdefault = pg;
      this.defaultWarn = true;
    } else if (this.es.showMissingLMPMsg(pg) && this.pat.ID !== "default") {
      this.missingLMPMsg = true;
      this.selectedTab = pg;
    } else {
      this.page = pg;
    }
  }

  noDefaults(){
    this.es.saveVals('EDefault',1,true,'exams');
    this.es.Defaults = 1;
    this.page=this.afterdefault;
    this.defaultWarn = false;
  }

  cancelDefaultQ(){
    this.defaultWarn = false;
    this.showPage$.next(this.es.ExamType == 'Gynecology'? 'Gynecology':'Pregnancy');
  }

  missingLMPContinue() {
    this.es.shouldAutoFocus = false;
    this.es.shouldAutoFocus$.next(false);
    this.missingLMPMsg = false;
    this.page = this.selectedTab;
  }
 
  backToPregnancy() {
    this.missingLMPMsg = false;
    this.es.shouldAutoFocus = true;
    this.es.shouldAutoFocus$.next(true);
    this.showPage$.next(
      this.es.ExamType === "Gynecology" ? "Gynecology" : "Pregnancy"
    );
    this.page = this.es.ExamType === "Gynecology" ? "Gynecology" : "Pregnancy";
  }
 

  startForm(){
    this.es.ps.resetPreg()
    var temp = this.es.pregnancies.filter((i) => i.AutoIndex == this.es.ps.pregID)
    if(temp.length > 0){
      // var res = this.es.getConcentForms();
      // res.subscribe((data) =>{
      //   temp = data
      // })
      temp = temp[0].forms.filter((i) => (i.ExamID == this.es.examID) || (!i.ExamID && i.ExamType == this.es.ExamType))
    } 
    if(temp.length>0)
      this.concentForm = temp[0]
    else
      this.concentForm = null;

      this.inform =true;
  }

  changeFetuses(n: number){
    this.fetuses = n;
  }

  record(){
    if(this.ls.RemoteRTC  && ! this.noStop){
      this.noStop = true;//don't allow to stop recording in less than 5 secons
      if(this.vidClient.Recording ){
        this.vidClient.StopRecording();
      }
      else if(! this.vidClient.Recording ){
        this.vidClient.StartRecording();
      }
      setTimeout(()=>{
        this.noStop = false;
      }, 5000);
    }
    else{
      if(! this.noStop){
        this.noStop = true;
        this.vs.Record(this.es.examID);
        setTimeout(()=>{
          this.noStop = false;
        }, 2000);
      }
    }
  }

  isRecording():boolean{
    if(this.ls.RemoteRTC)
      return this.vidClient.Recording;
    else
      return this.vs.recording;
  }

  openGallery(inbox:number){
    this.CurrentInbox= inbox;
    this.showgallery=true;
  }

  async startVideo(){
    this.Stream = await this.vs.startVideo(this.videoSource,this.audioSource,this.soundtest);
  } 

  loadSR(sr:number){
    this.CurrentSR = sr;
    this.es.getSR(sr).subscribe((data)=>{
      this.SR = this.sanitizer.sanitize(SecurityContext.HTML, data);
    }, (err) =>{
      console.log(err);
    })
  }

  exit(patlist = false){
    this.es.exitExam();
    if(this.ls.isCardio && this.ls.userType == 'secretary'){
      this.location.back()
    }else{
      this.router.navigateByUrl(patlist ? '/patsearch':'/exams');
    }
  }

  maximizeVid(){
    this.videoElement.nativeElement.requestFullscreen();
  }

  handleError(error) {
    console.log('navigator.MediaDevices.getUserMedia error: ', error.message, error.name);
  }

  togglefullscreen(){
    this.fullscreen = ! this.fullscreen;
  }

  deleteMedia(){
    this.gs.delete();
    this.showgallery = false;
    this.showdeleteimg = false;
    setTimeout(()=> {this.showgallery = true}, 100);
  }

  getDisk(){
    if(this.messages == 'Creating Disk ...')
      return;
    this.messages = "Creating Disk ...";
    this.es.getDisk().subscribe((data)=>{
      this.messages = "Downloading disk";
      setTimeout(()=>{
        this.messages = 'When buring disk or onkey ignore the "Not Responding" message';
        setTimeout(()=>{
          this.messages = null; 
        },10000);
      },2000);
      var element = document.createElement('a');
      element.setAttribute('href','/api/readydisk/' + data['disknum'] + '/' + data['disk']);
      element.setAttribute('download', data['disk']);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      //window.location.href = '/api/readydisk/' + data['disknum'] + '/' + data['disk'];
    },(err)=>{
      this.messages = 'Disk Creation failed';
      setTimeout(()=>{
        this.messages = null;
      },3000);
    });
  }

  closeConcent(event){
    console.log(event);
    this.inform=false;
  }

  getCountLinks(){
   this.ls.countLinks();
  }


  
  checkApprovedConcent(consent: any) {
    let valid = false;
    if (
      consent.approved &&
      consent.approved !== "appotropus" &&
      consent.patSign &&
      consent.patSignApproved &&
      consent.docSign
    ) {
      valid = true;
    } else if (
      consent.approved &&
      consent.approved === "appotropus" &&
      consent.patSign &&
      consent.docSign
    ) {
      valid = true;
    }
    return valid;
  }

  
  async changeModality(){
    if(this.es.isClosed) return
    this.es.saveVals('Modality',this.modality,true,"exams");
    this.es.modality=this.modality;
   }
}
