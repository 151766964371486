<!-- ===================================== POPUP : PREGNANCY CLOSE (STARTS) =============================================== -->

<div class="t-popup popup-pregnancyClose" [formGroup]="ps.pregForm">

    <div class="t-popup-close"></div>
    
    <div class="t-popup-frame">
        <div class="t-popup-body">
                <button _ngcontent-enj-c5="" aria-label="close popup" class="t-btn-popup-close" (click)="closed.emit()"></button>
            <h2>
                <!-- Pregnancy Outcome Descriptions -->
                {{'GUI.ClosePregnancy.Pregnancy Outcome Description' | translate | titlecase}}
            </h2>
            <div class="l-flex-w pregnancy-popup-fieldset">

                <label class="t-label-top">
                    <span>
                        <!-- Date of delivery -->
                        {{'GUI.ClosePregnancy.Date of delivery' | translate}}
                    </span>
                    <input [matDatepicker]="termDatepicker" readonly class="t-field t-field--date" formControlName="TerminationDate" (focus)="termDatepicker.open()" (click)="termDatepicker.open()" [max]="closeMax()" [min]="this.ps.getLMP()"/>
                    <mat-datepicker #termDatepicker></mat-datepicker>
                </label>

                <label class="t-label-top">
                    <span>
                        <!-- Duration in weeks -->
                        {{'GUI.ClosePregnancy.Duration in weeks' | translate}}
                    </span>
                    <span *ngIf="ps.pregForm.get('TerminationDate').value">{{ps.getTermWeeks()}}</span>
                </label>

                <label class="t-label-top">
                    <span>
                        <!-- Outcome -->
                        {{'GUI.ClosePregnancy.Outcome' | translate}}
                    </span>
                    <select class="t-field t-select" formControlName="TerminationType">
                        <option>
                            <!-- Choose -->
                        {{'GUI.ClosePregnancy.Choose' | translate}}
                        </option>
                        <option value="Birth">
                            <!-- Birth -->
                            {{'GUI.ClosePregnancy.Birth' | translate}}
                        </option>
                        <option value="Miscarriage">
                            <!-- Miscarriage -->
                            {{'GUI.ClosePregnancy.Miscarriage' | translate}}
                        </option>
                        <option value="Abortion">
                            <!-- Abortion -->
                            {{'GUI.ClosePregnancy.Abortion' | translate}}
                        </option>
                    </select>
                </label>

                <label class="t-label-top">
                    <span>
                        <!-- Number of Fetuses -->
                         {{'GUI.ClosePregnancy.Number of Fetuses' | translate}}
                    </span>
                    <select class="t-field t-select" formControlName="NumFetuses">
                        <option [value]="1">1</option>
                        <option [value]="2">2</option>
                        <option [value]="3">3</option>
                        <option [value]="4">4</option>
                    </select>
                </label>
                
            </div>

            <div class="m-box pregnancy-fetuses-table-hold">

                <div class="l-table-auto pregnancy-fetuses-table">
                    
                    <!-- row : table head -->
                    <div class="l-row l-thead">
                        <div class="l-td-m">
                            <!-- Fetuses -->
                            {{'GUI.ClosePregnancy.Fetuses' | translate}}
                        </div>
                        <label class="l-td-m" id="pregnancy_popup-sex">
                            <!-- Sex of fetus -->
                            {{'GUI.ClosePregnancy.Sex of fetus' | translate}}
                        </label>
                        <label class="l-td-m" id="pregnancy_popup-weight">
                            <!-- Weight -->
                            {{'GUI.ClosePregnancy.Weight' | translate}}
                        </label>
                        <label class="l-td-m" id="pregnancy_popup-anomales">
                            <!-- Anomalies -->
                            {{'GUI.ClosePregnancy.Anomalies' | translate}}
                        </label>
                    </div>
                    <!-- endrow : table head -->

                    <!-- row -->
                    <div class="l-row" *ngFor="let i of ps.getFetusCounter()">
                        <div class="l-td-m pregnancy-fetuses-title">
                            <i class="icon-fetus-blue"></i>
                            <!-- Fetus  -->
                            {{'GUI.ClosePregnancy.Fetus' | translate}}
                            {{i}}
                        </div>
                        <div class="l-td-m" (dblclick)="ps.pregForm.get('Fetus'+i+'Sex').setValue(null)">
                            <div class="t-field l-flex t-twin-fieldset">
                                <label>
                                    <input type="radio" class="custom-check customInput" aria-labelledby="pregnancy_popup-sex" [name]="'Fetus'+i+'Sex'" [formControlName]="'Fetus'+i+'Sex'" value="Male">
                                    <i class="customInput-display"></i>
                                    <span class="t-twin-icon icon-male" aria-label="male"></span>
                                </label>
                                <label>
                                    <input type="radio" class="custom-check customInput" aria-labelledby="pregnancy_popup-sex" [name]="'Fetus'+i+'Sex'" [formControlName]="'Fetus'+i+'Sex'" value="Female">
                                    <i class="customInput-display"></i>
                                    <span class="t-twin-icon icon-female" aria-label="female"></span>
                                </label>
                            </div>
                        </div>
                        <div class="l-td-m">
                            <span class="t-field t-field--number">
                                <input class="t-field-text" type="number" min="0" aria-labelledby="pregnancy_popup-weight" [formControlName]="'Fetus'+i+'Wt'">
                                <span class="t-field-desc">
                                    g
                                </span>
                            </span>
                        </div>
                        <div class="l-td-m" (dblclick)="ps.pregForm.get('Fetus'+i+'Anomolies').setValue(null)">
                            <div class="t-field l-flex t-twin-fieldset">
                                <label>
                                    <input type="radio" [name]="'Fetus'+i+'Anomolies'" class="custom-check customInput" aria-labelledby="pregnancy_popup-anomales" [formControlName]="'Fetus'+i+'Anomolies'" [value]="0">
                                    <i class="customInput-display"></i>
                                    <span>
                                        <!-- No -->
                                        {{'general.PositiveNegative.No' | translate}}
                                    </span>
                                </label>
                                <label>
                                    <input type="radio" [name]="'Fetus'+i+'Anomolies'" class="custom-check customInput" aria-labelledby="pregnancy_popup-anomales" [formControlName]="'Fetus'+i+'Anomolies'" [value]="1">
                                    <i class="customInput-display"></i>
                                    <span>
                                        <!-- Yes -->
                                        {{'general.PositiveNegative.yes' | translate}}
                                    </span>
                                </label>
                            </div>
                        </div>							
                    </div>
                    <!-- endrow -->

                    
                </div>
                <!-- table (end) -->
            </div>
            <!-- box (end) -->

            <div class="m-box pregnancy-popup-moreDetails">
                <h2>
                    <!-- More details -->
                    {{'GUI.ClosePregnancy.More details' | translate}}
                </h2>
                <textarea class="scroller fulltext" style="height: 100%" formControlName="TerminationRemarks"></textarea>
            </div>

            <button class="close_pregnancy" (click)="closePreg()" *ngIf="!isclosed &&! ps.pregForm.get('ClosedDate').value">
                <!-- Close Pregnancy -->
            {{'GUI.General.Buttons.Close Pregnancy' | translate | titlecase}}
            </button>

        </div>
    </div>

</div>

<!-- ===================================== POPUP : PREGNANCY CLOSE (END)  =============================================== -->
