<!-- ==================================== POPUP : GRAPHS (STARTS) ============================================== -->

<!-- <div class="t-popup popup-graphs" style='display: block;height:100%'> -->
<div [class]="this.examservice.isForm && ! pdfToShow ? 't-popup popup-graphs form':'t-popup popup-graphs'"
    style='display: block;height:100%'>

    <div class="popup-close"></div>
    <div class="popup-frame">
        <div class="popup-body">
            <h2>{{ this.examservice.isForm ? ('GUI.PrintOpts.Select Language' | translate) : ('GUI.PrintOpts.Report' | translate)}}</h2>
            <div class="graphs-container">
                <div class="l-flex-c t-set" *ngIf="ShowOptions">
                    <div class="t-set-title" *ngIf="!this.examservice.isForm">
                        <div class="l-flex-c">
                            <label *ngIf="! login.isCardio && !login.isGastro">
                                <input type="checkbox" matTooltip="Change The existing Title"
                                    name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    [(ngModel)]="changeTitle" />
                                <i class="customInput-display"></i>
                                <span>
                                    <!-- Change Title -->
                                    {{'GUI.PrintOpts.Change Title' | translate}}
                                </span>
                            </label>
                            <label>
                                <input type="checkbox" name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    [(ngModel)]="pdf.printCharts" />
                                <i class="customInput-display"></i>
                                <span>
                                    <!-- Print Charts -->
                                    {{'GUI.PrintOpts.Print Charts' | translate}}
                                </span>
                            </label>
                            <label>
                                <input type="checkbox" name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    (click)="showGallery=true" [(ngModel)]="pdf.printImages" />
                                <i class="customInput-display"></i>
                                <span>
                                    <!-- Print selected Images -->
                                    {{'GUI.PrintOpts.Print Selected Images' | translate}}
                                </span>
                            </label>
                            <label *ngIf="pdf.printImages">
                                &nbsp;
                                <select [(ngModel)]="pdf.perPage" class="t-select t-field menstural-field">
                                    <option value="2" *ngIf="login.ishospital != 'belinson'">2</option>
                                    <option value="6">6</option>
                                </select>
                                <span>
                                    <!-- Per Page -->
                                    {{'GUI.PrintOpts.Per Page' | translate}}
                                </span>
                            </label>
                            <label>
                                <input type="checkbox" name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    [(ngModel)]="pdf.newTab" />
                                <i class="customInput-display"></i>
                                <span>
                                    <!-- Open in new tab -->
                                    {{'GUI.PrintOpts.Open In New Tab' | translate}}
                                </span>
                            </label>
                            <label *ngIf="login.letterhead">
                                <input type="checkbox" name="gestation_TEMP_name_0" class="custom-check -sq" value="y"
                                    [(ngModel)]="pdf.letterhead" />
                                <i class="customInput-display"></i>
                                <span>
                                    Use preprinted paper
                                </span>
                            </label>

                        </div>
                    </div>
                    <div *ngIf="!login.printLang">
                        <label for="select_fetus" class="t-select-label" style='margin-left: 1em;'>
                            <!-- Language -->
                            {{'GUI.PrintOpts.Language'  | translate}}
                        </label>
                        <select id="select_fetus" class="t-field t-select" [(ngModel)]="pdf.Lang">
                            <option value='en-US'>English</option>
                            <option value='he-IL'>עברית</option>
                            <option *ngIf="login.premissions?.Polish" value='pl-PL'>Polish</option>
                            <!--<option value='ar-JO'>عربى</option>
                                    <option value='it-IT'>Italiano</option>
                                    <option value='es-ES'>Español</option>-->
                        </select>
                    </div>
                    <div *ngIf="! login.printStyle">
                        <label for="select_fetus" class="t-select-label" style='margin-left: 1em;'>
                            <!-- Print Style -->
                            {{'GUI.PrintOpts.Print Style' | translate}}
                        </label>
                        <select id="select_fetus" class="t-field t-select" [(ngModel)]="pdf.Style">
                            <option value='simple'>
                                <!-- Plain -->
                                {{'GUI.PrintOpts.Plain' | translate}}
                            </option>
                            <option value='fancy'>
                                <!-- Decorated -->
                                {{'GUI.PrintOpts.Decorated' | translate}}
                            </option>
                        </select>
                    </div>
                    <button class="search-btn" (click)='getPDF()' style='width: 4em; margin-left: 1em'
                        *ngIf="showGo">
                        <!-- Go -->
                        {{'GUI.PrintOpts.Print' | translate}}
                    </button>
                    <button class="search-btn" (click)="resendChameleon()"
                        style="width: 12em; position: absolute;right: 0" *ngIf="chameleon">Resend to Chameleon</button>
                </div>
                <button class="search-btn" (click)="resendChameleon()"
                    style="width: 12em;margin-left: auto;margin-right: 2em;margin-top: -2.5em;"
                    *ngIf="login.hasChameleon && chameleon && ! ShowOptions && examservice.PregCopied">Resend to
                    {{login.altArchiveName ? login.altArchiveName : 'Chameleon'}}</button>
                <div *ngIf="(changeTitle && (newTitle != '1st & NT' && newTitle !='Early & NT')) && ! pdfToShow && ! login.isCardio && !login.isGastro && examservice.ExamType!=='Consultation Letter'" style="width:fit-content;margin-top: 1em;">
                    <span class="t-menu">
                        <!-- Edit Exam Title to: -->
                        {{'GUI.PrintOpts.Edit Exam Title To' | translate}}
                        <select class="t-select color" #Title [(ngModel)]="newTitle"
                            [ngClass]="newTitle? 'is-invalid':''" [disabled]="examservice.isClosed">
                            <option [ngValue]="" disabled>
                                <!-- Please select a title -->
                                {{'GUI.PrintOpts.Please select a title' | translate}}
                            </option>
                            <option value="Early">Early Anatomy</option>
                            <option value="2nd/3rd">2nd/3rd Trimester</option>
                            <option value="Weight Assessment">Weight Assessment</option>
                            <option value="Late">Late Anatomy</option>
                            <option value="Third Scan">Third Scan</option>
                            <option value="Specialized">Specialized</option>
                            <option value="Fetal Echo">Fetal Echo</option>
                            <option *ngFor="let c of examservice.CustomTitles" [value]="c.Title">{{c.Title}}</option>
                        </select></span>
                </div>
                <app-gallery *ngIf="pdf.printImages && showGallery && ShowOptions" style="width:100%" [forprint]="true">
                </app-gallery>
                <embed *ngIf="!isPDFLoading && pdfToShow != null" [src]="pdfToShow" type="application/pdf"
                    style='width:95vw;height:80vh;z-index:1'>
                <img *ngIf="isPDFLoading" src="../../assets/images/Wedges.svg"
                    style='z-index:0;margin-left: auto;margin-right:auto;position: absolute;left: 0; right: 0;top: 40vh'>
            </div>
            <button type="button" class="popup-close-button" (click)="closed.emit()" [title]="'GUI.PrintOpts.Close Printout' | translate"></button>
        </div>
    </div>

</div>

<!-- ===================================== POPUP : GRAPHS (END)  =============================================== -->