<div class="t-popup popup-graphs" style='display: block;height:100%;'>

    <div class="popup-close"></div>
    
    <div class="popup-frame">
       
        <div class="patient-ob-box popup-body">
            
            <div class="h100 m-box" *ngIf="! isReports">
                <h2>Exam Search</h2>
                <div>
                    <strong style="margin-right: 0.5em;">
                        From:
                    </strong>
                    <input [matDatepicker]="startDatepicker" [(ngModel)]="startdate" [min]="searchmin"
                        class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP" (click)="startDatepicker.open()"
                        style="margin-right: 1em;">
                    <mat-datepicker #startDatepicker></mat-datepicker>
                    <strong style="margin-right: 0.5em;">
                        Until:
                    </strong>
                    <input [matDatepicker]="endDatepicker" [(ngModel)]="enddate" [max]="searchmax" (click)="endDatepicker.open()"
                        class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP" style="margin-right: 1em;">
                    <mat-datepicker #endDatepicker></mat-datepicker>
                </div>
                <div class="h100 l-flex l-column">

                    <div class="l-fg1">

                        <div class="">
                            
                            <!-- new exam top (start) -->
                            <div class="l-flex-c l-flex-w l-justify h50 new_exam-head">
                                
                                <label class="t-label-top">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="All" (click)="setVals('All')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        All
                                    </span>
                                </label>

                                <label class="t-label-top" *ngIf="! isGastro && !ls.isCardio">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="OB" (click)="setVals('OB')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        OB
                                    </span>
                                </label>
                                
                                <label class="t-label-top" *ngIf="! isGastro && !ls.isCardio">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="OB Fetal Echo" disabled style="display: none"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        OB Fetal Echo
                                    </span>
                                </label>
                                <label class="t-label-top" *ngIf="ls.hasSurrogatePreg">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="Travel Report" style="display: none"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        Download Travel Report
                                    </span>
                                </label>
                                
                                <label class="t-label-top" *ngIf="! isGastro && !ls.isCardio">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="GYN" (click)="setVals('GYN')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        GYN
                                    </span>
                                </label>
                                <label class="t-label-top" *ngIf="isGastro">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="Gastro" (click)="setVals('Gastro')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        Gastro
                                    </span>
                                </label>
                                
                                <label class="t-label-top" *ngIf="ls.isCardio">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="Echo" (click)="setVals('Echo')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        Echo
                                    </span>
                                </label>
                                <label class="t-label-top" *ngIf="ls.isCardio">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="ECG" (click)="setVals('ECG')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        ECG
                                    </span>
                                </label>
                                <label class="t-label-top" *ngIf="ls.ishospital">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="etype" class="custom-check" value="Invasive" (click)="setVals('Invasive')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        Invasive
                                    </span>
                                </label>
                                
                            </div>	
                            <!-- new exam top (end) -->
                            <div class="linked-radios-list linked-radios-set">
                           
                                <ng-container *ngIf="etype == 'OB'">
                                
                                   
                                <label class="linked-radios-item" (click)="setE($event,'1st')">
                                    <input type="checkbox" name="1st" [checked]="exams['1st']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">1st</span>
                                        <span class="linked-radios-duration"> 1-9 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>
                                
                                <label class="linked-radios-item" (click)="setE($event,'NT')">
                                    <input type="checkbox" name="NT" [checked]="exams['NT']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">NT</span>
                                        <span class="linked-radios-duration"> 12-14 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>
                                
                                <label class="linked-radios-item" (click)="setE($event,'Early')">
                                    <input type="checkbox" name="Early" [checked]="exams['Early']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">Early Anatomy</span>
                                        <span class="linked-radios-duration"> 14-16 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>

                                <label class="linked-radios-item" (click)="setE($event,'2nd/3rd')">
                                    <input type="checkbox" name="2nd/3rd" [checked]="exams['2nd/3rd']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">2nd/3rd Trimester</span>
                                        <span class="linked-radios-duration">12-40 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>

                                <label class="linked-radios-item" (click)="setE($event,'Weight Assessment')">
                                    <input type="checkbox" name="Weight Assessment" [checked]="exams['Weight Assessment']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">Weight Assessment</span>
                                        <span class="linked-radios-duration">12-40 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>
                                <label class="linked-radios-item" *ngIf="ls.hastech && ls.ishospital" (click)="setE($event,'Tech Exam')">
                                    <input type="checkbox" name="Tech Exam" [checked]="exams['Tech Exam']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">בדיקת טכנאי</span>
                                        <span class="linked-radios-duration">12-40 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>
                                
                                <label class="linked-radios-item" (click)="setE($event,'Late')">
                                    <input type="checkbox" name="Late" [checked]="exams['Late']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">Late Anatomy</span>
                                        <span class="linked-radios-duration"> 21-24 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>
                                
                                                                    
                                <label class="linked-radios-item" (click)="setE($event,'Third Scan')">
                                    <input type="checkbox" name="Specialized" [checked]="exams['Third Scan']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">Third Scan</span>
                                        <span class="linked-radios-duration">28-33 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>
                                
                                <label class="linked-radios-item" (click)="setE($event,'Specialized')">
                                    <input type="checkbox" name="Specialized" [checked]="exams['Specialized']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">Specialized</span>
                                        <span class="linked-radios-duration">12-40 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>


                                <label class="linked-radios-item" (click)="setE($event,'Fetal Echo')">
                                    <input type="checkbox" name="Fetal Echo" [checked]="exams['Fetal Echo']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">Fetal Echo</span>
                                        <span class="linked-radios-duration">12-40 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>
                                <label class="linked-radios-item" (click)="setE($event,'Emergency Room')" *ngIf="ls.hasEmergencyRoom">
                                    <input type="checkbox" name="Emergency Room" [checked]="exams['Emergency Room']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">Emergency Room</span>
                                        <span class="linked-radios-duration">12-40 Weeks</span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>
                                </ng-container>

                                <ng-container *ngIf="etype == 'Gastro'">
                                    <label class="linked-radios-item" (click)="setE($event,'Gastroscopy')">
                                        <input type="checkbox" name="Gastroscopy" [checked]="exams['Gastroscopy']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Gastroscopy</span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Colonoscopy')">
                                        <input type="checkbox" name="Colonoscopy" [checked]="exams['Colonoscopy']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Colonoscopy</span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Sigmoidoscopy')">
                                        <input type="checkbox" name="Sigmoidoscopy" [checked]="exams['Sigmoidoscopy']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Sigmoidoscopy</span>
                                        </span>
                                    </label>
                                </ng-container>
                                <label class="linked-radios-item" *ngIf="etype == 'GYN'" (click)="setE($event,'Gynecology')">
                                    <input type="checkbox" name="Gynecology" [checked]="exams['Gynecology']" />
                                    <span class="linked-radios-row">
                                        <span class="l-fg1 linked-radios-title">Gynecology</span>
                                        <span class="linked-radios-duration"></span>
                                        <span class="linked-radios-counter"><i>1</i></span>
                                    </span>
                                </label>
                                <ng-container *ngIf="etype == 'Echo'">
                                    <label class="linked-radios-item" (click)="setE($event,'TTE Adults')">
                                        <input type="checkbox" name="TTE Adults" [checked]="exams['TTE Adults']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">TTE Adults</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Stress-Echo')">
                                        <input type="checkbox" name="Stress-Echo" [checked]="exams['Stress-Echo']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Stress-Echo</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Duplex Carotid')">
                                        <input type="checkbox" name="Duplex Carotid" [checked]="exams['Duplex Carotid']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Duplex Carotid</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Stress')">
                                        <input type="checkbox" name="Stress" [checked]="exams['Stress']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Stress</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                </ng-container>
                                <ng-container *ngIf="etype == 'ECG'">
                                    <label class="linked-radios-item" (click)="setE($event,'ECG')">
                                        <input type="checkbox" name="ECG" [checked]="exams['ECG']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">ECG</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Ergometry')">
                                        <input type="checkbox" name="Ergometry" [checked]="exams['Ergometry']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Ergometry</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                </ng-container>

                                <ng-container *ngIf="etype == 'Invasive'">
                                    <label class="linked-radios-item" (click)="setE($event,'Amniocentesis')">
                                        <input type="checkbox" name="Amniocentesis" [checked]="exams['Amniocentesis']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Amniocentesis</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'CVS')">
                                        <input type="checkbox" name="CVS" [checked]="exams['CVS']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">CVS</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Follicles')">
                                        <input type="checkbox" name="Follicles" [checked]="exams['Follicles']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Follicles</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                </ng-container>
                                
                                
                            </div>

                        </div>

                    </div>

                    <div class="l-flex l-flex-w l-just-center patient-ob-btns-row">

                        <a class="btn-primary -exit" (click)="closed.emit(null)" style='padding-top: 1em;'>
                            Cancel
                        </a>
                        <button class="btn-primary -search"  (click)="search()" *ngIf="cansearch">
                            Search
                        </button>
                    </div>
                    
                </div>

            </div>
            <!-- -----------------------------------------------------------------------------------------reports statistic for leumit------------------------------------------- -->
            <div class="h100 m-box" *ngIf="isReports">

                <h2>Report Search</h2>
                From  <input [matDatepicker]="startDatepicker"  [(ngModel)]="startdate"  class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP" (click)="startDatepicker.open()">
                <mat-datepicker #startDatepicker></mat-datepicker>
                Until
                <input [matDatepicker]="endDatepicker"  [(ngModel)]="enddate" (click)="endDatepicker.open()" class="t-field t-field--date menstural-field" aria-labelledby="menstural_LMP">
                <mat-datepicker #endDatepicker></mat-datepicker>
                <div class="h100 l-flex l-column">
                    <div class="l-fg1">
                        <div class="">
                              <div class="l-flex-c l-flex-w l-justify h50 new_exam-head">
                                <label class="t-label-top">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="reportstype" class="custom-check" value="statisticsForLeumit" (click)="setValuesForLeumitReports('statisticsForLeumit')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                        Report
                                    </span>
                                </label>
                                <label class="t-label-top">
                                    <input type="radio" name="TEMP_name" [(ngModel)]="reportstype" class="custom-check" value="missingPrn" (click)="setValuesForLeumitReports('missingPrn')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                      List of missing PRNs
                                    </span>
                                </label>
                            
                                <label class="t-label-top" >
                                    <input type="radio" name="TEMP_name" [(ngModel)]="reportstype" class="custom-check" value="sentToLeumit" (click)="setValuesForLeumitReports('sentToLeumit')"/>
                                    <i class="customInput-display"></i>
                                    <span>
                                       List of sent to Leumit
                                    </span>
                                </label>

                            </div>
                            <!-- <div class="linked-radios-list linked-radios-set" *ngIf="false">
                           
                                <ng-container *ngIf="reportstype == 'HMO'">
                                    <label class="linked-radios-item" (click)="setE($event,'TTE Adults')">
                                        <input type="checkbox" name="TTE Adults" [checked]="exams['TTE Adults']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">TTE Adults</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Stress-Echo')">
                                        <input type="checkbox" name="Stress-Echo" [checked]="exams['Stress-Echo']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Stress-Echo</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Duplex Carotid')">
                                        <input type="checkbox" name="Duplex Carotid" [checked]="exams['Duplex Carotid']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Duplex Carotid</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Stress')">
                                        <input type="checkbox" name="Stress" [checked]="exams['Stress']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Stress</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                </ng-container>
                                 <ng-container *ngIf="etype == 'ECG'">
                                    <label class="linked-radios-item" (click)="setE($event,'ECG')">
                                        <input type="checkbox" name="ECG" [checked]="exams['ECG']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">ECG</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                    <label class="linked-radios-item" (click)="setE($event,'Ergometry')">
                                        <input type="checkbox" name="Ergometry" [checked]="exams['Ergometry']" />
                                        <span class="linked-radios-row">
                                            <span class="l-fg1 linked-radios-title">Ergometry</span>
                                            <span class="linked-radios-duration"></span>
                                            <span class="linked-radios-counter"><i>1</i></span>
                                        </span>
                                    </label>
                                </ng-container> -->
                                
                                
                            <!-- </div> --> 

                        </div>
                    </div>
                    <div class="l-flex l-flex-w l-just-center patient-ob-btns-row">

                        <a class="patient-ob-btn -exit" (click)="closed.emit(null)" style='padding-top: 1em;'>
                            Cancel
                        </a>
                        <button class="patient-ob-btn -search"  (click)="searchLeumitReports()" *ngIf="cansearch">
                            Search
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
