import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExamComponent } from './exam/exam.component';
import { LoginComponent } from './login/login.component';
import { PatientsComponent } from './patients/patients.component';
import {PatexamsComponent} from './patexams/patexams.component';
import { PrintComponent } from './print/print.component';
import {RemotevideoComponent} from './remotevideo/remotevideo.component';
import { CardioPrintComponent } from './cardio/cardio-print/cardio-print.component';
import { GastroPrintComponent } from './Gastro/gastro-print/gastro-print.component';
import { GsprintFormComponent } from './Gastro/gsprint-form/gsprint-form.component';
import { CustomheaderComponent } from './customheader/customheader.component';
import { StatsComponent } from './stats/stats.component';
import { PrintstatsComponent } from './Gastro/printstats/printstats.component';
import { SearchtextComponent } from './searchtext/searchtext.component';
import { PrintreportComponent } from './printreport/printreport.component';
import { PrintcalendarComponent } from './printcalendar/printcalendar.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { AdminLeumitReportComponent } from './admin-leumit-report/admin-leumit-report.component';
import { MediaTransferComponent } from './media-transfer/media-transfer.component';
import { InboxTransferComponent } from './inbox-transfer/inbox-transfer.component';
import { PrintConsentComponent } from './print-consent/print-consent.component'
import { TransferHistoryComponent } from './transfer-history/transfer-history.component';
import { NavComponent } from './nav/nav.component';
import { PatientRedirectComponent } from './patient-redirect/patient-redirect.component';

const routes: Routes = [
  { path: 'exam', component: ExamComponent },
  {path: 'patsearch', component: PatientsComponent},
  {path: 'patsearch/:code', component: PatientRedirectComponent},
  {path: 'patsearch/:code/:pid', component: PatientRedirectComponent},
  {path: 'nav', component: NavComponent},
  {path: 'exams', component: PatexamsComponent },
  {path: 'defaults', component: PatexamsComponent },
  {path: 'Custom', component: CustomheaderComponent },
  {path: 'Stats', component: StatsComponent },
  {path: 'Searchdata', component: SearchtextComponent },
  {path: 'advanced-search', component: AdvancedSearchComponent},
  {path: 'admin-Leumit-Report',component:AdminLeumitReportComponent},
  { path: "media-transfer", component: MediaTransferComponent },
  { path: "inbox-transfer", component: InboxTransferComponent },
  { path: "transfer-history/:type", component: TransferHistoryComponent },


  {path: '', component: LoginComponent},
//----------------------------Gastro Exam Print Start--------------------------------------------------------------------------------//
  {path: 'gastroprint/:examid/:PID/:LANG/:charts/:images/:style', component: GastroPrintComponent},
  {path: 'gastroprint/:examid/:PID/:LANG/:charts/:images/:style/:perpage', component: GastroPrintComponent},
  {path: 'gastroprint/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code', component: GastroPrintComponent},
  {path: 'gastroprint/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code/:showheader', component: GastroPrintComponent},


  {path: 'statprint/:LANG/:style/:return/:type/:dates/:start/:end/:searchByType', component: PrintstatsComponent},
  {path: 'statprint/:LANG/:style/:return/:type/:dates/:start/:end/:searchByType/:code', component: PrintstatsComponent},
  
  {path: 'reportprint/:isReport/:type/:start/:end/:hmo', component: PrintreportComponent},
  {path: 'reportprint/:isReport/:type/:start/:end/:hmo/:code', component: PrintreportComponent},

  {path: 'printCalendar/:pregID', component: PrintcalendarComponent},
  {path: 'printCalendar/:pregID/:code', component: PrintcalendarComponent},

  {path: 'printConsent/:AutoIndex', component: PrintConsentComponent},



//----------------------------Gastro Exam Print End--------------------------------------------------------------------------------//

//----------------------------Gastro Form Print Start--------------------------------------------------------------------------------//
{path: 'gastroprintform/:examid/:PID/:LANG/:charts/:images/:style', component: GsprintFormComponent},
{path: 'gastroprintform/:examid/:PID/:LANG/:charts/:images/:style/:perpage', component: GsprintFormComponent},
{path: 'gastroprintform/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code', component: GsprintFormComponent},
{path: 'gastroprintform/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code/:showheader', component: GsprintFormComponent},
{path: 'gastroprintform/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code/:showheader/:gsform', component: GsprintFormComponent},

//----------------------------Gastro Form Print End--------------------------------------------------------------------------------//

  {path: 'cardioprint/:examid/:PID/:LANG/:charts/:images/:style', component: CardioPrintComponent},
  {path: 'cardioprint/:examid/:PID/:LANG/:charts/:images/:style/:perpage', component: CardioPrintComponent},
  {path: 'cardioprint/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code', component: CardioPrintComponent},
  {path: 'cardioprint/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code/:showheader', component: CardioPrintComponent},
  {path: 'cardioprint/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code/:showheader', component: CardioPrintComponent},

  
  {path: 'print/:examid/:PID/:LANG/:charts/:images/:style', component: PrintComponent},
  {path: 'print/:examid/:PID/:LANG/:charts/:images/:style:/perpage', component: PrintComponent},
  {path: 'print/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code', component: PrintComponent},
  {path: 'print/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code/:showheader', component: PrintComponent},
  {path: 'print/:examid/:PID/:LANG/:charts/:images/:style/:perpage/:code/:showheader', component: PrintComponent},
  

  {path: 'videoserver', component: RemotevideoComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
