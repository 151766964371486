<!-- ==================================== POPUP : GRAPHS (STARTS) ============================================== -->

<div class="t-popup popup-auto-text" style='display: block;'>

    <div class="popup-close" ></div>
    
    <div class="popup-frame">
        <div class="popup-body">
            <h2>
                <!-- Select Auto Text for -->
                {{'GUI.Auto Text.Select Auto Text for' | translate}} 
                
                {{atS.Title}}</h2>
            <textarea [(ngModel)]="Text"></textarea>
            <div style="width: fit-content;" *ngIf="ls.isCardio">
                <app-genradio formname="type" [formgroup]="FilterBy" [value1]="0" [value2]="1" lname="Clinic" rname="User" [resetval]="-1"></app-genradio>
            </div>
            <div class="auto-text-list">
               
                <app-autotext-row [id]="null" text="" (updated)="reload()"  (add)="addText($event)" *ngIf="ls.isAdmin || (!ls.isAdmin && atS.filter)"></app-autotext-row>
                <ng-container *ngFor="let a of rowData">
                    <app-autotext-row [id]="a.ID" [text]="a.Text" (updated)="reload()" (add)="addText($event)"></app-autotext-row>
                </ng-container>
        
            </div>

            <button type="button" class="auto-text-submit-button" (click)="save()">
                <!-- Update & Close -->
                {{'GUI.Auto Text.Update & Close' | translate}}
            
            </button>
            <button type="button" class="popup-close-button" (click)="atS.close()">Close Auto Text Popup</button>
        </div>
    </div>

</div>

<!-- ===================================== POPUP : GRAPHS (END)  =============================================== -->	

