import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class CommerrorService {
  Error: string;
  ErrorType: string;
  logError = false;
  ecnext$ = new Subject<any>();

  saveCalendar$ = new Subject<any>();
  saveConcent$ = new Subject<any>();
  calendarForm = false;
  concentForm = false;
  alreadyHasExam = false;
  sessionExpiration: boolean = false;

  isCardio = false;
  save = false;

  constructor(
    private translate: TranslateService,
  ) {
    this.ErrorType = "";
  }

  handleCommError(error: HttpErrorResponse) {
    this.calendarForm = null;
    console.log(error);
    this.logError = false;
    if (error.url.indexOf("http://localhost:8088/alive") > -1)
      //local server doesn't exist
      return;
    if (error.error instanceof ErrorEvent) {
      this.ErrorType = "Network";
      this.Error =
        "Network Error: Please check your network connection and try again.";
    } else {
      if (error.status == 401 && error.error["error"] == "missing user or pw")
        return; //don't display error
      if (error.status == 409)
        //on add new user id already exists
        return;

      if (error.status === 200) {
        return
      }
      if (error.status === 400) {
        return
      }

      if(error.status === 401 && error.error["error"] === "need to login"){
        this.ErrorType = "Session";
        // this.Error = "Your session has expired. Please login.";
        this.Error = this.translate.instant("GUI.errorMessages.session.msg");
      } else {
        if (error.status == 0) {
          this.ErrorType = "Can not reach server";
          this.Error = "Check network connectivity (last action not saved)";
        } else {
          this.ErrorType = "Server";
          this.Error = error.status + ": " + JSON.stringify(error);
          if (error["error"] && error.error["error"]) {
            if (typeof error.error["error"] == "string") {
              this.Error =
                error.status + ": " + JSON.stringify(error.error["error"]);
            } else {
              this.Error =
                error.status + ": " + JSON.stringify(error.error["error"]);
            }
          }
        }
      }
    }
  }

  error(data, loginerror = false) {
    this.calendarForm = null;
    this.alreadyHasExam = false;
    this.ErrorType = data;
    this.logError = loginerror;
  }

  errorCardio(data, loginerror = false) {
    this.calendarForm = null;
    this.alreadyHasExam = false;
    this.ErrorType = data;
    this.logError = loginerror;
    this.isCardio = true;
  }

  hasExam(data, loginerror = false) {
    this.isCardio = false;
    this.calendarForm = null;
    this.alreadyHasExam = true;
    this.ErrorType = data;
    this.logError = loginerror;
    // this.Error = "Do you really want to create a new one?";
    this.Error = this.translate.instant("GUI.errorMessages.examExists.msg");
  }

  errorCalendar(data = null) {
    this.alreadyHasExam = false;
    this.Error = data;
    this.ErrorType = "Save This Pregnancy Planner?";
    this.calendarForm = true;
    this.concentForm = false;
    if(data){
      this.concentForm = true;
      this.ErrorType = data;
    }
  }

  errorSession(errorType: string, errorMessage: string){
    this.calendarForm = null;
    this.concentForm = false;
    this.alreadyHasExam = false;
    this.sessionExpiration = true
    this.ErrorType = errorType;
    this.Error = errorMessage;
  }

  resetErr() {
    this.Error = null;
    this.ErrorType = "";
  }
}
