import { Component, OnInit,Input } from '@angular/core';
import {ExamsService}from '../exams.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AutotextService } from '../autotext.service';


@Component({
  selector: 'app-findings-summary',
  templateUrl: './findings-summary.component.html',
  styleUrls: ['./findings-summary.component.scss']
})
export class FindingsSummaryComponent implements OnInit {
  @Input('formGroup') formGroup: UntypedFormGroup;
 addNewFinding=false
  userFindings=[]
  FindingText=''
  selectedFindingOpenSearch=false
  filteredFindings=[]
  examFindings :any = []
  constructor(public es: ExamsService,public atS:AutotextService) { }

  async ngOnInit() {
    this.loadFindingsOptions(); 
    this.examFindings =await this.es.ExamFindings()
  }


  loadFindingsOptions(){
  this.es.getFindingsOptions().subscribe( {
    next: (data:any) => {
      this.userFindings = data;
      this.filteredFindings = this.userFindings || []
    },
    error: (err) => console.error('Error fetching options:', err),
  });
  }


  onClosed(): void {
    this.addNewFinding = false;
    this.loadFindingsOptions(); 
  }

  filterOptions(){
    this.selectedFindingOpenSearch = false;
    if (this.FindingText) {
     
      this.filteredFindings = this.userFindings.filter((option) =>{
       return option['Finding'].toLowerCase().includes(this.FindingText.toLowerCase())
      }
      );
      const searchMatchFindings = this.filteredFindings.filter(
        (option) =>{return option['Finding'].toLowerCase() === this.FindingText.toLowerCase()}
      );
      if (searchMatchFindings.length) {
        this.selectedFindingOpenSearch=true
        
      }
    } else {
      this.filteredFindings = this.userFindings || [];
      this.selectedFindingOpenSearch=false
      
    }
  }

  addFinding(){
    const [selectedFinding]=this.userFindings.filter((option) =>{
      return option['Finding'].toLowerCase() === this.FindingText.toLowerCase()});
    if(!selectedFinding) return

    this.es.addFinding(selectedFinding).subscribe({
      next:async (data:any) => {
        this.FindingText = '';
        this.examFindings =await this.es.ExamFindings()
        this.selectedFindingOpenSearch=false
        this.filteredFindings =this.userFindings || [];
      },
      error: (err) => console.error('Error adding finding to exam:', err),
    });
  }

  removeFinding(finding){
    this.es.removeFinding(finding).subscribe({
      next:async (data:any) => {
        this.examFindings =await this.es.ExamFindings()
      },
      error: (err) => console.error('Error removing finding from exam:', err),
    });
  }
  
}
