 <!-- dr panel (start) -->
 <div class="drPanel">
    
        <i class="drPanel-icon icon-dr"></i>

        <div class="drPanel-info">
            <figure class="drPanel-image">
                <img src="assets/images/dr.jpg" alt=""/>
            </figure>
            <div class="drPanel-desc">
                <div class="drPanel-name">
                    {{loginService.getUserName()}}
                </div>
                <div class="drPanel-detail">
                    {{loginService.Clinic ? loginService.Clinic : ''}}
                </div>
            </div>
        </div>

        <div class="drPanel-controls">
            <a class="drPanel-controls-item drPanel-controls-icon-gear" style='cursor: pointer;' [matMenuTriggerFor]="menu">
                <!-- Settings -->
                 {{'GUI.General.Settings' | translate | titlecase}}
            </a>
            <mat-menu #menu="matMenu">
                <a mat-menu-item (click)="goDefaults()" routerLink="/defaults">
                    <!-- Defaults -->
                    {{'GUI.General.Buttons.Defaults' | translate | titlecase}}
                </a>
                <a mat-menu-item routerLink="/Custom" *ngIf="!loginService.isCardio && ! loginService.isGastro && false">Custom Header</a>
                <a mat-menu-item routerLink="/Stats" *ngIf="loginService.premissions && loginService.premissions.Stats">
                    {{'GUI.General.Buttons.Stats' | translate | titlecase}}
                </a>
                <a mat-menu-item (click)="showLimits = true" *ngIf="loginService.isAdmin && loginService.premissions && loginService.premissions.Limits">Limits</a>
                <a mat-menu-item (click)="showLogin = true" *ngIf="loginService.ishospital && loginService.isAdmin">Export Data</a>
                <a mat-menu-item routerLink="/Searchdata" *ngIf="(loginService.ishospital && (loginService.isAdmin || loginService.premissions.Stats ) || (!loginService.ishospital && loginService.premissions?.searchText))">
                    
                    {{'GUI.General.Buttons.Search by Text' | translate | titlecase}}

                </a>
                <!-- <a mat-menu-item [matMenuTriggerFor]="languages">
                    {{'GUI.General.Buttons.Language' | translate | titlecase}}
                </a> -->
                <!-- <mat-menu #languages="matMenu">
                    <button class="inner-menu-buttons" style="text-align: center;" mat-menu-item (click)="languageService.switchLanguage('en-US')">
                         English
                    </button>
                    <button class="inner-menu-buttons" style="text-align: center;" mat-menu-item (click)="languageService.switchLanguage('he-IL')">
                        Hebrew
                    </button>
                    <button class="inner-menu-buttons" style="text-align: center;" mat-menu-item (click)="languageService.switchLanguage('pl-PL')">
                        Polish
                    </button>
                    <button class="inner-menu-buttons" style="text-align: center;" mat-menu-item (click)="languageService.switchLanguage('it-IT')">
                        Italian
                    </button>
                    <button class="inner-menu-buttons" style="text-align: center;" mat-menu-item (click)="languageService.switchLanguage('es-ES')">
                        Spanish
                    </button>
                </mat-menu> -->
                <a mat-menu-item>
                    <!-- Measurements -->
                    {{'GUI.General.Buttons.Measurements' | translate | titlecase}}
                </a>
                <a mat-menu-item (click)="showAbout=true">
                    <!-- About -->
                    {{'GUI.General.Buttons.About' | translate | titlecase}}
                </a>
            </mat-menu>
            <mat-menu #about="matMenu">
                <a mat-menu-item>Aweb application version: {{currentApplicationVersion}},  API version: {{loginService.APIversion}}</a>
                <a mat-menu-item>View Changelog</a>
              </mat-menu>
            <a (click)="logout()" class="drPanel-controls-item drPanel-controls-icon-logout" style='cursor: pointer;'>
                <!-- Logout -->
                {{'GUI.General.Logout' | translate | titlecase}}
            </a>
        </div>
    <app-loginbox *ngIf="showLogin" (closed)="showLogin=false;" [isExport]="true"></app-loginbox>
    <app-limits *ngIf="showLimits" (closed)="showLimits=false;"></app-limits>

    <div class="t-popup popup-patientDetails"  style='display: block; top:10em' *ngIf="showAbout">
        <div class="t-popup-close"></div>
        <div class="t-popup-frame" >
            <div class="t-popup-body">
                Artisan Sonics Ltd. Aweb application version: {{currentApplicationVersion}}<br>
                Released: {{currentAppDate}}<br>
                Using API version: {{loginService.APIversion}}
                <br>
                <br>
                <a class="search-btn" target="_blank" href="../../assets/CHANGELOG.md">View Changelog</a>
                <button class="t-btn-popup-close" aria-label="close popup" (click)="showAbout=false"></button>
            </div>
        </div>
    </div>

</div>
    <!-- dr panel (end) -->
