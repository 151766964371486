import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MeasgrowthService } from '../measgrowth.service';
import { ExamsService } from '../exams.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AutotextService } from '../autotext.service';
import { Subject, Subscription } from 'rxjs';
import { FetalechoService } from '../fetalecho.service';
import { LoginService } from '../login.service';
import { PrintService } from '../print.service';
import { UltrasoundMeasurementsService } from './../ultrasound-measurements.service';
import { PregnancyService } from '../pregnancy.service';
import { PatientsService } from '../patients.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss']
})
export class MeasurementsComponent implements OnInit, AfterViewInit {
  @Input('modality') modality: string;
  GAlmp: string;
  fetusSub: Subscription;
  mediaSub: Subscription;
  Comment: UntypedFormControl;
  IsWeightAss = false;
  IsFetalEcho = false;
  showComment = false;
  text;
  sub;
  changesUsComputing={}
  // The following variables represent whether there is a difference between the normal value and the ultrasound value
  // if true- no difference, if false there is a difference
  BPDifferent:boolean=true
  HCDifferent:boolean=true
  FLDifferent:boolean=true;
  ACDifferent:boolean=true;
  HLDifferent:boolean=true;
  RADIUSDifferent:boolean=true
  ULDifferent:boolean=true;
  CLDifferent:boolean=true;
  TCDifferent:boolean=true;
  OFDDifferent:boolean=true;
  OODDifferent:boolean=true;
  IODDifferent:boolean=true;
  ODDifferent:boolean=true;
  EarLenDifferent:boolean=true;
  CEREBDifferent:boolean=true;
  CMDifferent:boolean=true;
  VoPlDifferent:boolean=true;
  NuchalDifferent:boolean=true;
  TLDifferent:boolean=true;
  FIBULADifferent:boolean=true;
  FootDifferent:boolean=true;
  public isLMPMissing: boolean;

  @ViewChild("CommentsExtra") set CommentsExtra(ref: ElementRef) {
    if (!!ref) {
      ref.nativeElement.focus() ;
    }
  }
  @Input('fetus') fetus: number =1;
  
  atSTitleMeasurementsComments = this.translate.instant('GUI.Auto Text.Titles.Measurements Comments');

  constructor(
    public fes: FetalechoService,
    private measService: MeasgrowthService,
    public es: ExamsService,
    public atS: AutotextService,
    public print: PrintService,
    public ls: LoginService,
    public us: UltrasoundMeasurementsService,
    public pregnancyService: PregnancyService,
    public patientsService: PatientsService,
    private translate: TranslateService,
  ) {
    this.measService.setGA();
    this.Comment = new UntypedFormControl();

    if(this.es.ExamType === 'Weight Assessment' || this.es.ExamType == 'Tech Exam'){
      this.IsWeightAss = true;
    }
    if(this.es.ExamType === 'Fetal Echo'){
      this.IsFetalEcho = true;
      // this.fes.getFetalEchoData(this.es.examID, 'meas');
      // this.fes.resetMeas();
      // this.fes.getFetalMeasure();
    }
    if(this.es.ExamType === 'Pediatric Echo'){
      this.IsFetalEcho = true;
      this.fes.getFetalMeasure();
    }

  }


  async ngOnInit() {
    this.measService.fetchValues(this.Comment);
    if((this.ls.usMode || this.ls.premissions?.usMode) && this.es.examsUsMode.includes(this.es.ExamType)){
   this.modality=this.es.modality
    try{
    this.changesUsComputing=await this.measService.fetchDifferent()
    this.BPDifferent=this.changesUsComputing['BPD'];
    this.HCDifferent=this.changesUsComputing['HC']
    this.FLDifferent=this.changesUsComputing['FL']
    this.ACDifferent=this.changesUsComputing['AC']
    this.HLDifferent=this.changesUsComputing['HL'];
    this.RADIUSDifferent=this.changesUsComputing['RADIUS'];
    this.ULDifferent=this.changesUsComputing['UL'];
    this.CLDifferent=this.changesUsComputing['CL'];
    this.TCDifferent=this.changesUsComputing['TC'];
    this.OFDDifferent=this.changesUsComputing['OFD'];
    this.OODDifferent=this.changesUsComputing['OOD'];
    this.IODDifferent=this.changesUsComputing['IOD'];
    this.ODDifferent=this.changesUsComputing['OD'];
    this.EarLenDifferent=this.changesUsComputing['EarLen'];
    this.CEREBDifferent=this.changesUsComputing['CEREB'];
    this.CMDifferent=this.changesUsComputing['CM'];
    this.VoPlDifferent=this.changesUsComputing['VoPl'];
    this.NuchalDifferent=this.changesUsComputing['Nuchal'];
    this.TLDifferent=this.changesUsComputing['TL'];
    this.FIBULADifferent=this.changesUsComputing['FIBULA'];
    this.FootDifferent=this.changesUsComputing['Foot'];
      //load values from
     
    
    
    
    } catch(err){
      console.log(err),this.changesUsComputing={BPD:true,HC:true,FL:true,AC:true};
    }
    if(this.modality=='Ultrasound')
    this.us.loadMeasurements();
  }
     
    this.fetusSub = this.es.fetusChange$.subscribe(() => {
      this.measService.fetchValues(this.Comment);
      // this.fes.getFetalEchoData(this.es.examID,'meas');
      // this.fes.getFetalMeasure()
    });
    this.es.getSRs();
    if(!this.es.isClosed)
      this.mediaSub =  this.es.mediaArrived$.subscribe((mediatype)=>{
        if(mediatype == 'html'){
          this.measService.fetchValues(this.Comment);
          if((this.ls.usMode || this.ls.premissions?.usMode) && this.es.examsUsMode.includes(this.es.ExamType))
          this.us.loadMeasurements();
  }});

    const LMP = this.pregnancyService.pregForm.get("LMP").value;
    const LMP_US = this.pregnancyService.pregForm.get("LMP_US").value;
    
    if (LMP || LMP_US) {
      this.isLMPMissing = false;
    } else {
      this.isLMPMissing = true;
    }
  }

  ngAfterViewInit(): void {
    this.Comment.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(data => {
      this.measService.saveMeas("Comments", this.Comment.value);
    });
    this.Comment.valueChanges.subscribe((data) => {
      if(data != null){
        this.showComment= true;
        this.text = data;
      }
      // this.measService.saveMeas("Comments", this.Comment.value);
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if(this.fetusSub){
      this.fetusSub.unsubscribe();
    }
    if(this.mediaSub)
      this.mediaSub.unsubscribe();
  }
  onClosed(closed: string){
    if(closed){
      this.showComment = false;
    }
    if(closed =='meas')
      this.measService.fetchValues(this.Comment);
  }


}
