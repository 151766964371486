<ng-container [formGroup]="GenForm">

<div class="h100 l-flex-w t-boxMargin--spacebyPadding summary">		

    <!-- cell : Anamnesis -->

    <div class="w50 summary-anamnesis" style="height: 50%;">

        <section class="m-box">

            <h2>
                <!-- Medical History -->
                {{'pregnancy.MedicalHistory.title' | translate | titlecase }}
            </h2>
            
            <textarea class="scroller" formControlName="MedicalHistory">
           
            </textarea><!-- /scroller -->
            <h4 style="margin: 0;">
                <!-- Obsetrical Formula -->
                {{'pregnancy.MedicalHistory.ObstetricFormula' | translate | titlecase}}

            </h4>
            <div style="display: flex;padding: 1em 0;justify-content: space-between;">
                <div style="width: 100%;display: flex">
                    <section style="margin-inline-end: 1em;">
                        Gravida
                        <select class="t-select t-field" formControlName="Gravida">
                            <ng-container *ngFor="let _ of [].constructor(25), let i = index">
                                <option value="{{i}}">{{i}}</option>
                            </ng-container>
                        </select>
                    </section>
                    <section style="margin-inline-end: 1em;">
                        Para
                        <select class="t-select t-field" formControlName="Para">
                            <ng-container *ngFor="let _ of [].constructor(25), let i = index">
                                <option value="{{i}}">{{i}}</option>
                            </ng-container>
                        </select>
                    </section>
                    <section style="margin-inline-end: 1em;">
                        Abortion
                        <select class="t-select t-field" formControlName="Abortion">
                            <ng-container *ngFor="let _ of [].constructor(25), let i = index">
                                <option value="{{i}}">{{i}}</option>
                            </ng-container>
                        </select>
                    </section>
                </div>
            </div>
            <button class="t-btn-edit" *ngIf="! es.isClosed && lastMed" (click)="addLastMed()" style="margin-right: 6em" >Previous Medical History</button>
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(GenForm.get('MedicalHistory'),atSTitleMedicalHistory,1)">Auto Text</button>

        </section>
    
    </div>
    <!-- endcell : Anamnesis -->

    <!-- cell : Reason for Examinanion -->
    
    <div class="w50 summary-recommendations" style="height: auto;">

        <section class="m-box">

            <h2>
                <!-- Reason for Examination -->
             {{'GUI.Labels.Reason for Examination' | translate }}
            </h2>

            <textarea class="scroller" formControlName="Purpose">
                
            </textarea><!-- /scroller -->
            
            <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(GenForm.get('Purpose'),atSTitleReasonForExamination,92)">Auto Text</button>
            
        </section>

    </div>

    <!-- endcell : Reason for Examinanion -->

</div>

<!-- box (start) -->
<div class="l-flex pregnancy-table">
    <!-- PREGNANCY TABLE - WIDE CELL (START) -->
    <div class="l-fg1 pregnancy-table-wide">
        <section class="m-box" style="margin-top: 1em;">

            <h2>
                <!-- Last Menstrual -->
                {{'pregnancy.Last_Menstrual_Period.GUI.Last Menstrual' | translate | titlecase }}

            </h2>
            
            <div class="pregnancy-menstrual">
                
                <!-- row (start) -->
                <div style="display: flex; align-items: center; margin-bottom: 1em;">
                    <label class="l-fg1 menstural-label" id="menstural_LMP">
                        L.M.P. &nbsp;
                    </label>

                    <span class="l-flex t-boxMargin menstural-fields-set">
                        <input
                          [matDatepicker]="LMPDatepicker"
                          formControlName="LMP"
                          class="t-field t-field--date menstural-field"
                          aria-labelledby="menstural_LMP"
                          style="background-image: none !important;"
                        />
                        <img
                          src="../../assets/icons/date.svg"
                          (click)="LMPDatepicker.open()"
                          style="cursor: pointer; margin-left: -3em"
                        />
                        <mat-datepicker #LMPDatepicker></mat-datepicker>
                        <mat-datepicker #LMPDatepicker></mat-datepicker>
                        <span class="t-field menstural-field menstural-info">
                          {{ days() }}
                          <ng-container *ngIf="days()"> Days </ng-container>
                        </span>
                      </span>
                </div>
                <!-- row (end) -->

            </div>

        </section>
    </div>
    <div class="l-flex pregnancy-table-sidebar" *ngIf="es.showDefaultButton()">
        <button class="defaults"  (click)="setDefaults()" style="margin-top:1em">
            Apply Defaults
        </button>
    </div>
</div>
<!-- box (end) -->

</ng-container>