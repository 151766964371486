<!-- ========================== intro (start) ========================== -->
    <div class="dashboard-wrapper" [formGroup]="nt.NTForm">
        <div class="dashboard-section-wrapper">
            <section class="dashboard-section" *ngIf="es.switchNT || es.ExamType=='NT' || es.addNT">
                <header class="dashboard-section-header">
                    <h2>
                         {{'pregnancy.Pregnancy_Type.title' | translate | titlecase}}
                    </h2>
                </header>
                <div class="tabs">
                    <div role="tablist" aria-label="Way of Conception">
                        <button role="tab"
                        [attr.aria-selected]="nt.NTForm.get('Conception').value =='Natural'? 'true': 'false'" 
                                aria-controls="natural-tab"
                                id="natural" (click)='setConcep("Natural")'>
                            {{'pregnancy.Pregnancy_Type.Natural' | translate | titlecase}}

                        </button>
                        <button role="tab"
                        [attr.aria-selected]="nt.NTForm.get('Conception').value=='Egg Freezing'? 'true': 'false'" 
                                aria-controls="egg-freezing-tab"
                                id="egg-freezing"
                                [disabled]="es.ps.pregForm.get('NaturalPregnancy').value == 1"
                                tabindex="-1" (click)='setConcep("Egg Freezing")'>
                            {{'nt.way_of_conception.egg_freezing' | translate | titlecase}}

                        </button>
                        <button role="tab"
                        [attr.aria-selected]="nt.NTForm.get('Conception').value=='Egg Donation'? 'true': 'false'" 
                                aria-controls="egg-donation-tab"
                                id="egg-donation"
                                [disabled]="es.ps.pregForm.get('NaturalPregnancy').value == 1"
                                tabindex="-1" (click)='setConcep("Egg Donation")'>
                            {{'nt.way_of_conception.egg_donation' | translate | titlecase}}
                        </button>
                    </div>
                </div>

                <div tabindex="0"
                    role="tabpanel"
                    id="natural-tab" *ngIf="nt.NTForm.get('Conception').value=='Natural'"
                    aria-labelledby="natural">
                    <div class="l-flex-c t-validation-wrap" > 
                            <label class="l-fg1 menstural-label t-validation-wrap" id="age-of-delivery" [ngClass]=" ! pats.ageRange(nt.getEDDAge()) && nt.getEDDAge() ? 'is-invalid-left':'' ">
                                {{'nt.way_of_conception.age_at_delivery_by_donation' | translate | titlecase}}
                            </label>
                            <div class="t-validation-message-left">
                                <span *ngIf="! pats.ageRange(nt.getEDDAge())" style="top: -2.3em; left: -5em;">{{(nt.getEDDAge() && getAgeOver(nt.getEDDAge()) ? 'Age over than 45 years and 11 months!' : 'Age less than 20 years!')}}<br>Acceptable Range 20y0m thru 45y11m</span>
                            </div>
                            <span class="l-flex t-boxMargin menstural-fields-set" *ngIf="! nt.getEDDAge() || pats.ageRange(nt.getEDDAge())">
                                <span class="t-field t-field--date menstural-field" aria-labelledby="age-of-delivery"  [ngClass]="! nt.getEDDAge() ? 'is-invalid-right':''">{{nt.getEDDAge()}}</span>
                                <div class="t-validation-message-right" style="top: 4%;right: -19%;">
                                    <span *ngIf="! nt.getEDDAge()">
                                        {{'GUI.NT.CustomTooltips.Please insert birth date or LMP is missing'  | translate | titlecase}}
                                    </span>
                                </div>
                            </span> 
                    </div>
                </div>
                <div tabindex="0"
                    role="tabpanel"
                    id="egg-freezing-tab"
                    aria-labelledby="egg-freezing"
                   *ngIf="nt.NTForm.get('Conception').value=='Egg Freezing'">
                    <div class="l-flex-c cervical-row-2">
                        <label class="l-fg1 cervical-label" id="">Do not print the Way of Conception</label>
                        <div class="l-flex-c t-field t-twin-fieldset t-field--number">
                            <app-genradio formname="PrintConception" [formgroup]="nt.NTForm" [value1]="0" [value2]="1" lname="{{'general.PositiveNegative.yes' | translate | titlecase}}" rname="{{'general.PositiveNegative.No' | translate | titlecase}}" [resetval]="-1"></app-genradio>
                        </div>
                    </div>
                    <div class="l-flex-c">
                        <label class="l-fg1 menstural-label" id="date-of-egg-freezing">
                            {{'nt.way_of_conception.date_of_egg_freezing.title' | translate | titlecase}}

                        </label>
                        <span class="l-flex t-boxMargin menstural-fields-set">
                            <mat-datepicker #FrDatepicker></mat-datepicker>
                            <input readonly  formControlName="EggDonationDate" [matDatepicker]="FrDatepicker" (focus)="FrDatepicker.open()" (click)="FrDatepicker.open()" class="t-field t-field--date menstural-field bigcal" aria-labelledby="date-of-egg-freezing">
                        </span>

                    </div>
                    <div class="l-flex-c  t-validation-wrap">
                        <label class="l-fg1 menstural-label" id="age-of-delivery" [ngClass]=" ! pats.ageRange(nt.freezeAge()) && nt.freezeAge() ? 'is-invalid-left':'' ">
                            {{'nt.way_of_conception.age_at_delivery_by_donation' | translate | titlecase}}
                        </label>
                        <div class="t-validation-message-left">
                            <span *ngIf="! pats.ageRange(nt.freezeAge())" style="top:-2.3em;left: -5em;">{{(nt.freezeAge() && getAgeOver(nt.freezeAge()) ? 'Age over than 45 years and 11 months!' : 'Age less than 20 years!')}}<br>Acceptable Range 20y0m thru 45y11m</span>
                        </div>
                        <span class="l-flex t-boxMargin menstural-fields-set" *ngIf="!nt.freezeAge() || pats.ageRange(nt.freezeAge())" [ngClass]="! nt.freezeAge() ? 'is-invalid-right':''">
                            <span class="t-field t-field--date menstural-field" aria-labelledby="age-of-delivery" >{{nt.freezeAge()}}</span>
                        </span>
                        <div class="t-validation-message-right t-validation-message-right-EggFreezing" style="top: 40%;right: -17%;">
                            <span >
                           {{'GUI.NT.CustomTooltips.Please insert Egg freeze date or Birth date' | translate | titlecase}}
                            </span>
                        </div>
                    </div>
                </div>
                <div tabindex="0"
                    role="tabpanel"
                    id="egg-donation-tab"
                    aria-labelledby="egg-donation"
                    *ngIf="nt.NTForm.get('Conception').value =='Egg Donation'">
                    <div class="l-flex-c cervical-row-2">
                        <label class="l-fg1 cervical-label" id="">Do not print the Way of Conception</label>
                        <div class="l-flex-c t-field t-twin-fieldset t-field--number">
                            <app-genradio formname="PrintConception" [formgroup]="nt.NTForm" [value1]="0" [value2]="1" lname="{{'general.PositiveNegative.yes' | translate | titlecase}}" rname="{{'general.PositiveNegative.No' | translate | titlecase}}" [resetval]="-1"></app-genradio>
                        </div>
                    </div>
                    <div class="l-flex-c">
                        <label class="l-fg1 menstural-label" id="date-of-donors-birth">
                            {{'nt.way_of_conception.donors_birthdate.title' | translate | titlecase}}

                        </label>
                        <span class="l-flex t-boxMargin menstural-fields-set">
                            <mat-datepicker #BDDatepicker startView="year"></mat-datepicker>
                            <input readonly formControlName="OtherDonorBD"  [matDatepicker]="BDDatepicker" (focus)="BDDatepicker.open()" (click)="BDDatepicker.open()"class="t-field t-field--date menstural-field bigcal" aria-labelledby="date-of-donors-birth">
                        </span>
                    </div>
                    <div class="l-flex-c">
                        <label class="l-fg1 menstural-label" id="age-of-egg-donation">
                            {{'nt.way_of_conception.date_of_egg_donation.title' | translate | titlecase}}

                        </label>
                        <span class="l-flex t-boxMargin menstural-fields-set">
                            <mat-datepicker #DonDatepicker></mat-datepicker>
                            <input readonly [matDatepicker]="DonDatepicker" (focus)="DonDatepicker.open()" (click)="DonDatepicker.open()"  formControlName="EggDonationDate" class="t-field t-field--date menstural-field bigcal" aria-labelledby="age-of-egg-donation">
                        </span>
                    </div>
                    <div class="l-flex-c t-validation-wrap">
                        <label class="l-fg1 menstural-label " id="age-of-delivery" [ngClass]="! pats.ageRange(nt.getDonorAge()) && nt.getDonorAge() ? 'is-invalid-left':''">
                            {{'nt.way_of_conception.age_at_delivery_by_donation' | translate | titlecase}}

                        </label>
                        <div class="t-validation-message-left" style="top:0.2em;left: -12em;">
                            <span>{{(nt.getDonorAge() && getAgeOver(nt.getDonorAge()) ? 'Age over than 45 years and 11 months!' : 'Age less than 20 years!')}}<br>Acceptable Range 20y0m thru 45y11m</span>
                        </div>
                        <span class="l-flex t-boxMargin menstural-fields-set t-validation-wrap">
                            <span type="text" class="t-field t-field--date menstural-field" *ngIf="! nt.getDonorAge() || pats.ageRange(nt.getDonorAge())" [ngClass]="! nt.getDonorAge() ? 'is-invalid-right':''" aria-labelledby="age-of-delivery">{{nt.getDonorAge()}}</span>
                            <div class="t-validation-message-right t-validation-message-right-EggDoantion">
                                <span>
                                    {{'GUI.NT.CustomTooltips.Please insert Egg Donation date or Donor\'s Birth date'  | translate | titlecase}}
                                </span>
                            </div>
                        </span>
                    </div>
                </div>
            </section>
            <section class="dashboard-section">
                <header class="dashboard-section-header">
                    <h2>
                         {{'measurements.titles.Measurements' | translate | titlecase}}

                    </h2>
                    <div class="dashboard-section-status">
                        <span></span>
                        <span></span>
                        <span class="active">Normal</span>
                        <span></span>
                        <span></span>
                    </div>
                </header>
                <div class="measurement-container fww measurments-panel ntmeas" style="z-index: -5;">
                    <app-measure MyMeasure="GS"  [Editable]="true" class="measurment-unit" style='margin-right: 9em' [updated]="nt.updated$.asObservable()"></app-measure>
                    <app-measure MyMeasure="CRL"  [Editable]="true" class="measurment-unit" style="margin-right: 7em;" [updated]="nt.updated$.asObservable()"></app-measure>
                    <app-measure MyMeasure="NT"  [Editable]="true" class="measurment-unit"  style='margin-right: 4em;' [updated]="nt.updated$.asObservable()"></app-measure>
                </div>
            </section>
            
        </div>
        <div class="dashboard-section-wrapper">
            <section class="dashboard-section"  style="padding: 20px 24px;margin-bottom: 15px;">
                <header class="dashboard-section-header">
                    <h2>
                        {{'1-st.biophysical_indices.title' | translate | titlecase}}
                    </h2>
                </header>
                <div class="l-flex-c cervical-row-2">
                    <label class="l-fg1 cervical-label" id="">
                        {{'1-st.biophysical_indices.yolk_sac' | translate | titlecase}}

                    </label>
                    <div class="l-flex-c t-field t-twin-fieldset t-field--number">
                        <app-genradio formname="YolkSac" [formgroup]="nt.NTForm" [value1]="0" [value2]="1" lname="{{'general.PositiveNegative.yes' | translate | titlecase | titlecase}}" rname="{{'general.PositiveNegative.No' | translate | titlecase}}" [resetval]="-1"></app-genradio>
                    </div>
                </div>
                <div class="l-flex-c cervical-row-2">
                    <label class="l-fg1 cervical-label" id="">
                        {{'1-st.biophysical_indices.nasal_bone' | translate | titlecase}}

                    </label>
                    <div class="l-flex-c t-field t-twin-fieldset t-field--number">
                        <app-genradio formname="NasalBone" [formgroup]="nt.NTForm" [value1]="0" [value2]="1" lname="{{'general.PositiveNegative.yes' | translate | titlecase }}" rname="{{'general.PositiveNegative.No' | translate | titlecase}}" [resetval]="-1"></app-genradio>
                    </div>
                </div>
                <div class="l-flex-c cervical-row-2">
                    <label class="l-fg1 cervical-label" id="">
                        {{'GUI.Labels.Heart Beat' | translate | titlecase}}

                    </label>
                    <div class="l-flex-c t-field t-twin-fieldset t-field--number">
                        <app-genradio formname="Heart" [formgroup]="nt.NTForm" [value1]="0" [value2]="1" lname="{{'general.PositiveNegative.yes' | translate | titlecase}}" rname="{{'general.PositiveNegative.No' | translate | titlecase}}" [resetval]="-1"></app-genradio>
                    </div>
                </div>
                <div class="l-flex-c cervical-row-2">
                    <label for="" class="l-fg1 cervical-label">FHR</label>
                    <span class="t-field t-field--number">
                        <input class="t-field-text" type="number" min="0" id="" formControlName="FHR"/>
                        <span class="t-field-desc">bpm</span>
                    </span>
                </div>
               
            </section>
            <section class="dashboard-section" *ngIf="es.switchNT || es.ExamType=='NT' || this.es.addNT">
                <div class="l-flex-c cervical-row-2  t-validation-wrap">
                    <label for="" class="l-fg1 cervical-label"  [ngClass]=" ! getRiskAge() ? 'is-invalid-left':'' ">
                         {{'nt.fetus_measurements.risk_by_age.title' | translate | titlecase}}
                    </label>
                    <div class="t-validation-message-left" style="top:22px;left: -45%;">
                        <span *ngIf="! getRiskAge()">
                            {{'GUI.NT.CustomTooltips.Risk by age can not be computed' | translate | titlecase}}

                        </span>
                    </div>
                    <span class="t-field t-field--number">
                        <span class="t-field-ratio">1:</span>
                        <span class="t-field-text" *ngIf="nt.loaded">{{getRiskAge()}}</span>
                    </span>
                </div>
                <div class="l-flex-c cervical-row-2 t-validation-wrap">
                    <label for="" class="l-fg1 cervical-label" [ngClass]=" ! checkRiskCRL() ? 'is-invalid-left':'' ">
                        {{'nt.fetus_measurements.risk_by_age_and_measurements.title' | translate | titlecase}}
                    </label>
                    <div class="t-validation-message-left" style="top:24px;left: 15%;">
                        <span *ngIf="! checkRiskCRL()">
                            {{'GUI.NT.CustomTooltips.Risk by Age and Measurements can not be computed' | translate | titlecase}}
                        </span>
                    </div>
                    <span class="t-field t-field--number">
                        <span class="t-field-ratio">1:</span>
                        <span class="t-field-text" *ngIf="nt.loaded">{{checkRiskCRL()}}</span>
                    </span>
                </div>
            </section>
            <div class="w100 summary">
                <div class="m-box doppler-comments">
                    <h2>
                        {{'general.Comments' | translate | titlecase}}
                    </h2>
                    
                        <textarea class="full" formControlName="Comments" formControlName="Comments"></textarea>
                        <button class="t-btn-edit" *ngIf="! es.isClosed" (click)="atS.showAuto(nt.NTForm.get('Comments'),(es.ExamType === 'NT' ? atSTitleNT : atSTitle1stTrimester) + ' ' + atSTitleComments, 60)">Auto Text</button>		
                        
                </div>
            </div>
        </div>
    </div>

    <div class="second-layer-popup">
        <div class="second-layer-popup-frame">
            <div class="second-layer-popup-body"></div>
        </div>
    </div>
