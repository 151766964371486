<div style="width: 100%;">
    <form [formGroup]="fes.fetalEchoForm" *ngIf=fes.fetalEchoForm>
        <div style="display: flex;flex-direction: row;">
            <div class="w25 anatomy-item" style="margin: 0.75em;display: flex;flex-direction: column;justify-content: space-between;">
                <app-fetalechoitem [group]="Group" [name]="'Situs'" >
                </app-fetalechoitem>
                <app-fetalechoitem [group]="Group" [name]="'PE'" style="margin-top: 1em;">
                </app-fetalechoitem>
            </div>
            <div class="w25 anatomy-item" style="margin: 0.75em;display: flex;flex-direction: column;justify-content: space-between;">
                <app-fetalechoitem [group]="Group" [name]="'AVC'" ></app-fetalechoitem>
                <app-fetalechoitem [group]="Group" [name]="'VAC'" ></app-fetalechoitem>
            </div>
            <div class="w25 anatomy-item" style="margin: 0.75em;">
                <app-fetalechoitem [group]="Group" [name]="'SVR'" ></app-fetalechoitem>
            </div>
            <div class="w25 anatomy-item" style="margin: 0.75em;">
                <app-fetalechoitem [group]="Group" [name]="'PVR'" ></app-fetalechoitem>
            </div>
        </div>
        <h3 style="margin-inline-start: 1em;">
            {{'GUI.Fetal Echo.Anatomy.Atria' | translate}}
        </h3>
        <div class="row4" style="justify-content: space-between;">
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Right Atrium'" >
                </app-fetalechoitem>
            </div>
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Left Atrium'" >
                </app-fetalechoitem>
            </div>
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Interatrial Septum'" >
                </app-fetalechoitem>
            </div>
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Foramen Ovale'" >
                </app-fetalechoitem>
            </div>
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Coronary Sinus'" >
                </app-fetalechoitem>
            </div>
        </div>
        <h3 style="margin-inline-start: 1em;">
            {{'GUI.Fetal Echo.Anatomy.Ventricles' | translate}}
        </h3>
        <div class="row4" style="justify-content: space-between;">
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Right Ventricle'" >
                </app-fetalechoitem>
            </div>
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Left Ventricle'" >
                </app-fetalechoitem>
            </div>
            <div class="w20 anatomy-item"  (dblclick)="clear(Group,'IVS')"
                style="width: 40%;height: fit-content;margin: 2px;">
                <div class="m-box" [ngClass]="getClass('IVS')">

                    <h2>
                        {{'GUI.Fetal Echo.Anatomy.Interventricular Septum' | translate}}
                    </h2>
                    <div>
                        <div class="anatomy-twin-radios anatomy-twin-radios--is_tripple" style="margin-block-start: 1em;" (dblclick)="clear(Group,'IVSStatus')">

                            <label class="observe">
                
                                <input type="radio" class="custom-check customInput" formControlName="IVSStatus" [value]="0" />
                                <i class="customInput-display"></i>
                                <span>
                                    {{'GUI.Fetal Echo.Anatomy.Normal' | translate}}
                                </span>
                
                            </label>
                            <label class="attention">
                
                                <input type="radio" class="custom-check customInput" formControlName="IVSStatus" [value]="2" />
                                <i class="customInput-display"></i>
                                <span>
                                    {{'GUI.Fetal Echo.Anatomy.Abnormal' | translate}}
                                </span>
                
                            </label>
                
                            <label class="not-observe">
                
                                <input type="radio" class="custom-check customInput" formControlName="IVSStatus" [value]="1" />
                                <i class="customInput-display"></i>
                                <span>
                                    {{'GUI.Fetal Echo.Anatomy.Not Observed' | translate}}
                                </span>
                
                            </label>
                
                        </div>
                    </div>
                    <div class="l-flex-c t-field t-twin-fieldset t-fieldset-big" style="justify-content: space-evenly;height: 100%;align-items: center;margin: 1em 0;">
                        <span>
                            {{'GUI.Fetal Echo.Anatomy.Diam' | translate}}
                        </span>
                        <input class="fetal-mesure-in" type="text" formControlName="IVS" style="width: 4em; border: none;text-align: center;text-align-last: center;">
                        {{'GUI.Fetal Echo.Anatomy.mm' | translate}}
                    </div>
                    <span class="anatomy-show-more"  style="right: 1em;">
                        <!--form elemnts not working when empty so using hack-->

                        <div class="on-hoverPanel">

                            <div class="on-hoverPanel-body">

                                <div class="anatomy-item-details">

                                    <h3 class="anatomy-item-details-title">
                                        IVS
                                    </h3>

                                    <div class="anatomy-items-desc">

                                        <div class="anatomy-items-edited scroller mCustomScrollbar _mCS_2 mCS_no_scrollbar"
                                            contenteditable="true">
                                            <div id="mCSB_2"
                                                class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside"
                                                tabindex="0" style="max-height: none;">
                                                <div id="mCSB_2_container"
                                                    class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y"
                                                    style="position:relative; top:0; left:0;" dir="ltr"> </div>
                                                <div id="mCSB_2_scrollbar_vertical"
                                                    class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical"
                                                    style="display: none;">
                                                    <div class="mCSB_draggerContainer">
                                                        <div id="mCSB_2_dragger_vertical" class="mCSB_dragger"
                                                            style="position: absolute; min-height: 30px; top: 0px; display: block; height: 12px; max-height: 106.938px;">
                                                            <div class="mCSB_dragger_bar" style="line-height: 30px;">
                                                            </div>
                                                        </div>
                                                        <div class="mCSB_draggerRail"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <textarea class="anatomy-items-edit" name="IVSNote"
                                            formControlName="IVSNote"></textarea>


                                    </div>

                                </div>

                                <!--<button class="anatomy-details-save">Save Comments</button> -->

                            </div>

                        </div>

                    </span>
                </div>
            </div>
        </div>
        <h3 style="margin-inline-start: 1em;">
            {{'GUI.Fetal Echo.Anatomy.Valves' | translate}}
        </h3>
        <div class="row4" style="justify-content: space-evenly;">
            <div class="m-box" style=" z-index: 4;padding: 1.13em 0.51em 1.13em 0.51em;width: 45%;">
                <h2 style="margin-inline-start: 1em;">
                    {{'GUI.Fetal Echo.Anatomy.Semilunar Valves' | translate}}
                </h2>
                <div style="display: flex;flex-direction: row;width: 100%;justify-content: space-between; padding-block-start: 0.5em;">
                    <div class="w20 anatomy-item" style="width: 45%;">
                        <app-fetalechoitem [group]="Group" [name]="'Pulmonary Valve'" >
                        </app-fetalechoitem>
                    </div>
                    <div class="w20 anatomy-item" style="width: 45%;">
                        <app-fetalechoitem [group]="Group" [name]="'Aortic Valve'" >
                        </app-fetalechoitem>
                    </div>
                </div>
            </div>
            <div class="m-box" style=" z-index: 5;padding: 1.13em 0.51em 1.13em 0.51em;width: 45%;">
                <h2 style="margin-inline-start: 1em;">
                    {{'GUI.Fetal Echo.Anatomy.AV Valves' | translate}}
                </h2>
                <div
                    style="display: flex;flex-direction: row;width: 100%;justify-content: space-between; padding-block-start: 0.5em;">
                    <div class="w20 anatomy-item" style="width: 45%;">
                        <app-fetalechoitem [group]="Group" [name]="'Tricupsid Valve'" >
                        </app-fetalechoitem>
                    </div>
                    <div class="w20 anatomy-item" style="width: 45%;">
                        <app-fetalechoitem [group]="Group" [name]="'Mitral Valve'" >
                        </app-fetalechoitem>
                    </div>
                </div>
            </div>
        </div>
        <h3 style="margin-inline-start: 1em;">
            {{'GUI.Fetal Echo.Anatomy.Great Vessels' | translate}}
        </h3>
        <div class="row4" style="justify-content: space-between;">
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Pulmonary Artery'" >
                </app-fetalechoitem>
            </div>
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Ductus Arteriosus'" >
                </app-fetalechoitem>
            </div>
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Aortic Arch'" >
                </app-fetalechoitem>
            </div>
            <div class="w20 anatomy-item" style="width: 40%;margin: 2px;">
                <app-fetalechoitem [group]="Group" [name]="'Isthmic Flow'" >
                </app-fetalechoitem>
            </div>
        </div>
        <div class="summary" style="margin-block-start: 2.5em;">
            <section class="m-box">
                <h2>
                    {{'GUI.Fetal Echo.Anatomy.Comments' | translate}}
                </h2>
                <textarea class="scroller anatomy-comments" #Comment name="Comments"
                    formControlName="Comments"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(fes.fetalEchoForm.get('Comments'), atSTitleFetalAnatomyComments ,43)">
                    Auto Text
                </button>
            </section>
        </div>
    </form>
</div>
<app-text-box *ngIf="showComment" [data]="fes.fetalEchoForm.get('Comments').value" [form]="fes.fetalEchoForm" type="Comments" ATiD="43" (closed)="showComment = false"></app-text-box>

<!-- <div *ngIf="showComment">
    <div class="t-popup popup-patientDetails summary" style='display: block;top: 5em;'>

        <div class="t-popup-close">
        </div>

        <div class="t-popup-frame" style="max-width: 65em;">
            <h3 class="anatomy-item-details-title">
                Comments
            </h3>
            <div class="t-popup-body" style="display: flex;flex-direction: column;height: 100%;">
                <div style="place-self: flex-end;">
                    <button class="t-btn-popup-close summary" style="top: 0;right: 0;position: relative;"
                        aria-label="close popup" (click)="showComment = false"></button>
                </div>
                <div class="m-box" style="height: 100%; padding: 0;">
                    <textarea class="fulltext" style="min-height: 20em;height: 100%; width: 100%;" #CommentsExtra [(ngModel)]="comment" (ngModelChange)="fes.fetalEchoForm.get('Comments').patchValue(comment)"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                        (click)="atS.showAuto(fes.fetalEchoForm.get('Comments'),'Fetal Anatomy Comments',43)">Auto
                        Text</button>
                </div>
            </div>
        </div>

    </div>
</div> -->