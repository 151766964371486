<main class="l-deashboard measurements">

    <header class="deashboard-header">
        <h1 class="deashboard-title" [ngStyle]="(isLMPMissing && patientsService.getPat().ID !== 'default') ? {'flex-grow': '0'} : {}">

        {{es.ExamType === 'Pediatric Echo' ? 'Pediatric Measurements' : ('measurements.titles.Measurements' | translate | titlecase)}}
        </h1>
        <div *ngIf="isLMPMissing && patientsService.getPat().ID !== 'default'" style="flex-grow: 1; font-size: 1.5em;">
            <span class="lmp-msg">
                <!-- LMP is empty therefore GGs are not computed. -->
                {{'GUI.Measurements.CustomTooltips.Missing LMP Message' | translate}}<br/> 
                {{'GUI.Measurements.CustomTooltips.Missing LMP Message 2' | translate}}

                <!-- The GAs are computed by standard tables. -->
            </span>
        </div>
        <div class="legend" *ngIf="!IsFetalEcho" >
            <i class="legend-item"></i>
            <i class="legend-item"></i>
            <i class="legend-item">Normal</i>
            <i class="legend-item"></i>
            <i class="legend-item"></i>
        </div>
    </header>
    <div *ngIf="IsFetalEcho">
        <section class="m-box" style="width: fit-content; display: flex; float: right;" *ngIf="es.ExamType === 'Fetal Echo' || es.ExamType==='Pediatric Echo'">
            <span>
                {{'GUI.Labels.Print Measurements' | translate}}
            </span>
            <div class="t-set-title" style="margin-inline-start: 1em;" (dblclick)="es.printAllMeas.setValue(true)">

                <div>

                    <label class="observe">

                        <input type="radio" name="printAllMeas" class="custom-check customInput" [formControl]="es.printAllMeas" (change)="es.savePrintALl()"
                            [value]="true" />
                        <i class="customInput-display"></i>
                        <span>
                            {{'general.PositiveNegative.yes' | translate}}
                        </span>

                    </label>

                    <label class="not-observe">

                        <input type="radio" name="printAllMeas" [formControl]="es.printAllMeas" class="custom-check customInput"
                            [value]="false" (change)="es.savePrintALl()" />
                        <i class="customInput-display"></i>
                        <span>
                            {{'general.PositiveNegative.No' | translate}}
                        </span>

                    </label>

                </div>

            </div>
        </section>
        <!-- <div class="summary-spreadHeight summary-recommendations" style="text-align: -webkit-right;">
            <div class="m-box" style="width: fit-content;">

                    <div class="l-flex-c l-flex-w indicative-fieldset h100" style="justify-content: space-evenly;min-height: 2.33em">
                        <span> Print Measurements</span>
                        <label class="t-label-top">
    
                            <input type="radio" [formControl]="measService.printAllMeas" [value]="true" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                               yes
                            </span>
    
                        </label>
    
                        <label class="t-label-top">
                            <input type="radio" [formControl]="measService.printAllMeas" [value]="false" class="custom-check -sq" />
                            <i class="customInput-display -sq"></i>
                            <span>
                                no
                            </span>
                        </label>
                    </div>
                    
                </div>

        

        </div> -->
        <div style="display: flex;">
            <div>
                <div class="fww measurments-panel space"  id="measurmentsPanel">
                    <app-measure MyMeasure="TRC" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="MIT" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="PulmonaryV" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="AOR" [Editable]="true" class="measurment-unit"></app-measure>
                </div>
                <div class="fww measurments-panel space" id="measurmentsPanel">
                    <app-measure MyMeasure="PULFlow" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="MPASYS" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="RPA" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="LPA" [Editable]="true" class="measurment-unit"></app-measure>
                </div>
                <div class="fww measurments-panel space" id="measurmentsPanel">
                    <app-measure MyMeasure="AOFlow" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="ASCAO" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="TrasArch" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="ISTHMUS" [Editable]="true" class="measurment-unit"></app-measure>
                </div>
                <div class="fww measurments-panel space" id="measurmentsPanel">
                    <app-measure MyMeasure="IFI" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="DASYSFlow" [Editable]="true" class="measurment-unit"></app-measure>
                    <app-measure MyMeasure="DADIASTFLOW" [Editable]="true" class="measurment-unit"></app-measure>
                </div>
            </div>
            <div style="margin-block-start: 5em;">
                <app-measure MyMeasure="PEEFF" [Editable]="true" class="measurment-unit" [isminmax]="true"></app-measure>
                <app-measure MyMeasure="Coronary" [Editable]="true" class="measurment-unit"></app-measure>
                
            </div>
        </div>
        <div *ngIf="es.ExamType !== 'Pediatric Echo' "style="display: flex;margin: 1em;">
            <app-fetalmeasure [name]="'GUI.Fetal Echo.Measurements.Ductus Venosus' | translate" dbName="DuctusVenosus" [first]="'GUI.Fetal Echo.Measurements.Observed' | translate" [second]="'GUI.Fetal Echo.Measurements.Not Observed' | translate"
                style="margin: 1em;"></app-fetalmeasure>
            <app-fetalmeasure name="A-Wave" dbName="AWave" [first]="'GUI.Fetal Echo.Measurements.Positive' | translate" [second]="'GUI.Fetal Echo.Measurements.Negative' | translate" style="margin: 1em;">
            </app-fetalmeasure>
            <app-fetalmeasure name="PI" dbName="PI" style="margin: 1em;"></app-fetalmeasure>
        </div>
    </div>

    
    <div class="fww measurments-panel" id="measurmentsPanel" *ngIf="! IsFetalEcho" style="align-items: flex-start;">
        <ng-container *ngIf="modality === 'Ultrasound'">
            <app-mesure-us *ngFor="let measure of ['BPD', 'HC', 'AC', 'FL', 'EFW']"
            [MyMeasure]="measure" class="measurment-unit"></app-mesure-us>
            <ng-container *ngIf="!IsWeightAss" >
                <app-mesure-us MyMeasure="AUA" class="measurment-unit"></app-mesure-us>
                <app-mesure-us MyMeasure="HCAC" class="measurment-unit"></app-mesure-us>
                <app-mesure-us *ngFor="let measure of ['HL', 'RADIUS', 'UL', 'CL', 'TC', 'OFD', 'OOD', 'IOD', 'OD', 'EarLen', 'CEREB', 'CM', 'VoPl','Nuchal', 'TL', 'FIBULA', 'Foot']"
                [MyMeasure]="measure" class="measurment-unit"></app-mesure-us>
          </ng-container>
        </ng-container>
    

        <ng-container *ngIf="modality!=='Ultrasound'" >
        <app-measure MyMeasure="BPD" [different]="BPDifferent" [Editable]="true" class="measurment-unit"></app-measure>
        <app-measure MyMeasure="HC" [different]="HCDifferent" [Editable]="true" class="measurment-unit"></app-measure>
        <app-measure MyMeasure="AC" [different]="ACDifferent" [Editable]="true" class="measurment-unit"></app-measure>
        <app-measure MyMeasure="FL" [different]="FLDifferent" [Editable]="true" class="measurment-unit"></app-measure>
        <app-measure MyMeasure="EFW 1" [Editable]="false" class="measurment-unit"></app-measure>
        <app-measure MyMeasure="EFW 2" [Editable]="false" class="measurment-unit" *ngIf="false"></app-measure>
        <app-measure MyMeasure="HCAC" [Editable]="false" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="VoPl" [different]="VoPlDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="VoPr" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="CEREB" [different]="CEREBDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="CM" [different]="CMDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="OFD" [different]="OFDDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="OOD" [different]="OODDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="IOD" [different]="IODDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="OD"  [different]=" ODDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="EarLen" [different]="EarLenDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="Nuchal" [different]="NuchalDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="TC"  [different]="TCDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="CL"  [different]="CLDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="HL" [different]="HLDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="RADIUS"  [different]="RADIUSDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="UL"  [different]="ULDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="TL" [different]="TLDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="FIBULA" [different]="FIBULADifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="Foot"  [different]="FootDifferent" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss"></app-measure>
        <app-measure MyMeasure="CRL" [Editable]="true" class="measurment-unit" *ngIf="!IsWeightAss && (es.ExamType == 'CVS' || es.ExamType == 'Amniocentesis')"></app-measure>
    </ng-container>
    </div>


    <!--
<div class="container measurements-panel">
   
</div>
<div style='height:11em'> </div>
-->
    <app-weight-assessment [print]="false" *ngIf="IsWeightAss" direction="ltr" [fetus]="fetus"></app-weight-assessment>

    <footer class="comments" style="margin: 2%;width: 80%;">

        <section class="comments-item summary">
            <div class="m-box">
                <h2 class="comments-title">
                    {{'GUI.Measurements.Comments' | translate | titlecase}} 
                    <!-- Comments -->
                </h2>
                <textarea [formControl]="Comment"></textarea>
                <button class="t-btn-edit" *ngIf="! es.isClosed"
                    (click)="atS.showAuto(Comment,atSTitleMeasurementsComments,31)">Auto Text</button>
            </div>
        </section>

    </footer>
</main>

<div *ngIf="showComment && IsFetalEcho">
    <div class="t-popup popup-patientDetails summary" style='display: block;top: 5em;'>

        <div class="t-popup-close">
        </div>

        <div class="t-popup-frame" style="max-width: 65em;">
            <h3 class="anatomy-item-details-title">
                Comments
            </h3>
            <div class="t-popup-body" style="display: flex;flex-direction: column;height: 100%;">
                <div style="place-self: flex-end;">
                    <button class="t-btn-popup-close summary" style="top: 0;right: 0;position: relative; margin-bottom: 20px;"
                        aria-label="close popup" (click)="showComment = false"></button>
                </div>
                <div class="m-box" style="height: 100%; padding: 0;">
                    <textarea class="fulltext" style="min-height: 20em;height: 100%; width: 100%;padding-block-start: 1em;" #CommentsExtra [(ngModel)]="text" (ngModelChange)="Comment.setValue(text)"></textarea>
                    <button class="t-btn-edit" *ngIf="! es.isClosed"
                        (click)="atS.showAuto(Comment,'Comments',31)">Auto
                        Text</button>
                </div>
            </div>
        </div>

    </div>
</div>