import { Injectable } from '@angular/core';
import { UntypedFormControl,AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AutotextService {
  public currentFieldID: number;
  currentText: AbstractControl;
  public Display = false;
  Title: string;
  summaryDiagnosisAT =[];
  filter=null;
  options =[];
  filteredOptions: Observable<string[]>;
  GYNOPTIONSEXMAS = [ '1st', 'NT', 'Early', '2nd/3rd','Weight Assessment', 'Late', 'Third Scan','Specialized' ,'Fetal Echo'];
  search = new UntypedFormControl();
  loaded$ = new Subject();
  loaded=false;




  constructor(private http: HttpClient) { }

  showAuto(formObj: AbstractControl,title: string,formID: number, display = true){
    this.currentText = formObj;
    this.Title= title;
    this.currentFieldID= formID;
    this.Display = display;
  }

  getTexts(field = this.currentFieldID.toString(), filter = this.filter){
    let res = this.http.get('/api/autotext',{params:{field:field, filter:filter}});
    return res;
  }

  saveText(txt:string,id:number, move = false){
    if(move){
      let res = this.http.post('/api/autotextMove',{Text: txt, DlgID: this.currentFieldID});
      return res;
    }
    let res = this.http.post('/api/autotext',{Text: txt, DlgID: this.currentFieldID,ID: id,  filter:this.filter});
    return res;
  }

  delete(id:number){
    let res = this.http.delete('/api/autotext',{params:{ID: id.toString()}});
    return res;
  }

  close(){
    this.Display=false;

  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  async loadData(form: UntypedFormControl){
    this.loaded = false;
    await this.http.get('/api/newExams/getAutoInput').subscribe((data:any) => {
      this.options=[];
      for(let txt of this.GYNOPTIONSEXMAS)
        this.options.push(txt)
      // this.options = this.GYNOPTIONSEXMAS;
      if(data.length > 0){
        data.forEach((i) => {
          this.options.push(i.Text)
        })
      }
      this.filteredOptions = form.valueChanges.pipe(startWith(''),map(value => this._filter('')));
      this.loaded$.next();
      this.loaded = true
    })
    
  }
  deleteAuto(option){
    this.http.delete("/api/newExams/deleteOption/"+option).subscribe((data) =>{
      console.log(data);
      this.options =[];
      this.filteredOptions = this.search.valueChanges.pipe(startWith(''),map(value => this._filter('')));
    })
  }
}
