<div class="t-popup popup-patientDetails" style='display: block'>

    <div class="t-popup-close"></div>
    
    <div class="t-popup-frame">
        <div class="t-popup-body">

            <button class="t-btn-popup-close" aria-label="close popup" (click)="close()"></button>

            <h2 style='color: #D7A95B'>
                {{! sent ? ('GUI.Links.SendLink' | translate) : ('GUI.Links.SendLinkConfirm' | translate)}}
            </h2>
            <!-- box (end) -->

            <div class="l-flex l-flex-w t-fieldmargin patient-details">
                <input type="tel" [(ngModel)]="phone" style="height:fit-content;margin-top:0.5em" [placeholder]="'GUI.Links.sms number' | translate" [matTooltip]="'GUI.Links.SMS Phone Number' | translate">
                <button class="search-btn" (click)="sendLink()" style="width: 4em; margin-left: 1em" *ngIf="! sending && !isForm">
					<!-- Send -->
                    {{'GUI.Links.Send' | translate}}
				</button>
                <button [disabled]="!enableSend" class="search-btn" (click)="sendLink()" style="width: 4em; margin-left: 1em" *ngIf="! sending && isForm">
					<!-- Send -->
                    {{'GUI.Links.Send' | translate}}
				</button>
                
            </div>
            {{error}}

        </div>
    </div>

</div>
