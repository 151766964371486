<div class="patient">

        <div class="wrapper">
    
        <!-- ========================== header (start) ========================== -->
        <header class="l-header">
    
            <div class="on-mobile header-logo">
                <img src="assets/icons/logo.svg" alt="Artisan Sonics"/>
            </div>
            <div class="l-header-panel"     [ngClass]="loginService.isAdmin && !loginService.isCardio && !loginService.isGastro ? 'dsp-flex' :''">
                <div id="1"  [ngClass]="reportList?.length || statisticsForDoctors?.length? 'headerReports' : 'header'">

                    <h1 class="t-title-main" *ngIf="examList">
                         {{'GUI.Patients.titles.Exams' | translate}}
                    </h1>
                    <h1 class="t-title-main" *ngIf="patList">
                        {{'GUI.Patients.titles.Patients' | translate}}
                    </h1>
                    <h1 class="t-title-main" *ngIf="AWLlist">
                        {{'GUI.Patients.titles.TodayList' | translate}}
                    </h1>
                    <h1 class="t-title-main" *ngIf="reportList || statisticsForDoctors">
                        {{'GUI.Patients.titles.Reports' | translate}}
                    </h1>
                    <ng-container *ngFor="let examType of examTypeCounts | keyvalue">
                        <h4>{{ examType.key }}: {{ examType.value }}</h4>
                      </ng-container>
                      <h4 *ngIf="reportList?.length">
                        {{'GUI.Patients.titles.Number of Exams' | translate}}:
                        {{ reportList.length }}
                      </h4>
                      <div>
                    <img
                    style="cursor: pointer"
                    matTooltip="Download CVS"
                    *ngIf="showIcons && reportList?.length"
                   (click)="exportExcel()"
                   height="31em"
                    width="31em"
                    src="../../assets/icons/file.svg"
                      />
                  <!-- <img
                   style="cursor: pointer ;margin-right: 0.5em; margin-left: 0.5em"
                   matTooltip="Print pdf"
                   *ngIf="showIcons"
                   (click)="getPDF()"
                  height="31em"
                  width="31em"
                  src="../../assets/icons/print.svg"
                /> -->
                     </div>
                    
                    <div class="l-flex l-flex-c t-field t-field-pad"  *ngIf="AWLlist" style='margin-left: 2em'>
                     

                        <label>
                            {{'GUI.Patients.titles.Status' | translate}}
                        </label>

                        <i class="t-sep"></i>
                    
                        <select class="t-field t-select" [(ngModel)]="AWLStatus" (change)="AWL();startdate=null;enddate=null">
                            <option value="All">
                                {{'GUI.Patients.todaylist.statues.all' | translate}}
                            </option>
                            <option value="Waiting">
                                {{'GUI.Patients.todaylist.statues.Waiting' | translate}}
                            </option>
                            <option value="In Progress">
                                {{'GUI.Patients.todaylist.statues.In Progress' | translate}}
                            </option>
                            <option value="Completed">
                                {{'GUI.Patients.todaylist.statues.Completed' | translate}}
                            </option>
                        </select>
                  
                    </div>
                </div>
              
               
               
               
                <button class="search-btn search-btn--newPatient" *ngIf="AWLlist && loginService.barcodeScan && ! swiping" style='margin-left: auto; width: 8em' (click)="swiping=true;getcard()">Scan barcode</button>
               
                <input *ngIf="AWLlist && loginService.barcodeScan && swiping" #swipeField (keyup)="barscan($event)" [(ngModel)]="card">
                <nav class="on-mobile header-mobilePanel">
                    <button class="btn-mobileMenu" id="open-mainNav" data-show=".l-sidebar" aria-label="open mobile sidebar"></button>
                </nav>	
                <div *ngIf="(loginService.isAdmin || loginService.userType == 'secretary') && (loginService.separatedClinic || loginService.SeparateZimun) && (!loginService.isGastro && !loginService.isCardio && !loginService.ishospital)" style="right: 4em">

                    <div class="l-flex l-flex-c t-field t-field-pad" >

                        <label matTooltip="{{'GUI.Tooltips.Doctors Name' | translate}}">
                            <img src="../../assets/icons/drb.svg">
                        </label>

                        <i class="t-sep"></i>

                        <span class="t-validation-wrap l-fg1">
                            <select [(ngModel)]="loginService.doctor" (ngModelChange)="AWL()" class="t-select w100" style="text-align: center;">
                                <option [value]="null">All</option>
                                <!-- <option *ngIf="awlExam && ! examTypes.includes(awlExam)" [value]="awlExam">{{awlExam}}</option> -->
                                <ng-container *ngFor="let e of getDoctors()">
                                    <option [value]="e.UID">{{e.Name}}</option>
                                </ng-container>
                            </select>
                        </span>
                        
                    </div>		
                
                </div>
                
            </div>
        
        </header>
        <!-- ========================== header (end) ========================== -->
    
    
        <!-- ========================== intro (start) ========================== -->
    
        <main class="l-deashboard">

            <ng-container>
                <div class="l-flex patient-item" *ngFor="let pat of AWLlist;index as i">

                    <div class="w100 patient-item-link">
        
                        <div class="l-table h100">
                            <span class="l-td-m patient-item-cell patient-id"  (click)="setAWL(pat);selectedPatient=pat">
                                {{'GUI.Patients.Time' | translate}}: <strong>{{loginService.isCardio && AWLStatus == "Completed"? (pat.ExamDate | date: 'd/M/yy, h:mm a'):(pat.ExamDate | date: 'shortTime')}}</strong>
                            </span>
                            <span class="l-td-m patient-item-cell patient-id" (click)="setAWL(pat);selectedPatient=pat" >
                                {{'GUI.Patients.ID' | translate}}: <strong>{{pat.ID}}</strong>
                            </span> 
                            <span class="l-td-m patient-item-cell patient-name-first" [ngClass]="pat.Reason ?  'type-v': 'type-plus'" (click)="setAWL(pat);selectedPatient=pat">
                                {{pat.First}} {{pat.Last}}
                                <span class="anatomy-show-more" style="bottom: auto;right:auto" *ngIf="(! loginService.ishospital || loginService.ishospital == 'belinson') && !loginService.isGastro"> <!--form elemnts not working when empty so using hack-->
                                    
                                    <div class="on-hoverPanel">
                                        
                                        <div class="on-hoverPanel-body">
                    
                                            <div class="anatomy-item-details">
                                                
                                                <div class="anatomy-items-desc">
                    
                                                    <div class="anatomy-items-edited scroller mCustomScrollbar _mCS_2 mCS_no_scrollbar" contenteditable="true"><div id="mCSB_2" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" tabindex="0" style="max-height: none;"><div id="mCSB_2_container" class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" style="position:relative; top:0; left:0;" dir="ltr">   </div>
                                                    <div id="mCSB_2_scrollbar_vertical" class="mCSB_scrollTools mCSB_2_scrollbar mCS-light mCSB_scrollTools_vertical" style="display: none;"><div class="mCSB_draggerContainer"><div id="mCSB_2_dragger_vertical" class="mCSB_dragger" style="position: absolute; min-height: 30px; top: 0px; display: block; height: 12px; max-height: 106.938px;"><div class="mCSB_dragger_bar" style="line-height: 30px;"></div></div><div class="mCSB_draggerRail"></div></div></div></div></div>
                                                    <textarea class="anatomy-items-edit" (click)="$event.stopPropagation()" (change)="saveComment(pat.Reason,pat.AWLID)" [(ngModel)]="pat.Reason"></textarea>
                                                    
                    
                                                </div>
                    
                                            </div>
                    
                                           <!--<button class="anatomy-details-save">Save Comments</button> -->
                    
                                        </div>
                    
                                    </div>
                    
                                </span>
                            </span> 
                           
                            
                            <span class="l-td-m patient-item-cell patient-name-first" *ngIf="! loginService.ishospital && ! loginService.hasSurrogatePreg" (click)="setAWL(pat);selectedPatient=pat">
                                {{'GUI.Patients.Phone' | translate}}: {{pat.Phone ? pat.Phone : pat.Mobile}}
                            </span>
                            <span class="l-td-m patient-item-cell patient-name-first" *ngIf="loginService.hasSurrogatePreg" (click)="setAWL(pat);selectedPatient=pat">
                                {{pat.Surrogate ? pat.Surrogate : ''}}
                            </span>
                            <span class="l-td-m patient-item-cell patient-name-first" *ngIf="(! loginService.ishospital || loginService.AWLExamNames) && pat.ExamCode">
                                {{pat.ExamCode}}
                                 <ng-container *ngIf="!loginService.isCardio && !loginService.isGastro && loginService.premissions.Consent">
                                    <img src="../../assets/icons/login/text-file-icon.svg" style='height: 1.5em;width: 1.5em' matTooltip="{{'GUI.Tooltips.Open Consent Form' | translate}}" (click)="startForm(pat)">
                                </ng-container> 
                            </span>
                            <span class="l-td-m patient-item-cell patient-name-first" *ngIf="(! loginService.ishospital || loginService.AWLExamNames) && (! pat.ExamCode && loginService.isCardio)">
                                <select class="t-field t-select" [(ngModel)]="changedExamCode" (ngModelChange)="setExamCode(pat)">
                                    <option *ngFor="let name of cardioTodayListExams" [value]="name">{{name}}</option>
                                </select>
                            </span>
                            <span class="l-td-m patient-item-cell patient-name-first" *ngIf="loginService.separatedClinic && (loginService.isAdmin || loginService.userType == 'secretary')">
                                {{loginService.getDocByUID(pat.UID)}}
                            </span>
                            <span class="l-td-m patient-item-cell patient-name-first" *ngIf="! loginService.ishospital && ! loginService.isCardio && !loginService.isGastro" (click)="setAWL(pat);selectedPatient=pat">
                                {{pat.ExamLMP ?  (('GUI.Patients.LMP' | translate)+': ')+ (pat.ExamLMP | date: 'd.M.yyyy'):''}}  {{GaArray ? (('GUI.Patients.GA' | translate)+': ') + GaArray[i]: '-1w -1d'}}
                            </span>
                            <span class="l-td-m patient-item-cell patient-name-first" (click)="setAWL(pat);selectedPatient=pat">
                                <!-- {{getStatus(pat.Status)}} -->
                                {{'GUI.Patients.todaylist.statues.'+getStatus(pat.Status) | translate}}
                            </span>
                        </div>			
        
                    </div>
                    <a class="l-td-m patient-item-exit" routerLink="/exams" (click)="patservice.setPat(pat);setPatAwlid(pat)" [state]="{ data: pat }"></a>
                    
        
                </div>
            </ng-container>
           
            
            <div class="l-flex patient-item" *ngFor="let pat of patList">
    
                <button class="w100 patient-item-link" (click)="selectedPatient=pat" [matTooltip]="'GUI.Tooltips.Open Patient Record' | translate | titlecase">
    
                    <div class="l-table h100">
    
                        <span class="l-td-m patient-item-cell patient-id">
                            {{pat.IsID ? ('GUI.Patients.ID' | translate) : ('GUI.Patients.Passport' | translate)}}:
                            <strong>
                                {{pat.ID}}
                            </strong>
                        </span>
    
                        <span class="l-td-m patient-item-cell patient-name-first">
                            {{pat.First}}
                        </span>
    
                        <span class="l-td-m patient-item-cell patient-name-last">
                            {{pat.Last}}
                        </span>
                        <span class="l-td-m patient-item-cell patient-name-first" *ngIf="loginService.separatedClinic && (loginService.isAdmin || loginService.userType == 'secretary')">
                            {{loginService.getDocByUID(pat.UID)}}
                        </span>
    
                    </div>			
    
                </button>
                <a class="l-td-m patient-item-exit" routerLink="/exams" (click)="patservice.setPat(pat);" [state]="{ data: pat }" [matTooltip]="'GUI.Tooltips.Open Patient History' | translate | titlecase"></a>
                
    
            </div>
            <div class="pagination-controls" *ngIf="showPaginationControls || currentPage > 1">
                <button class="flip-page" (click)="previousPage()" [disabled]="currentPage === 1" matTooltipPosition="before" matTooltip="{{'GUI.Tooltips.Go To Previous Page' | translate}}">
                    {{'GUI.Buttons.Previous' | translate}}
                </button>
                <span>Page {{currentPage}}</span>
                <button class="flip-page" (click)="nextPage()" [disabled]="!hasMore" matTooltipPosition="after" matTooltip="{{'GUI.Tooltips.Go To Next Page' | translate}}">
                    {{'GUI.Buttons.Next' | translate}}
                </button>
            </div>
            <!-- report list -->
        <div  *ngIf="reportList">
            <h1 *ngIf="withoutPrn && reportList.length">Exams without PRNs for the period starting on  {{reportList[0].from | date:'d/M/yyyy'}}  and ending on  {{reportList[0].until | date:'d/M/yyyy'}} </h1>
            <h1 *ngIf="!withoutPrn && reportList.length">Exams sent to Leumit for the period starting on  {{reportList[0].from | date:'d/M/yyyy'}}  and ending on  {{reportList[0].until | date:'d/M/yyyy'}} </h1>
            <div  *ngFor="let examType of reportList | map : 'ExamType' | set | toArray">
                <h4>{{ examType }}</h4>
                <div class="l-flex patient-item "  *ngFor="let exam of reportList | filter : { ExamType: examType }">
                    <!-- matTooltip="Click to View Patient History" -->
                    <!-- (click)="goPatient(exam.PID) -->
        
                    <button class="w100 "  style="cursor:default;"  >
        
                        <div class="l-table h100">
                            <span class="l-td-m patient-item-cell patient-name-first">
                                Exam Time: <strong>{{exam.CreationTime | date:"dd/MM/yyyy h:mm a"}}</strong>
                            </span>
                            <span class="l-td-m patient-item-cell patient-id">
                                ID: <strong>{{exam.ID}}</strong>
                            </span>
        
                            <span class="l-td-m patient-item-cell patient-id">
                                Name: {{exam.First}}  {{exam.Last}}
                            </span>
        
                            <span class="l-td-m patient-item-cell patient-name-first">
                                Exam Type: <strong>{{exam.ExamType}} </strong>
                            </span>
                            <span class="l-td-m patient-item-cell patient-name-first">
                                Performed By : <strong>{{exam.Opener}} </strong>
                            </span>

        
                        </div>			
        
                    </button>
                    <!-- <a class="l-td-m patient-item-exit" [ngClass]="getLocked(exam)" (click)="goExam(exam)" [matTooltip]="(getLocked(exam) === 'locked' ) ? 'Locked By: ' +exam.LockedBy : 'View Exam'"></a> -->
                    
        
                </div>
            </div>
        </div>

              <!--  exam  list-->
        
            
               
                <div class="l-flex patient-item" *ngFor="let exam of examList ">
        
                    <button class="w100 patient-item-link" (click)="goPatient(exam.PID)" matTooltip="{{'GUI.Tooltips.Click to View Patient History' | translate}}">
        
                        <div class="l-table h100">
                            <span class="l-td-m patient-item-cell patient-name-first">
                                Exam Time: <strong>{{exam.CreationTime | date:"dd/MM/yyyy h:mm a"}}</strong>
                            </span>
                            <span class="l-td-m patient-item-cell patient-id">
                                ID: <strong>{{exam.ID}}</strong>
                            </span>
        
                            <span class="l-td-m patient-item-cell patient-id">
                                Name: {{exam.First}}  {{exam.Last}}
                            </span>
        
                            <span class="l-td-m patient-item-cell patient-name-first">
                                Exam Type: <strong>{{exam.ExamType}} <span *ngIf="exam.Changed">({{exam.ChangedExamTitle}})</span></strong>
                            </span>
        
                        </div>			
        
                    </button>
                    <a class="l-td-m patient-item-exit" [ngClass]="getLocked(exam)" (click)="goExam(exam)" [matTooltip]="(getLocked(exam) === 'locked' ) ? ('GUI.Tooltips.Locked By' | translate) +exam.LockedBy : ('GUI.Tooltips.View Exam' | translate)"></a>
                    
        
                </div>

        <!-- =================================statisticsForDoctors of leumit (start) ==============================================================-->
        <ng-container *ngIf="statisticsForDoctors" >
         <div *ngFor="let details of statisticsForDoctors;" id="statistic" >
         <h1>Exams Distribution by HMO’s for the period starting on  {{details.from | date:'d/M/yyyy'}}  and ending on  {{details.until | date:'d/M/yyyy'}} for doctor {{details.englishName || details.name}}:</h1>
         <table>
            <thead>
              <tr>
                <th>Patient Category</th>
                <th># of Exams</th>
              </tr>
            </thead>
            <tbody>
              <tr >
                <td>Leumit with PRN</td>
                <td>{{ details.leumitWithPrn }}</td>
              </tr>
              <tr >
                <td>Leumit without PRN</td>
                <td>{{ details.leumitWithoutPrn }}</td>
              </tr>
              <tr >
                <td>Leumit Total</td>
                <td>{{ details.totalExamsForLeumit }}</td>
              </tr>
              <tr >
                <td>By other HMO’s</td>
                <td>{{ details.otherHmo }}</td>
              </tr>
              <tr >
                <td>No HMO indication</td>
                <td>{{ details.notRecognizeHmo }}</td>
              </tr>
              <tr >
                <td>Total exams performed </td>
                <td>{{ details.totalExams }}</td>
              </tr>
            </tbody>
          </table>






         </div>
        </ng-container>


            
        




      <!-- =================================statisticsForDoctors of leumit (end)  ==============================================================-->
        </main>
        
        <!-- ========================== main sidebar (start) ========================== -->
        
        <aside class="l-sidebar l-flex l-column">
            
            <img class="off-mobile sidebar-logo" src="assets/icons/logo.svg" alt="Artisan Sonics" />
    
            <!-- user panel (start) -->
            <div class="userPanel">
                
                <i class="userPanel-icon icon-female">
        
                </i>

                <div class="userPanel-userName" >
                   {{'GUI.Patients.titles.Patient Search' | translate}}
                </div>

                <div class="search-patient">
    
                    <div class="search-patient-field">
                        <div class="t-field l-flex t-twin-fieldset">
                            <label>
                                <input type="radio" name="idp" [(ngModel)]="idp" [value]="1" class="custom-check customInput" aria-labelledby="pregnancy_popup-sex">
                                <i class="customInput-display"></i>
                                <span class="t-field-desc">
                                   {{'GUI.Patients.ID' | translate}}
                                </span>
                            </label>
                            <label>
                                <input type="radio" name="idp" [value]="0" class="custom-check customInput" aria-labelledby="pregnancy_popup-sex">
                                <i class="customInput-display"></i>
                                <span class="t-field-desc">
                                   {{'GUI.Patients.Passport' | translate}}
                                </span>
                            </label>
                        </div>
                    </div>
    
                    <div class="t-validation-wrap">
                        <input type="text" class="search-patient-field idnumber" [ngClass]="invalidi" placeholder="{{'GUI.Placeholders.ID Number' | translate}}" [(ngModel)]="id" (keyup)="invalidi='';trysearch()" pattern="[A-Za-z0–9\-]+">
                        <div class="t-validation-message">
                            <span>
                                {{'GUI.Patients.Invalid ID Number' | translate}}
                            </span>
                        </div>
                    </div>
    
                    <div class="t-validation-wrap">
                        <input type="text" class="search-patient-field" placeholder="{{'GUI.Placeholders.First Name' | translate}}" [ngClass]="invalidf" [(ngModel)]="first" (keyup)="invalidf='';trysearch()">
                        <div class="t-validation-message">
                            <span>
                                {{'GUI.Patients.Please fill in first name' | translate}}
                            </span>
                        </div>
                    </div>
    
                    <div class="t-validation-wrap">
                        <input type="text" class="search-patient-field" placeholder="{{'GUI.Placeholders.Last Name' | translate}}" [ngClass]="invalidl" [(ngModel)]="last" (keyup)="invalidl='';trysearch()">
                        <div class="t-validation-message">
                            <span>
                                {{'GUI.Patients.Please fill in last name' | translate}}
                            </span>
                        </div>
                    </div>
                    
                    <div class="t-validation-wrap">
                        <input type="tel" class="search-patient-field" placeholder="{{'GUI.Placeholders.Phone' | translate}}" [(ngModel)]="phone">
                        <div class="t-validation-message">
                            <span>
                                {{'GUI.Patients.Invalid phone' | translate}}
                            </span>
                        </div>
                    </div>
    
                    <div class="l-flex l-flex-w t-fieldmargin search-btns">
                        <div class="w100" *ngIf="loginService.showAWL()">
                            <button class="search-btn"   matTooltipPosition="after" (click)="AWL();isReports=false" matTooltip="{{'GUI.Tooltips.Artisan Worklist' | translate}}">
                                {{'GUI.General.Buttons.Today List' | translate}}
                            </button>
                        </div>
                        <div class="w50">
                            <button class="search-btn" [disabled]="isSearchDisabled()" matTooltipPosition="after" (click)="search(true);isReports=false;reportList=null" matTooltip="{{'GUI.Tooltips.Search Patient' | translate}}">
                                    {{'GUI.General.Buttons.Search' | translate}}
                            </button>
                        </div>
                        <div class="w50">
                            <button class="search-btn"   matTooltipPosition="after" (click)="clear()" [ngClass]="id.length > 0 || last.length>0 || first.length > 0 || phone.length > 0 ? '' : 'search-btn--clear'" matTooltip="{{'GUI.Tooltips.Clear Search Fields' | translate}}">
                                {{'GUI.General.Buttons.Clear' | translate}}
                            </button>
                        </div>
                        <div class="w100">
                            <button class="search-btn search-btn--newPatient"   matTooltipPosition="after" (click)="addPatient();isReports=false" matTooltip="{{'GUI.Tooltips.Open New Patient' | translate}}">
                                {{'GUI.General.Buttons.New Patient' | translate}}
                            </button>
                        </div>
                        <div class="w100">
                            <button class="search-btn" matTooltip="{{'GUI.Tooltips.Search Exams' | translate}}"   matTooltipPosition="after" (click)="openEsearch=true;isReports=false">
                                {{'GUI.General.Buttons.Search Exams' | translate}}
                            </button>
                        </div>
                        <div class="w100">
                            <button class="search-btn" matTooltip="{{'GUI.Tooltips.Reports for Leumit' | translate}}" matTooltipPosition="after" *ngIf="loginService.hasLeumit" (click)="openEsearch=true;isReports=true">
                                {{'GUI.General.Buttons.Reports' | translate}}
                            </button>
                        </div>
                        <div class="w100">
                            <button class="search-btn" matTooltip="{{'GUI.Tooltips.Go to appointments' | translate}}" matTooltipPosition="after" *ngIf="loginService.premissions?.Appointments" (click)="redirectAdministration('appointments')">
                                {{'GUI.General.Buttons.Appointments' | translate}}
                            </button>
                        </div>
                        <div class="w100">
                            <button class="search-btn" matTooltip="{{'GUI.Tooltips.Go to billing' | translate}}" matTooltipPosition="after" *ngIf="loginService.premissions?.Billings" (click)="redirectAdministration('billing')">
                                {{'GUI.General.Buttons.Billings' | translate}}
                            </button>
                        </div>
    
                    </div>
    
                </div>

    
                <div class="search-help">
                    {{'GUI.Labels.Need help' | translate}}
                    <button class="open-help" (click)="showhelp=true">
                        {{'GUI.General.Buttons.Click Here' | translate}}
                    </button>
                </div>
                
            </div>
            <!-- user panel (end) -->
    
            <!-- main nav (start) -->
            <nav class="mainNav l-fg1 scroller">
    
            </nav>
            <!-- main nav (end) -->
            <app-docpanel></app-docpanel>
            
    
        </aside>
    
        <!-- ========================== main sidebar (end) ========================== -->
        
        <app-bottombar></app-bottombar>
    
        </div><!-- end wrapper -->
    
    
        <!-- ===================================== POPUP : HELP (START) =============================================== -->
    
        <div class="t-popup popup-help" *ngIf="showhelp">
    
            <div class="t-popup-close"></div>
            
            <div class="t-popup-frame">
                <div class="t-popup-body">
    
                    <button class="t-btn-popup-close" aria-label="close popup" (click)="showhelp=false"></button>
    
                    <h2>
                        Help
                    </h2>
    
                    <section class="part-help">
    
                        <h3>
                            Search
                        </h3>
    
                        It is a long established fact that a reader will be distracted by the readable content
                        of a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                        more-or-less normal distribution of letters, as opposed to using 'Content here,
                        content here', making it look like readable English. Many desktop publishing packages
                        and web page editors now use Lorem Ipsum as their default model text, and a search for
                        'lorem ipsum' will uncover many web sites still in their infancy. Various versions have 
                        evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
    
    
                    </section>
    
                    
                    
                </div>
            </div>
        </div>
        <div class="t-popup popup-help" *ngIf="carderror" style="display: inherit">
    
            <div class="t-popup-close"></div>
            
            <div class="t-popup-frame">
                <div class="t-popup-body">
    
                    <button class="t-btn-popup-close" aria-label="close popup" (click)="carderror=null"></button>
    
                    <h2 style="direction: rtl; margin-right: 3em;">
                        מטופל עם ת.ז. {{carderror}} לא נמצאה ברשימת המוזמנים להיום.
                    </h2>
    
                    <section class="part-help">
    
                        <h3 style="direction:rtl">
                            נא לבדוק נכונות המספר ולנסות שוב
                        </h3> 
                    </section>                 
                </div>
            </div>
        </div>

       

        <app-informconcent *ngIf="inform" (closed)="closCalForm($event)" [pat]="exServ.patServ.getPat()" [form]="concentForm"></app-informconcent>

        <app-loginbox *ngIf="showLogin" (closed)="showLogin=false" (viewonly)="viewonly()" [isnew]="false"></app-loginbox>
        <app-patdetails *ngIf="selectedPatient && ! typeForm" [pat]="selectedPatient" [awl]="chosenAWL" [awlExam]="awlExamType" [awlTime]="awlTime" [awlLMP]="awlLMP" [awlComment]="awlComment" [openWAWL]="loginService.barcodeScan" (closed)="onClosed($event)" (showpay)="paymentNoCard()" (show_surrogate)="show_surrogate=true"></app-patdetails>
        <app-surrogate *ngIf="show_surrogate" (closed)="show_surrogate=false" [genMom]="selectedPatient"></app-surrogate>
        <app-payment *ngIf="showpayment" [tz]="showpayment" [idtype]="idcode" [awlid]="selectedPatient.AWLID" [exp]="expcode" (closed)="showpayment=null"></app-payment>
        <app-searchbox *ngIf="openEsearch" (closed)="searchClosed($event)" [isReports]="isReports" [hmostype]="hmostype" [isGastro]="loginService.isGastro" [startdate]="fromd" [enddate]="tod" [examtype]="examMode" [exams]="examVals"></app-searchbox>
        <app-commerror></app-commerror> 
        <app-printopts *ngIf="showprint" (closed)="showprint=false" [showopts]="showprintopts" [stats]="true" [isReport]="true" [type]="reportName" [startdate]="fromd" [enddate]="tod" [hmostype]="hmostype">
        </app-printopts>
    </div>
    
